@charset "UTF-8";
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import '~@fortawesome/fontawesome-free/css/all.min.css';
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0E2D52;
  --bs-secondary: #FCAC2F;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 14, 45, 82;
  --bs-secondary-rgb: 252, 172, 47;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #333;
  text-decoration: none;
}
a:hover {
  color: #292929;
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1180px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1260px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfd5dc;
  --bs-table-striped-bg: #c5cad1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bac0c6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfc5cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bac0c6;
}

.table-secondary {
  --bs-table-bg: #feeed5;
  --bs-table-striped-bg: #f1e2ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5d6c0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebdcc5;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e5d6c0;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #002f5b;
  outline: 0;
  box-shadow: none;
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #002f5b;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(14, 45, 82, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #002f5b;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(14, 45, 82, 0.25);
}
.form-check-input:checked {
  background-color: #0E2D52;
  border-color: #0E2D52;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0E2D52;
  border-color: #0E2D52;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23002f5b'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0E2D52;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b7c0cb;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0E2D52;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b7c0cb;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(14, 45, 82, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #0E2D52;
  border-color: #0E2D52;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0c2646;
  border-color: #0b2442;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0c2646;
  border-color: #0b2442;
  box-shadow: 0 0 0 0.25rem rgba(50, 77, 108, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0b2442;
  border-color: #0b223e;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(50, 77, 108, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #0E2D52;
  border-color: #0E2D52;
}

.btn-secondary {
  color: #000;
  background-color: #FCAC2F;
  border-color: #FCAC2F;
}
.btn-secondary:hover {
  color: #000;
  background-color: #fcb84e;
  border-color: #fcb444;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #fcb84e;
  border-color: #fcb444;
  box-shadow: 0 0 0 0.25rem rgba(214, 146, 40, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #fdbd59;
  border-color: #fcb444;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(214, 146, 40, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #FCAC2F;
  border-color: #FCAC2F;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #0E2D52;
  border-color: #0E2D52;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0E2D52;
  border-color: #0E2D52;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(14, 45, 82, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0E2D52;
  border-color: #0E2D52;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(14, 45, 82, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #0E2D52;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #FCAC2F;
  border-color: #FCAC2F;
}
.btn-outline-secondary:hover {
  color: #000;
  background-color: #FCAC2F;
  border-color: #FCAC2F;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(252, 172, 47, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #FCAC2F;
  border-color: #FCAC2F;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(252, 172, 47, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #FCAC2F;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #333;
  text-decoration: none;
}
.btn-link:hover {
  color: #292929;
  text-decoration: none;
}
.btn-link:focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #FFF;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #FFF;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #FFF;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #333;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #292929;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0E2D52;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #0d294a;
  background-color: #e7eaee;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230d294a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #002f5b;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(14, 45, 82, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #333;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #292929;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #292929;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(14, 45, 82, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0E2D52;
  border-color: #0E2D52;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #081b31;
  background-color: #cfd5dc;
  border-color: #b7c0cb;
}
.alert-primary .alert-link {
  color: #061627;
}

.alert-secondary {
  color: #654513;
  background-color: #feeed5;
  border-color: #fee6c1;
}
.alert-secondary .alert-link {
  color: #51370f;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0E2D52;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0E2D52;
  border-color: #0E2D52;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #081b31;
  background-color: #cfd5dc;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #081b31;
  background-color: #bac0c6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #081b31;
  border-color: #081b31;
}

.list-group-item-secondary {
  color: #654513;
  background-color: #feeed5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #654513;
  background-color: #e5d6c0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #654513;
  border-color: #654513;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(14, 45, 82, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0E2D52;
}
.link-primary:hover, .link-primary:focus {
  color: #0b2442;
}

.link-secondary {
  color: #FCAC2F;
}
.link-secondary:hover, .link-secondary:focus {
  color: #fdbd59;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0E2D52 !important;
}

.border-secondary {
  border-color: #FCAC2F !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
/*
Name: 			theme.css
Written by: 	Okler Themes - (http://www.okler.net)
Theme Version:	@@version
*/
@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"), url(https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@400;500;600&family=Palanquin:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700) format("woff2");
}
html {
  direction: ltr;
  overflow-x: hidden;
  box-shadow: none !important;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: #FFF;
  color: #0E2D52;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 26px;
  margin: 0;
}
body a {
  outline: none !important;
}

.body {
  background-color: #FFF;
}

html.safari:not(.no-safari-overflow-x-fix) .body {
  overflow-x: hidden;
}

li {
  line-height: 24px;
}

a {
  color: #0E2D52;
}

/* Responsive */
@media (max-width: 575px) {
  body {
    font-size: 13px;
  }
}
/* Header */
#header {
  position: relative;
  z-index: 1030;
  height: 100px !important;
}
#header .header-body {
  display: flex;
  flex-direction: column;
  background: #fff;
  transition: min-height 0.3s ease;
  width: 100%;
  z-index: 1001;
}
#header .header-body.header-body-bottom-border-fixed {
  border-bottom: 1px solid rgba(234, 234, 234, 0.5) !important;
}
#header .header-body[class*=border-color-] {
  border-bottom: 0 !important;
}
#header .header-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  transition: ease height 300ms;
  height: 100px !important;
}
@media (max-width: 991px) {
  #header .header-container:not(.container):not(.container-fluid):not(.container-sm):not(.container-md):not(.container-lg):not(.container-xl):not(.container-xxl) .header-nav-main {
    padding: 0 0.75rem;
  }
}
#header .header-container.header-container-height-xs {
  height: 80px;
}
#header .header-container.header-container-height-sm {
  height: 100px;
}
#header .header-container.header-container-height-md {
  height: 125px;
}
#header .header-container.header-container-height-lg {
  height: 145px;
}
#header .container {
  position: relative;
}
@media (max-width: 767px) {
  #header .container {
    width: 100%;
  }
}
#header .header-row {
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-self: stretch;
  max-height: 100%;
}
#header .header-column {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
}
#header .header-column.header-column-border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}
#header .header-column.header-column-border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
#header .header-column .header-row {
  justify-content: inherit;
}
#header .header-column .header-extra-info {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
#header .header-column .header-extra-info li {
  display: inline-flex;
  margin-left: 25px;
}
#header .header-column .header-extra-info li .header-extra-info-icon {
  font-size: 2rem;
  margin-right: 10px;
}
#header .header-column .header-extra-info li .header-extra-info-text label {
  display: block;
  margin: 0;
  color: #999;
  font-size: 0.75rem;
  line-height: 1.2;
}
#header .header-column .header-extra-info li .header-extra-info-text strong {
  display: block;
  margin: 0;
  color: #333;
  font-size: 0.9rem;
  line-height: 1.3;
}
#header .header-column .header-extra-info li .header-extra-info-text a {
  color: #333;
}
#header .header-column .header-extra-info li .feature-box p {
  margin: 0;
}
#header .header-column .header-extra-info li .feature-box small, #header .header-column .header-extra-info li .feature-box .small {
  position: relative;
  top: -4px;
  font-size: 80%;
}
#header.header-no-min-height .header-body {
  min-height: 0 !important;
}
#header .header-logo {
  margin: 1rem 0;
  position: relative;
  z-index: 1;
}
#header .header-logo img {
  transition: all 0.3s ease;
  position: relative;
  top: 0;
  height: auto;
}
#header .header-nav {
  padding: 1rem 0;
  min-height: 70px;
}
#header .header-nav-features {
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
}
#header .header-nav-features:before, #header .header-nav-features:after {
  background: rgba(0, 0, 0, 0.03);
  content: none;
  width: 1px;
  height: 16px;
  position: absolute;
  display: block;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  left: 0;
}
#header .header-nav-features.header-nav-features-divisor-lg:before, #header .header-nav-features.header-nav-features-divisor-lg:after {
  height: 72px;
}
#header .header-nav-features.header-nav-features-light:before, #header .header-nav-features.header-nav-features-light:after {
  background: rgba(255, 255, 255, 0.1);
}
#header .header-nav-features:not(.header-nav-features-no-border):before, #header .header-nav-features:not(.header-nav-features-no-border):after {
  content: "";
}
#header .header-nav-features:not(.header-nav-features-no-border):after {
  display: none;
}
#header .header-nav-features.header-nav-features-show-border:before, #header .header-nav-features.header-nav-features-show-border:after {
  content: "";
}
@media (min-width: 576px) {
  #header .header-nav-features.header-nav-features-sm-show-border:before, #header .header-nav-features.header-nav-features-sm-show-border:after {
    content: "";
  }
}
@media (min-width: 768px) {
  #header .header-nav-features.header-nav-features-md-show-border:before, #header .header-nav-features.header-nav-features-md-show-border:after {
    content: "";
  }
}
@media (min-width: 992px) {
  #header .header-nav-features.header-nav-features-lg-show-border:before, #header .header-nav-features.header-nav-features-lg-show-border:after {
    content: "";
  }
}
@media (min-width: 1200px) {
  #header .header-nav-features.header-nav-features-xl-show-border:before, #header .header-nav-features.header-nav-features-xl-show-border:after {
    content: "";
  }
}
@media (min-width: 1400px) {
  #header .header-nav-features.header-nav-features-xxl-show-border:before, #header .header-nav-features.header-nav-features-xxl-show-border:after {
    content: "";
  }
}
#header .header-nav-features.header-nav-features-start {
  padding-left: 0;
  margin-left: 0;
  padding-right: 20px;
  margin-right: 10px;
}
#header .header-nav-features.header-nav-features-start:not(.header-nav-features-no-border):before {
  left: auto;
  right: 0;
}
#header .header-nav-features.header-nav-features-center {
  padding-left: 20px;
  margin-left: 10px;
  padding-right: 20px;
  margin-right: 10px;
}
#header .header-nav-features.header-nav-features-center:after {
  display: block;
  left: auto;
  right: 0;
}
#header .header-nav-features .header-nav-top-icon,
#header .header-nav-features .header-nav-top-icon-img {
  font-size: 0.8rem;
  position: relative;
  top: -1px;
  color: #444;
}
#header .header-nav-features.header-nav-features-light .header-nav-top-icon,
#header .header-nav-features.header-nav-features-light .header-nav-top-icon-img {
  color: #FFF;
}
#header .header-nav-features .header-nav-top-icon-img {
  top: -2px;
}
#header .header-nav-features .header-nav-feature {
  position: relative;
}
#header .header-nav-features .header-nav-features-dropdown {
  opacity: 0;
  top: -10000px;
  position: absolute;
  box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.05);
  background: #FFF;
  padding: 17px;
  min-width: 300px;
  z-index: 10000;
  right: 100%;
  margin-right: -25px;
  margin-top: 30px;
  transition: opacity 0.2s ease-out;
  pointer-events: none;
}
#header .header-nav-features .header-nav-features-dropdown:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 5px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  transform: translate3d(-7px, -6px, 0);
}
#header .header-nav-features .header-nav-features-dropdown.show {
  opacity: 1;
  top: auto;
  pointer-events: all;
}
@media (min-width: 992px) {
  #header .header-nav-features .header-nav-features-dropdown.header-nav-features-dropdown-reverse {
    right: auto;
    margin-right: 0;
    left: 100%;
    margin-left: -25px;
  }
  #header .header-nav-features .header-nav-features-dropdown.header-nav-features-dropdown-reverse:before {
    right: auto;
    left: 5px;
    transform: translate3d(7px, -6px, 0);
  }
}
@media (max-width: 440px) {
  #header .header-nav-features .header-nav-features-dropdown.header-nav-features-dropdown-mobile-fixed {
    position: fixed;
    right: auto;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  #header .header-nav-features .header-nav-features-dropdown.header-nav-features-dropdown-mobile-fixed:before {
    content: none;
  }
}
#header .header-nav-features .header-nav-features-dropdown.header-nav-features-dropdown-force-right {
  margin-right: 6px !important;
  right: 0;
}
#header .header-nav-features .header-nav-features-dropdown.header-nav-features-dropdown-dark {
  background: #333;
}
#header .header-nav-features .header-nav-features-dropdown.header-nav-features-dropdown-dark:before {
  border-bottom-color: #333;
}
#header .header-nav-features .header-nav-features-cart {
  z-index: 2;
}
#header .header-nav-features .header-nav-features-cart .header-nav-features-dropdown {
  padding: 20px;
}
#header .header-nav-features .header-nav-features-cart .cart-info {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  margin-top: -4px;
  left: 0;
  padding: 0;
  display: block;
  line-height: 1;
}
#header .header-nav-features .header-nav-features-cart .cart-info .cart-qty {
  position: absolute;
  top: -12px;
  right: -10px;
  width: 15px;
  height: 15px;
  display: block;
  font-size: 9px;
  font-weight: 600;
  color: #fff;
  background-color: #ed5348;
  text-align: center;
  line-height: 15px;
  border-radius: 20px;
  box-shadow: -1px 1px 2px 0 rgba(0, 0, 0, 0.3);
}
#header .header-nav-features .header-nav-features-cart .mini-products-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li {
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid #EEE;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li:first-child {
  margin-top: -15px;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-image {
  float: right;
  border-color: #ededed;
  border-width: 1px;
  padding: 0;
  border: 1px solid #ddd;
  background-color: #fff;
  display: block;
  position: relative;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-image img {
  max-width: 80px;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-details {
  margin-left: 0;
  line-height: 1.4;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-details a {
  letter-spacing: 0.005em;
  font-weight: 600;
  color: #696969;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-details .product-name {
  margin: 15px 0 0;
  font-size: 0.9em;
  font-weight: 400;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-details .qty-price {
  font-size: 0.8em;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-details .btn-remove {
  z-index: 3;
  top: 5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  color: #474747;
  border-radius: 100%;
  position: absolute;
  text-align: center;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  line-height: 20px;
  font-size: 10px;
}
#header .header-nav-features .header-nav-features-cart .totals {
  padding: 15px 0 25px;
  overflow: hidden;
}
#header .header-nav-features .header-nav-features-cart .totals .label {
  float: left;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  color: #474747;
}
#header .header-nav-features .header-nav-features-cart .totals .price-total {
  float: right;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  color: #474747;
}
#header .header-nav-features .header-nav-features-cart .actions {
  padding: 0;
  margin: 0;
}
#header .header-nav-features .header-nav-features-cart .actions .btn {
  display: inline-block;
  min-width: 125px;
  text-align: center;
  margin: 5px 0;
  float: right;
  font-size: 0.7rem;
  padding: 0.7rem 1rem;
  text-transform: uppercase;
  font-weight: 600;
}
#header .header-nav-features .header-nav-features-cart .actions .btn:first-child {
  float: left;
}
#header .header-nav-features .header-nav-features-cart.header-nav-features-cart-big .cart-info {
  margin-top: -9px;
  left: -4px;
}
#header .header-nav-features .header-nav-features-cart.header-nav-features-cart-big .header-nav-features-dropdown {
  margin-right: -31px;
  top: 10px;
}
#header .header-nav-features .header-nav-features-user .header-nav-features-toggle {
  color: #444;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
#header .header-nav-features .header-nav-features-user .header-nav-features-toggle .far {
  font-size: 0.95rem;
  margin: 0px 2px 0 0;
  position: relative;
  top: 1px;
}
#header .header-nav-features .header-nav-features-user .header-nav-features-toggle:hover {
  text-decoration: none;
}
#header .header-nav-features .header-nav-features-user .header-nav-features-dropdown {
  margin-right: -27px;
  min-width: 420px;
  padding: 35px;
}
@media (max-width: 767px) {
  #header .header-nav-features .header-nav-features-user .header-nav-features-dropdown {
    min-width: 320px;
  }
}
#header .header-nav-features .header-nav-features-user .actions {
  padding: 0;
  margin: 0;
}
#header .header-nav-features .header-nav-features-user .actions .btn {
  display: inline-block;
  min-width: 125px;
  margin: 5px 0;
  font-size: 0.7rem;
  padding: 0.7rem 1rem;
  text-transform: uppercase;
  font-weight: 600;
}
#header .header-nav-features .header-nav-features-user .extra-actions {
  clear: both;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding-top: 20px;
  margin-top: 20px;
  text-align: center;
}
#header .header-nav-features .header-nav-features-user .extra-actions p {
  font-size: 0.75rem;
  margin: 0;
  padding: 0;
}
#header .header-nav-features .header-nav-features-user.signin .signin-form {
  display: block;
}
#header .header-nav-features .header-nav-features-user.signin .signup-form, #header .header-nav-features .header-nav-features-user.signin .recover-form {
  display: none;
}
#header .header-nav-features .header-nav-features-user.signup .signin-form {
  display: none;
}
#header .header-nav-features .header-nav-features-user.signup .signup-form {
  display: block;
}
#header .header-nav-features .header-nav-features-user.signup .recover-form {
  display: none;
}
#header .header-nav-features .header-nav-features-user.recover .signin-form, #header .header-nav-features .header-nav-features-user.recover .signup-form {
  display: none;
}
#header .header-nav-features .header-nav-features-user.header-nav-features-user-logged .header-nav-features-dropdown {
  min-width: 320px;
}
#header .header-nav-features .header-nav-features-search-reveal-container {
  display: flex;
  align-items: center;
  min-height: 42px;
}
#header .header-nav-features .header-nav-features-search-reveal .simple-search {
  display: none;
}
#header .header-nav-features .header-nav-features-search-reveal .header-nav-features-search-hide-icon {
  display: none;
  text-decoration: none;
}
#header .header-nav-features .header-nav-features-search-reveal .header-nav-features-search-show-icon {
  display: inline-flex;
  text-decoration: none;
}
#header .header-nav-features .header-nav-features-search-reveal.show .header-nav-features-search-hide-icon {
  display: inline-flex;
  margin-left: 10px;
  align-items: center;
}
#header .header-nav-features .header-nav-features-search-reveal.show .header-nav-features-search-show-icon {
  display: none;
}
#header .header-nav-features .header-nav-features-search-reveal.show .simple-search {
  display: inline-flex;
}
#header .header-nav-features .header-nav-features-search-reveal-big-search {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  background: #FFF;
  width: 100%;
  height: 100%;
  z-index: 1;
}
#header .header-nav-features .header-nav-features-search-reveal-big-search .big-search-header {
  align-items: center;
}
#header .header-nav-features .header-nav-features-search-reveal-big-search .form-control {
  border: 0;
  background: transparent;
  font-size: 22px !important;
  line-height: 1;
  border-bottom: 2px solid #CCC;
  border-radius: 0;
  box-shadow: none;
  position: relative;
  z-index: 1;
}
#header .header-nav-features .header-nav-features-search-reveal-big-search .header-nav-features-search-hide-icon {
  position: absolute;
  right: 0px;
  top: 50%;
  z-index: 2;
  display: block;
  margin-right: 10px;
  margin-top: -10px;
}
#header .header-nav-features .header-nav-features-search-reveal-big-search .header-nav-features-search-hide-icon i {
  font-size: 20px;
}
#header .header-nav-features .header-nav-features-search-reveal-big-search.header-nav-features-search-reveal-big-search-full {
  position: fixed;
  z-index: 100000;
  background: transparent;
}
#header .header-nav-features .header-nav-features-search-reveal-big-search.header-nav-features-search-reveal-big-search-full:before {
  content: "";
  display: block;
  background: rgba(23, 23, 23, 0.95);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
#header .header-nav-features .header-nav-features-search-reveal-big-search.header-nav-features-search-reveal-big-search-full .form-control {
  color: #FFF;
}
#header .header-nav-features .header-nav-features-search-reveal-big-search.header-nav-features-search-reveal-big-search-full .header-nav-features-search-hide-icon {
  position: fixed;
  top: 25px;
  right: 20px;
  margin-top: 0;
}
#header .header-nav-features .header-nav-features-search-reveal-big-search.header-nav-features-search-reveal-big-search-full .header-nav-features-search-hide-icon i {
  color: #FFF;
}
#header.search-show .header-nav-features .header-nav-features-search-reveal-big-search {
  display: flex;
}
#header .header-search {
  margin-left: 1rem;
}
#header .header-search .form-control {
  border-radius: 20px;
  font-size: 0.9em;
  height: 34px;
  margin: 0;
  padding: 6px 12px;
  transition: width 0.3s ease;
  perspective: 1000px;
  width: 170px;
}
#header .header-search .btn-light {
  background: transparent;
  border: 0 none;
  color: #CCC;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
#header .header-search .btn-light:hover {
  color: #000;
}
#header .header-social-icons {
  margin-left: 1rem;
}
#header .header-border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}
#header .header-border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}
#header .header-border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}
#header .header-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
@media (max-width: 991px) {
  #header .header-mobile-border-top:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
}
#header .header-btn-collapse-nav {
  background: transparent;
  color: #0E2D52;
  display: none;
  float: right;
  margin: 0 0 0 1rem;
}
#header .header-btn-collapse-nav i {
  font-size: 2em;
}
#header.header-narrow .header-body {
  min-height: 0;
}
#header.header-narrow .header-logo {
  margin-top: 0;
  margin-bottom: 0;
}
#header.header-narrow .header-nav {
  padding-top: 0;
  padding-bottom: 0;
}
#header.header-no-border-bottom .header-body {
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
}
#header.header-no-border-bottom .header-nav.header-nav-links nav > ul > li > a, #header.header-no-border-bottom .header-nav.header-nav-links nav > ul > li:hover > a, #header.header-no-border-bottom .header-nav.header-nav-line nav > ul > li > a, #header.header-no-border-bottom .header-nav.header-nav-line nav > ul > li:hover > a {
  margin: 0;
}
#header.header-effect-shrink {
  transition: ease height 300ms;
}
#header.header-effect-shrink .header-top {
  transition: ease height 300ms;
}
#header.header-effect-shrink .header-container {
  min-height: 100px;
}
#header.header-effect-shrink .header-logo {
  display: flex;
  align-items: center;
}
#header.header-dark .header-body {
  background: #333;
}
#header.header-dark .header-body .header-nav.header-nav-links-side-header nav > ul > li > a {
  border-bottom-color: #2a2a2a;
}

@media (max-width: 991px) {
  html.mobile-menu-opened #header .header-body[class*=border-color-] {
    border-bottom: 1px solid rgba(234, 234, 234, 0.5) !important;
  }
}
html:not(.boxed) #header.header-floating-bar {
  position: absolute;
  top: 45px;
  width: 100%;
}
html:not(.boxed) #header.header-floating-bar .header-body {
  border: medium none;
  margin: 0 auto;
  padding: 0;
  position: relative;
  width: auto;
}
html:not(.boxed) #header.header-floating-bar .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu {
  left: 0;
  right: 0;
}
@media (max-width: 991px) {
  html:not(.boxed) #header.header-floating-bar {
    top: 0;
  }
}
@media (max-width: 991px) {
  html:not(.boxed):not(.sticky-header-active) #header.header-floating-bar .header-body {
    margin: 10px;
  }
  html:not(.boxed):not(.sticky-header-active) #header.header-floating-bar .header-nav-main:before {
    display: none;
  }
}

@media (min-width: 1200px) {
  html:not(.sticky-header-active) #header.header-floating-bar .header-body {
    max-width: 1140px;
    right: 15px;
  }
  html:not(.sticky-header-active) #header.header-floating-bar .header-container {
    padding-right: 0px;
    padding-left: 30px;
  }
}
html:not(.boxed) #header.header-full-width .header-container {
  width: 100%;
  max-width: none;
}

html #header.header-bottom-slider {
  position: absolute;
  bottom: 0;
}
html #header.header-bottom-slider .header-body {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
html #header.header-bottom-slider.header-transparent-light-top-border .header-body {
  border-top: 1px solid rgba(68, 68, 68, 0.4);
}
html #header.header-bottom-slider.header-transparent-light-top-border-1 .header-body {
  border-top: 1px solid rgba(68, 68, 68, 0.1);
}
html #header.header-bottom-slider.header-transparent-dark-top-border .header-body {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
html #header.header-bottom-slider.header-transparent-dark-top-border-1 .header-body {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
html.sticky-header-active #header.header-bottom-slider .header-body {
  border-top-color: transparent;
}

@media (max-width: 991px) {
  html #header.header-bottom-slider .header-nav-main {
    top: calc(100% + 15px);
  }
  html.sticky-header-active #header.header-bottom-slider .header-nav-main {
    top: 100%;
  }
}
html #header.header-transparent {
  min-height: 0 !important;
  width: 100%;
  position: absolute;
}
html #header.header-transparent .header-body:not(.header-border-bottom) {
  border-bottom: 0;
}
html:not(.sticky-header-active) #header.header-transparent:not(.header-semi-transparent) .header-body {
  background-color: transparent !important;
}
html:not(.sticky-header-active) #header.header-semi-transparent-light .header-body {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
html:not(.sticky-header-active) #header.header-semi-transparent-dark .header-body {
  background-color: rgba(68, 68, 68, 0.4) !important;
}
html:not(.sticky-header-active) #header.header-transparent-light-bottom-border .header-body {
  border-bottom: 1px solid rgba(68, 68, 68, 0.4);
}
html:not(.sticky-header-active) #header.header-transparent-light-bottom-border-1 .header-body {
  border-bottom: 1px solid rgba(68, 68, 68, 0.1);
}
html:not(.sticky-header-active) #header.header-transparent-dark-bottom-border .header-body {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
html:not(.sticky-header-active) #header.header-transparent-dark-bottom-border-1 .header-body {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 991px) {
  html #header.header-transparent .header-body {
    overflow: hidden;
  }
  html #header.header-transparent .header-body.bg-dark .header-nav-main:before, html #header.header-transparent .header-body.bg-color-dark .header-nav-main:before {
    background-color: #333;
  }
  html #header.header-transparent .header-nav-main:before {
    width: calc(100% - 30px);
  }
  html #header.header-transparent .header-nav-main nav {
    padding: 0 30px;
  }
  html.sticky-header-active #header.header-transparent .header-nav-main nav {
    padding: 0 15px;
  }
}
html.sticky-header-active #header .header-body {
  position: fixed;
}
html.sticky-header-active #header .header-body.header-body-bottom-border {
  border-bottom: 1px solid rgba(234, 234, 234, 0.5) !important;
}
html.sticky-header-active #header.header-effect-shrink .header-body {
  position: relative;
}

html.sticky-header-reveal.sticky-header-active #header .header-body {
  position: fixed;
  border-bottom: none;
  width: 100%;
  max-width: 100%;
}

html.sticky-header-enabled #header .header-logo-sticky-change {
  position: relative;
}
html.sticky-header-enabled #header .header-logo-sticky-change img {
  position: absolute;
  top: 0;
  left: 0;
}
html.sticky-header-enabled #header .header-logo-sticky-change .header-logo-non-sticky {
  opacity: 1 !important;
}
html.sticky-header-enabled #header .header-logo-sticky-change .header-logo-sticky {
  opacity: 0 !important;
}
html.sticky-header-enabled.sticky-header-active #header .header-logo-sticky-change .header-logo-non-sticky {
  opacity: 0 !important;
}
html.sticky-header-enabled.sticky-header-active #header .header-logo-sticky-change .header-logo-sticky {
  opacity: 1 !important;
}

/* Header Nav Main */
@media (min-width: 992px) {
  #header .header-nav-main {
    display: flex !important;
    height: auto !important;
  }
  #header .header-nav-main nav {
    display: flex !important;
  }
  #header .header-nav-main nav > ul > li {
    height: 100%;
    align-self: stretch;
    margin-left: 2px;
  }
  #header .header-nav-main nav > ul > li > a {
    display: inline-flex;
    align-items: center;
    white-space: normal;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding: 0.5rem 1rem;
  }
  #header .header-nav-main nav > ul > li > a:after {
    display: none;
  }
  #header .header-nav-main nav > ul > li > a:active {
    background-color: transparent;
    text-decoration: none;
    color: #CCC;
  }
  #header .header-nav-main nav > ul > li > a.dropdown-toggle .fa-chevron-down {
    display: none;
  }
  #header .header-nav-main nav > ul > li > a.active {
    background-color: transparent;
  }
  #header .header-nav-main nav > ul > li.open > a, #header .header-nav-main nav > ul > li:hover > a {
    background: #CCC;
    color: #FFF;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
    top: -10000px;
    display: block;
    opacity: 0;
    left: auto;
    border-radius: 0 4px 4px;
    border: 0;
    border-top: 3px solid #CCC;
    box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.07);
    margin: 0;
    min-width: 200px;
    padding: 5px 0;
    text-align: left;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
    border-bottom: 1px solid #f7f7f7;
    color: #777;
    font-size: 0.8em;
    font-weight: 400;
    padding: 6px 18px 6px 18px;
    position: relative;
    text-transform: none;
    letter-spacing: -0.5px;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:hover, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:focus, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a.active, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:active {
    background-color: transparent;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li:hover > a, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li:focus > a, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.active > a, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li:active > a {
    background-color: #f8f9fa;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu {
    position: relative;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a .fa-chevron-down {
    display: none;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
    font-family: "Font Awesome 5 Free";
    content: "\f054";
    font-weight: 900;
    position: absolute;
    top: 50%;
    font-size: 0.5rem;
    opacity: 0.7;
    right: 20px;
    transform: translateY(-50%);
    transition: all 0.2s ease-out;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
    left: 100%;
    display: block;
    margin-top: -8px;
    margin-left: -1px;
    border-radius: 4px;
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.open > a:after, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu:hover > a:after {
    opacity: 1;
    right: 15px;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.open > .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
    top: 0;
    opacity: 1;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse:hover > a:after {
    right: 30px;
    transform: translateY(-50%) rotateY(180deg);
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse > .dropdown-menu {
    left: auto;
    right: 100%;
    transform: translate3d(0, 0, 0);
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li:last-child a {
    border-bottom: 0;
  }
  #header .header-nav-main nav > ul > li.dropdown.open > a, #header .header-nav-main nav > ul > li.dropdown:hover > a {
    border-radius: 4px 4px 0 0;
    position: relative;
  }
  #header .header-nav-main nav > ul > li.dropdown.open > a:before, #header .header-nav-main nav > ul > li.dropdown:hover > a:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    border-bottom: 5px solid #CCC;
  }
  #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
    top: auto;
    display: block;
    opacity: 1;
  }
  #header .header-nav-main nav > ul > li.dropdown.dropdown-reverse > .dropdown-menu {
    left: auto;
    right: 0;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega {
    position: static;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu {
    border-radius: 4px;
    left: 15px;
    right: 15px;
    width: auto;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-content {
    padding: 1.6rem;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-title {
    color: #333333;
    display: block;
    font-size: 0.85em;
    font-weight: 600;
    margin-top: 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: -0.5px;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-title:first-child {
    margin-top: 0;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li > a {
    border: 0 none;
    border-radius: 4px;
    color: #777;
    display: block;
    font-size: 0.8em;
    font-weight: normal;
    margin: 0 0 3px -8px;
    padding: 3px 8px;
    text-shadow: none;
    text-transform: none;
    text-decoration: none;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li:hover > a {
    background: #f7f7f7;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop > .dropdown-item {
    padding: 0.5rem 0.8rem;
  }
  #header .header-nav-main nav > ul > li .wrapper-items-cloned i {
    display: none;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li > a {
    border-radius: 0 !important;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu {
    border-radius: 0 !important;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
    border-radius: 0 !important;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li.dropdown .thumb-info, #header .header-nav-main.header-nav-main-square nav > ul > li.dropdown .thumb-info-wrapper {
    border-radius: 0 !important;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li.dropdown-mega > .dropdown-menu {
    border-radius: 0 !important;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li > a {
    border-radius: 0 !important;
  }
  #header .header-nav-main.header-nav-main-dropdown-no-borders nav > ul > li.dropdown .dropdown-menu {
    border: 0 !important;
    border-radius: 4px;
  }
  #header .header-nav-main.header-nav-main-dropdown-no-borders nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
    margin-top: -5px;
  }
  #header .header-nav-main.header-nav-main-font-sm nav > ul > li > a {
    font-size: 0.7rem;
  }
  #header .header-nav-main.header-nav-main-font-md nav > ul > li > a {
    font-size: 1rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: -1px;
  }
  #header .header-nav-main.header-nav-main-font-lg nav > ul > li > a {
    font-size: 1.1rem;
    font-weight: 400;
    text-transform: none;
    letter-spacing: -1px;
  }
  #header .header-nav-main.header-nav-main-font-lg-upper nav > ul > li > a {
    font-size: 0.95rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -1px;
  }
  #header .header-nav-main.header-nav-main-font-alternative nav > ul > li > a {
    font-family: "Oswald", sans-serif;
    font-size: 0.85rem;
    font-weight: 600;
  }
  #header .header-nav-main.header-nav-main-dropdown-modern nav > ul > li.dropdown .dropdown-menu {
    border: 0 !important;
    padding: 15px 8px;
    margin-left: -10px;
  }
  #header .header-nav-main.header-nav-main-dropdown-modern nav > ul > li.dropdown .dropdown-menu li a {
    border-bottom: 0;
    background: transparent !important;
    font-size: 0.85em;
    transition: all 0.2s ease-out;
    left: 0;
  }
  #header .header-nav-main.header-nav-main-dropdown-modern nav > ul > li.dropdown .dropdown-menu li a:hover, #header .header-nav-main.header-nav-main-dropdown-modern nav > ul > li.dropdown .dropdown-menu li a:active {
    left: 5px;
  }
  #header .header-nav-main.header-nav-main-dropdown-modern nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
    margin-top: -15px;
  }
  #header .header-nav-main.header-nav-main-dropdown-center nav > ul > li.dropdown:not(.dropdown-mega) > .dropdown-menu {
    transform: translate3d(-50%, 10px, 0) !important;
  }
  #header .header-nav-main.header-nav-main-dropdown-center nav > ul > li.dropdown:not(.dropdown-mega):hover > .dropdown-menu {
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0) !important;
  }
  #header .header-nav-main.header-nav-main-dropdown-center.header-nav-main-dropdown-center-bottom nav > ul > li.dropdown:hover > .dropdown-menu {
    margin-top: -14px !important;
  }
  #header .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown > .dropdown-item {
    position: relative;
  }
  #header .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown > .dropdown-item:before {
    content: "";
    display: block;
    position: absolute;
    top: auto;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
    transform: translate3d(-7px, 10px, 0);
    bottom: 14px;
    margin-top: auto;
    z-index: 1001;
    opacity: 0;
    transition: ease transform 300ms;
  }
  #header .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown:hover > .dropdown-menu {
    margin-top: -14px !important;
    box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.1);
  }
  #header .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown.open > .dropdown-item:before, #header .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown:hover > .dropdown-item:before {
    opacity: 1 !important;
    transform: translate3d(-7px, 0, 0);
  }
  #header .header-nav-main.header-nav-main-dark nav > ul > li > a {
    color: #fff;
  }
  #header .header-nav-main.header-nav-main-slide nav > ul > li > a {
    background-color: transparent;
    color: #FFF;
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    padding: 18px 18px 22px !important;
    margin: 0 !important;
    transition: ease transform 300ms;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  #header .header-nav-main.header-nav-main-slide nav > ul > li > a.dropdown-toggle:after {
    visibility: hidden;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main.header-nav-main-slide nav > ul > li > a.item-original {
    display: none;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main.header-nav-main-slide nav > ul > li > span {
    display: flex;
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  #header .header-nav-main.header-nav-main-slide nav > ul > li > span > a {
    display: flex;
    align-items: center;
    white-space: normal;
    border-radius: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    position: relative;
    background: transparent !important;
    color: #444;
    padding: 0 1.5rem;
    margin: 1px 0 0;
    min-height: 60px;
    height: 100%;
    transition: ease all 300ms;
    transform: translateY(0%);
  }
  #header .header-nav-main.header-nav-main-slide nav > ul > li > span > a.dropdown-toggle:after {
    display: none;
  }
  #header .header-nav-main.header-nav-main-slide nav > ul > li > span > a .fa-caret-down {
    display: none;
  }
  #header .header-nav-main.header-nav-main-slide nav > ul > li > span > a.active:first-child {
    transform: translateY(-40%);
    opacity: 0;
  }
  #header .header-nav-main.header-nav-main-slide nav > ul > li > span > a.active.item-two {
    transform: translateY(0);
    opacity: 1;
  }
  #header .header-nav-main.header-nav-main-slide nav > ul > li > span .item-two {
    position: absolute;
    transform: translateY(40%);
    opacity: 0;
    width: auto;
    left: 0;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main.header-nav-main-slide nav > ul > li:hover > span > a:first-child {
    transform: translateY(-40%);
    opacity: 0;
  }
  #header .header-nav-main.header-nav-main-slide nav > ul > li:hover > span .item-two {
    transform: translateY(0%);
    opacity: 1;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main.header-nav-main-transition nav > ul > li > a {
    transition: ease all 300ms;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main.header-nav-main-text-capitalize nav > ul > li > a {
    text-transform: capitalize;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main.header-nav-main-text-size-2 nav > ul > li > a {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main a > .thumb-info-preview {
    position: absolute;
    display: block;
    left: 100%;
    opacity: 0;
    border: 0;
    padding-left: 10px;
    background: transparent;
    overflow: visible;
    margin-top: 15px;
    top: -10000px;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(-20px, 0, 0);
  }
  #header .header-nav-main a > .thumb-info-preview .thumb-info-wrapper {
    background: #FFF;
    display: block;
    border-radius: 4px;
    border: 0;
    box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
    margin: 0;
    padding: 4px;
    text-align: left;
    width: 190px;
  }
  #header .header-nav-main a > .thumb-info-preview .thumb-info-image {
    transition: all 6s linear 0s;
    width: 182px;
    height: 136px;
    min-height: 0;
  }
  #header .header-nav-main a:hover > .thumb-info-preview {
    transform: translate3d(0, 0, 0);
    top: 0;
    opacity: 1;
    margin-top: -5px;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main .dropdown-reverse a > .thumb-info-preview {
    transform: translate3d(20px, 0, 0);
    right: 100%;
    left: auto;
    padding-left: 0;
    margin-right: 10px;
  }
  #header .header-nav-main .dropdown-reverse a:hover > .thumb-info-preview {
    transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 992px) {
  #header .header-nav-bar {
    z-index: 1;
  }
  #header .header-nav-bar.header-nav-bar-top-border {
    border-top: 1px solid #EDEDED;
  }
  #header .header-nav-bar .header-container {
    min-height: 0;
    height: auto !important;
  }
  #header .header-nav-bar .header-nav {
    min-height: 60px;
  }
  #header .header-nav-bar .header-nav .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown > .dropdown-item:before {
    bottom: 1px !important;
    margin-left: 0;
  }
  #header .header-nav-bar .header-nav .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav-bar .header-nav .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown:hover > .dropdown-menu {
    margin-top: -1px !important;
  }
  #header .header-nav-bar .header-nav-links:not(.justify-content-lg-center) .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li {
    margin-left: 0;
    margin-right: 0;
  }
  #header .header-nav-bar .header-nav-links:not(.justify-content-lg-center) .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown > .dropdown-item {
    padding: 0 0 0 2rem !important;
  }
  #header .header-nav-bar .header-nav-links:not(.justify-content-lg-center) .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown > .dropdown-item:before {
    bottom: 1px !important;
    margin-left: 17px;
  }
  #header .header-nav-bar .header-nav-links:not(.justify-content-lg-center) .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav-bar .header-nav-links:not(.justify-content-lg-center) .header-nav-main.header-nav-main-dropdown-arrow nav > ul > li.dropdown:hover > .dropdown-menu {
    margin-top: -1px !important;
  }
}
@media (min-width: 992px) {
  #header .header-nav {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    align-self: stretch;
  }
  #header .header-nav.header-nav-stripe {
    padding: 0;
  }
  #header .header-nav.header-nav-stripe .header-nav-main {
    align-self: stretch;
    margin-top: -1px;
    min-height: 0;
  }
  #header .header-nav.header-nav-stripe nav {
    display: flex;
  }
  #header .header-nav.header-nav-stripe nav > ul > li {
    display: inline-flex;
    align-self: stretch;
  }
  #header .header-nav.header-nav-stripe nav > ul > li > a {
    background: transparent;
    padding: 0 1.5rem;
    margin: 1px 0 0;
    height: 100%;
  }
  #header .header-nav.header-nav-stripe nav > ul > li > a.active {
    color: #333;
    background: transparent;
  }
  #header .header-nav.header-nav-stripe nav > ul > li:hover > a, #header .header-nav.header-nav-stripe nav > ul > li:hover > a.active, #header .header-nav.header-nav-stripe nav > ul > li.open > a, #header .header-nav.header-nav-stripe nav > ul > li.open > a.active {
    color: #FFF;
    padding-bottom: 0;
  }
  #header .header-nav.header-nav-stripe nav > ul > li.dropdown.open > a:before, #header .header-nav.header-nav-stripe nav > ul > li.dropdown:hover > a:before {
    content: none;
  }
  #header .header-nav.header-nav-stripe nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav.header-nav-stripe nav > ul > li.dropdown:hover > .dropdown-menu {
    top: 100%;
    left: 0;
    margin-top: 1px;
  }
  #header .header-nav.header-nav-stripe nav > ul > li.dropdown.dropdown-reverse > .dropdown-menu {
    left: auto;
  }
  #header .header-nav.header-nav-stripe.header-nav-main-dark nav > ul > li:hover > a {
    color: #FFF !important;
  }
  #header .header-nav.header-nav-stripe nav > ul:not(:hover) > li > a.active {
    color: #FFF !important;
  }
  #header .header-nav.header-nav-stripe nav > ul > li:hover > a.active {
    color: #FFF !important;
  }
  #header .header-nav.header-nav-links, #header .header-nav.header-nav-line {
    padding: 0;
  }
  #header .header-nav.header-nav-links .header-nav-main, #header .header-nav.header-nav-line .header-nav-main {
    align-self: stretch;
    min-height: 0;
    margin-top: 0;
  }
  #header .header-nav.header-nav-links nav > ul > li > a, #header .header-nav.header-nav-links nav > ul > li:hover > a, #header .header-nav.header-nav-line nav > ul > li > a, #header .header-nav.header-nav-line nav > ul > li:hover > a {
    position: relative;
    background: transparent !important;
    padding: 0 1.5rem;
    margin: 1px 0 0;
    min-height: 60px;
    height: 100%;
  }
  #header .header-nav.header-nav-links nav > ul > li:hover > a:before, #header .header-nav.header-nav-links nav > ul > li.open > a:before, #header .header-nav.header-nav-line nav > ul > li:hover > a:before, #header .header-nav.header-nav-line nav > ul > li.open > a:before {
    opacity: 1;
  }
  #header .header-nav.header-nav-links nav > ul > li > a.active, #header .header-nav.header-nav-line nav > ul > li > a.active {
    background: transparent;
    font-weight: 600;
  }
  #header .header-nav.header-nav-links nav > ul > li > a.active:before, #header .header-nav.header-nav-line nav > ul > li > a.active:before {
    opacity: 1;
  }
  #header .header-nav.header-nav-links nav > ul > li.dropdown > a:before, #header .header-nav.header-nav-line nav > ul > li.dropdown > a:before {
    border-bottom: 0;
  }
  #header .header-nav.header-nav-links nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav.header-nav-links nav > ul > li.dropdown:hover > .dropdown-menu, #header .header-nav.header-nav-line nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav.header-nav-line nav > ul > li.dropdown:hover > .dropdown-menu {
    margin-top: 0;
  }
  #header .header-nav.header-nav-links:not(.header-nav-light-text) nav > ul > li > a, #header .header-nav.header-nav-line:not(.header-nav-light-text) nav > ul > li > a {
    color: #0E2D52;
  }
  #header .header-nav.header-nav-links.header-nav-light-text nav > ul > li > a, #header .header-nav.header-nav-line.header-nav-light-text nav > ul > li > a {
    color: #FFF;
  }
  #header .header-nav.header-nav-links.header-nav-force-light-text nav > ul > li > a, #header .header-nav.header-nav-line.header-nav-force-light-text nav > ul > li > a {
    color: #FFF !important;
  }
  #header .header-nav.header-nav-links.header-nav-force-light-text nav > ul > li > a.active, #header .header-nav.header-nav-line.header-nav-force-light-text nav > ul > li > a.active {
    color: #FFF !important;
  }
  #header .header-nav.header-nav-links.header-nav-force-light-text nav > ul > li:hover > a, #header .header-nav.header-nav-links.header-nav-force-light-text nav > ul > li:focus > a, #header .header-nav.header-nav-links.header-nav-force-light-text nav > ul > li:active > a, #header .header-nav.header-nav-line.header-nav-force-light-text nav > ul > li:hover > a, #header .header-nav.header-nav-line.header-nav-force-light-text nav > ul > li:focus > a, #header .header-nav.header-nav-line.header-nav-force-light-text nav > ul > li:active > a {
    color: #FFF !important;
  }
  #header .header-nav.header-nav-line nav > ul > li > a:before, #header .header-nav.header-nav-line nav > ul > li:hover > a:before {
    content: "";
    position: absolute;
    background: transparent;
    width: auto;
    height: 3px;
    top: -2px;
    left: -1px;
    right: -1px;
    opacity: 0;
  }
  #header .header-nav.header-nav-line nav > ul > li:hover > a:before {
    opacity: 1 !important;
  }
  #header .header-nav.header-nav-line.header-nav-top-line nav > ul > li > a:before, #header .header-nav.header-nav-line.header-nav-top-line nav > ul > li:hover > a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    top: -2px;
    left: 0;
  }
  #header .header-nav.header-nav-line.header-nav-top-line.header-nav-top-line-with-border nav > ul > li > a:before, #header .header-nav.header-nav-line.header-nav-top-line.header-nav-top-line-with-border nav > ul > li:hover > a:before {
    top: -4px;
  }
  #header .header-nav.header-nav-line.header-nav-top-line-animated nav > ul > li > a:before {
    transition: all 0.3s ease;
    width: 0%;
    left: 50%;
  }
  #header .header-nav.header-nav-line.header-nav-top-line-animated nav > ul > li:hover > a:before {
    left: 0;
    width: 100%;
  }
  #header .header-nav.header-nav-line.header-nav-bottom-line nav > ul > li > a:before, #header .header-nav.header-nav-line.header-nav-bottom-line nav > ul > li:hover > a:before {
    content: "";
    position: absolute;
    width: auto;
    height: 3px;
    top: 50%;
    left: 15px;
    right: 15px;
    margin-top: 15px;
    transform: translate3d(0, -50%, 0);
  }
  #header .header-nav.header-nav-line.header-nav-bottom-line.header-nav-line-under-text nav > ul > li > a:before, #header .header-nav.header-nav-line.header-nav-bottom-line.header-nav-line-under-text nav > ul > li:hover > a:before {
    left: 0;
    right: 0;
    height: 7px;
    margin: 6px 10px 15px 10px;
    opacity: 0.3 !important;
  }
  #header .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-effect-1 nav > ul > li > a:before {
    transition: cubic-bezier(0.55, 0, 0.1, 1) right 300ms;
    right: 100%;
  }
  #header .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-effect-1 nav > ul > li > a.active:before {
    right: 15px;
  }
  #header .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-effect-1 nav > ul > li:hover > a:before {
    right: 15px;
  }
  #header .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-effect-1.header-nav-line-under-text nav > ul > li > a.active:before {
    right: 0;
  }
  #header .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-effect-1.header-nav-line-under-text nav > ul > li:hover > a:before {
    right: 0;
  }
  #header .header-nav.header-nav-no-space-dropdown nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav.header-nav-no-space-dropdown nav > ul > li.dropdown:hover > .dropdown-menu {
    margin-top: -1px;
  }
  #header .header-nav.header-nav-force-light-text .header-nav-main nav > ul > li > a, #header .header-nav.header-nav-force-light-text .header-nav-main nav > ul > li .wrapper-items-cloned > a {
    color: #FFF !important;
  }
  #header .header-nav.header-nav-force-light-text:not(.header-nav-force-light-text-active-skin-color) .header-nav-main nav > ul > li:hover > a, #header .header-nav.header-nav-force-light-text:not(.header-nav-force-light-text-active-skin-color) .header-nav-main nav > ul > li .wrapper-items-cloned:hover > a {
    color: #333 !important;
  }
  #header .header-nav.header-nav-force-light-text:not(.header-nav-force-light-text-active-skin-color) .header-nav-main nav > ul:not(:hover) > li > a.active, #header .header-nav.header-nav-force-light-text:not(.header-nav-force-light-text-active-skin-color) .header-nav-main nav > ul:not(:hover) > li .wrapper-items-cloned > a.active {
    color: #333 !important;
  }
  #header .header-nav.header-nav-icons nav > ul > li > a [class*=fa-], #header .header-nav.header-nav-icons nav > ul > li > a .icons {
    font-size: 1.1rem;
    margin-right: 0.28rem;
    margin-top: 0.1rem;
  }
  #header .header-nav.header-nav-divisor nav > ul > li {
    margin: 0;
  }
  #header .header-nav.header-nav-divisor nav > ul > li > a {
    border-right: 1px solid rgba(0, 0, 0, 0.07);
  }
  #header .header-nav.header-nav-divisor nav > ul > li:last-child > a {
    border-right: 0;
  }
  #header .header-nav.header-nav-spaced nav > ul > li > a {
    margin: 0 !important;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  #header .header-nav.header-nav-first-item-no-padding nav > ul > li:first-child > a {
    padding-left: 0 !important;
  }
  #header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown > a:before {
    border-bottom: 0;
  }
  #header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown li a {
    border-bottom-color: #2a2a2a;
  }
  #header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown .dropdown-menu {
    background: #1e1e1e;
    margin-top: 0;
  }
  #header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown .dropdown-menu > li > a {
    color: #969696;
  }
  #header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown .dropdown-menu > li:hover > a, #header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown .dropdown-menu > li:focus > a {
    background: #282828;
  }
  #header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-title {
    color: #ababab;
  }
  #header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-nav > li:hover > a, #header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-nav > li:focus > a, #header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-nav > li:active > a, #header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-nav > li.active > a {
    background: #282828;
  }
  #header .header-nav.header-nav-sub-title .header-nav-main nav > ul > li > a, #header .header-nav.header-nav-sub-title-animated .header-nav-main nav > ul > li > a {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
  }
  #header .header-nav.header-nav-sub-title .header-nav-main nav > ul > li > a > span, #header .header-nav.header-nav-sub-title-animated .header-nav-main nav > ul > li > a > span {
    font-size: 0.9em;
    text-transform: none;
    font-weight: normal;
    margin-top: 0.3rem;
  }
  #header .header-nav.header-nav-sub-title .header-nav-main nav > ul > li > a > span {
    margin-top: -0.5rem;
  }
  #header .header-nav.header-nav-sub-title-animated .header-nav-main nav > ul > li > a {
    transition: ease padding-bottom 300ms;
  }
  #header .header-nav.header-nav-sub-title-animated .header-nav-main nav > ul > li > a.active {
    padding-bottom: 15px;
  }
  #header .header-nav.header-nav-sub-title-animated .header-nav-main nav > ul > li > a.active > span {
    top: 60%;
    left: 0;
    opacity: 1;
  }
  #header .header-nav.header-nav-sub-title-animated .header-nav-main nav > ul > li > a > span {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: 0;
    opacity: 0;
    transform: translate3d(0, -50%, 0);
    transition: ease opacity 200ms, ease top 300ms;
  }
  #header .header-nav.header-nav-sub-title-animated .header-nav-main nav > ul > li:hover > a, #header .header-nav.header-nav-sub-title-animated .header-nav-main nav > ul > li:focus > a {
    padding-bottom: 15px;
  }
  #header .header-nav.header-nav-sub-title-animated .header-nav-main nav > ul > li:hover > a > span, #header .header-nav.header-nav-sub-title-animated .header-nav-main nav > ul > li:focus > a > span {
    top: 60%;
    left: 0;
    opacity: 1;
  }
  #header .header-nav.header-nav-links-side-header nav > ul > li > a {
    padding: 10px 15px !important;
    min-height: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0;
  }
  #header .header-nav.header-nav-links-side-header nav > ul > li:last-child > a {
    border-bottom: 0;
  }
  #header .header-nav.header-nav-links-side-header nav > ul > li.dropdown {
    position: static;
  }
  #header .header-nav.header-nav-links-side-header nav > ul > li.dropdown > .dropdown-item {
    position: relative;
  }
  #header .header-nav.header-nav-links-side-header nav > ul > li.dropdown > .dropdown-item:before {
    font-family: "Font Awesome 5 Free";
    content: "\f054" !important;
    font-weight: 900;
    position: absolute;
    top: 50%;
    font-size: 0.5rem;
    opacity: 0.7 !important;
    left: auto;
    right: 20px;
    transform: translateY(-50%);
    transition: all 0.2s ease-out;
    display: block !important;
  }
  #header .header-nav.header-nav-links-side-header nav > ul > li.dropdown:hover > .dropdown-item:before {
    right: 15px;
    opacity: 1 !important;
  }
  #header .header-nav.header-nav-click-to-open nav > ul > li.dropdown .dropdown-menu,
#header .header-nav.header-nav-click-to-open nav > ul > li.dropdown li.dropdown-submenu > .dropdown-menu {
    transition: opacity 0.2s ease-out;
  }
  #header .header-nav.header-nav-click-to-open nav > ul > li.dropdown:hover:not(.open) .dropdown-menu {
    top: -10000px;
    opacity: 0;
  }
  #header .header-nav.header-nav-click-to-open nav > ul > li.dropdown.open .dropdown-menu > li.dropdown-submenu:hover:not(.open) .dropdown-menu {
    top: -10000px;
    opacity: 0;
  }
}
/* Header Arrows */
@media (min-width: 992px) {
  #header .header-nav-main-arrows nav > ul > li > a.dropdown-toggle:after {
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    content: "\f078";
    font-weight: 900;
    border: 0;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0 0 0 4px;
    font-size: 0.6rem;
  }
}
/* Header Nav Main Dark Text */
@media (min-width: 992px) {
  #header .header-nav-main-dark-text nav > .nav-pills > li > a {
    color: #333;
  }
}
/* Header Nav Main Colors */
@media (min-width: 992px) {
  html #header .header-nav-main nav > ul > li.dropdown-full-color .dropdown-menu {
    border-top: 0;
  }
  html #header .header-nav-main nav > ul > li.dropdown-full-color .dropdown-menu li.dropdown-submenu > .dropdown-menu {
    margin-top: -5px;
  }
}
/* Header Nav Main Dark Text */
@media (min-width: 992px) {
  #header .header-nav-main-rounded nav > .nav-pills > li > a {
    justify-content: center;
    align-self: center;
    border-radius: 1.5rem;
  }
  #header .header-nav-main-rounded nav > .nav-pills > li > a:before {
    display: none !important;
  }
  #header .header-nav-main-rounded nav > ul > li.dropdown.open > a, #header .header-nav-main-rounded nav > ul > li.dropdown:hover > a {
    border-radius: 1.5rem;
  }
  #header .header-nav-main-rounded nav > ul > li.dropdown .dropdown-menu {
    border-radius: 4px;
  }
}
/* Header Nav Main Stretch */
@media (min-width: 992px) {
  #header .header-nav-stretch {
    padding-top: 0;
    padding-bottom: 0;
  }
  #header .header-nav-stretch .header-nav-main {
    align-self: stretch;
  }
  #header .header-nav-stretch nav > ul > li {
    display: inline-flex;
    align-self: stretch;
  }
  #header .header-nav-stretch nav > ul > li.dropdown.open > a:before, #header .header-nav-stretch nav > ul > li.dropdown:hover > a:before {
    content: none;
  }
  #header .header-nav-stretch nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav-stretch nav > ul > li.dropdown:hover > .dropdown-menu {
    top: 100%;
    left: 0;
    margin-top: -1px;
  }
}
/* Header Narrow */
@media (min-width: 992px) {
  #header.header-narrow .header-nav.header-nav-stripe nav > ul > li > a, #header.header-narrow .header-nav.header-nav-dropdowns-dark nav > ul > li > a, #header.header-narrow .header-nav.header-nav-links nav > ul > li > a, #header.header-narrow .header-nav.header-nav-line nav > ul > li > a {
    min-height: 60px;
  }
}
/* Header Nav Vertical */
@media (min-width: 992px) {
  html.side-header #header.side-header .side-header-scrollable,
html.side-header-hamburguer-sidebar #header.side-header .side-header-scrollable {
    height: 100%;
    margin-right: -13px;
  }
  html.side-header #header.side-header .side-header-scrollable .scrollable-content,
html.side-header-hamburguer-sidebar #header.side-header .side-header-scrollable .scrollable-content {
    padding-right: 10px;
    padding-left: 0;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li > a.dropdown-toggle .fa-chevron-down,
html.side-header-hamburguer-sidebar #header.side-header .header-nav-main nav > ul > li > a.dropdown-toggle .fa-chevron-down {
    display: block;
    position: absolute;
    right: 7px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    font-size: 0.5rem;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li > a.dropdown-toggle .fa-chevron-down:before,
html.side-header-hamburguer-sidebar #header.side-header .header-nav-main nav > ul > li > a.dropdown-toggle .fa-chevron-down:before {
    content: none;
  }
  html.side-header #header.side-header .header-nav nav,
html.side-header-hamburguer-sidebar #header.side-header .header-nav nav {
    display: block !important;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-dropdown nav > ul > li.dropdown,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-dropdown nav > ul > li.dropdown {
    position: relative;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown:before,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown:before {
    top: 23px;
    right: 20px;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown > .dropdown-menu li a,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown > .dropdown-menu li a {
    border: 0;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown:not(.open) .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown:not(.open) .dropdown-menu {
    overflow: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    top: -10000px;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.open > .dropdown-menu,
html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.open > .dropdown-menu li.dropdown-submenu.open > .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.open > .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.open > .dropdown-menu li.dropdown-submenu.open > .dropdown-menu {
    position: relative;
    box-shadow: none;
    margin: 0;
    padding: 5px 0 5px 15px;
    left: auto;
    right: auto;
    transform: none;
    min-width: auto;
    width: 100%;
    opacity: 1 !important;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.open > .dropdown-menu li.dropdown-submenu.open > a:after,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.open > .dropdown-menu li.dropdown-submenu.open > a:after {
    right: 20px;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-menu {
    min-width: 0;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content {
    padding: 5px 0 5px 15px;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content [class*=col],
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content [class*=col] {
    max-width: 100%;
    display: block;
    padding: 5px 0;
    flex: none;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content .dropdown-mega-sub-title,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content .dropdown-mega-sub-title {
    padding-left: 15px;
    font-size: 0.75em;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content .dropdown-mega-sub-nav,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content .dropdown-mega-sub-nav {
    padding-left: 30px;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand .thumb-info-preview,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand .thumb-info-preview {
    display: none;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns {
    position: relative;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav {
    margin-top: -10px;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown {
    position: static;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu {
    box-shadow: none;
    top: 50% !important;
    transform: translateY(-50%);
    left: 10000px;
    margin-left: -15px;
    margin-top: 10px;
    opacity: 0;
    transition: margin-left 0.2s ease-out, opacity 0.2s ease-out;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu:before,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu:before {
    content: "";
    display: block;
    position: absolute;
    top: -100vh;
    width: 100%;
    height: 100%;
    background: #FCFCFC;
    border-right: 1px solid #f7f7f7;
    border-left: 1px solid #f7f7f7;
    height: 200vh;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu {
    position: static;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
    margin-left: 0;
    left: 100%;
    right: auto;
    margin-left: -15px;
    opacity: 0;
    transition: margin-left 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(10000px, 0, 0);
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu:before,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu:before {
    border-left: 0;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
    right: auto;
    opacity: 1;
    margin-left: 0;
    transform: translateY(-50%);
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse > .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse > .dropdown-menu {
    left: auto;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse:hover > .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse:hover > .dropdown-menu {
    right: 100%;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown:hover > .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown:hover > .dropdown-menu {
    margin-left: 0;
    margin-top: 0;
    padding-top: 0;
    opacity: 1;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown-mega,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns nav > ul > li.dropdown-mega {
    position: static !important;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-columns .thumb-info-preview,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-columns .thumb-info-preview {
    display: none;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide {
    height: 100%;
    justify-content: center;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide .header-nav-main,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide .header-nav-main {
    height: 100% !important;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav > ul,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav > ul {
    height: 100%;
    justify-content: center;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav > ul > li,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav > ul > li {
    height: auto;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul {
    transform: translate3d(0, 0, 0);
    transition: ease all 300ms;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul.next-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul.next-menu {
    transform: translate3d(-100%, 0, 0);
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown > a.dropdown-toggle,
html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown > a.dropdown-toggle,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item {
    display: inline-block;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown > a.dropdown-toggle:before,
html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item:before,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown > a.dropdown-toggle:before,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item:before {
    display: none !important;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown > a.dropdown-toggle:after,
html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item:after,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown > a.dropdown-toggle:after,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item:after {
    font-family: "Font Awesome 5 Free";
    content: "\f054";
    font-weight: 900;
    position: relative;
    font-size: 0.5rem;
    opacity: 0.7;
    display: inline-block;
    border: 0;
    top: 13px;
    left: 5px;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown > a.dropdown-toggle:hover:after,
html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item:hover:after,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown > a.dropdown-toggle:hover:after,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item:hover:after {
    animation: sideMenuArrow 600ms ease;
    animation-iteration-count: infinite;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item {
    background: transparent;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item:after,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li.dropdown-submenu > a.dropdown-item:after {
    top: -1px;
    margin-left: 5px;
    transform: none;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li a,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul > li.dropdown .dropdown-menu li a {
    background: transparent;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li {
    position: static;
    text-align: center;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li a,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li a {
    display: inline-block;
    width: 100%;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li ul,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li ul {
    visibility: hidden;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li ul.visible,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li ul.visible {
    visibility: visible;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.back-button > a,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.back-button > a {
    cursor: pointer;
    background: transparent;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.back-button > a:before,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.back-button > a:before {
    font-family: "Font Awesome 5 Free";
    content: "\f053";
    font-weight: 900;
    position: relative;
    font-size: 0.5rem;
    opacity: 0.7;
    display: inline-block;
    border: 0;
    top: -1px;
    right: 5px;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.back-button > a:hover:before,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.back-button > a:hover:before {
    animation: sideMenuArrowBack 600ms ease;
    animation-iteration-count: infinite;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 50% !important;
    left: 100%;
    padding: 0;
    margin: 0 !important;
    opacity: 1;
    border-top: none;
    box-shadow: none;
    transform: translate3d(0, -50%, 0) !important;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu::-webkit-scrollbar,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu::-webkit-scrollbar {
    width: 5px;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu::-webkit-scrollbar-thumb,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background: rgba(204, 204, 204, 0.5);
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu.next-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu.next-menu {
    transform: translate3d(-100%, -50%, 0) !important;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu li.dropdown-submenu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu li.dropdown-submenu {
    position: static;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown-submenu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-slide nav ul li.dropdown-submenu {
    position: static;
  }
}
/* Header Floating */
#header.header-floating-icons .nav {
  flex-direction: column;
}
#header.header-floating-icons .header-nav-main nav > ul > li {
  flex: 0 0 auto;
  margin-left: 0;
}
#header.header-floating-icons .header-nav-main nav > ul > li > a > i {
  pointer-events: none;
}
#header.header-floating-icons .header-nav-main nav > ul > li > a.active {
  background: #FFF !important;
}
#header.header-floating-icons .header-nav-main nav > ul > li > a.active:after {
  background: #FFF;
}

@media (min-width: 992px) {
  #header.header-floating-icons {
    background: transparent;
    height: 0;
    min-height: 0 !important;
  }
  #header.header-floating-icons .header-nav-main nav > ul > li {
    height: auto;
  }
  #header.header-floating-icons .header-row {
    display: block;
    position: absolute;
    top: 12px;
    left: -60px;
  }
  #header.header-floating-icons .header-body {
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
    min-height: 0;
    padding: 0;
    border: none;
  }
  #header.header-floating-icons .header-nav {
    padding: 0 !important;
  }
  #header.header-floating-icons .header-nav-main {
    margin: 0;
    box-shadow: 0px 0px 80px 0px rgba(62, 62, 62, 0.3);
  }
  #header.header-floating-icons .header-nav-main nav > ul > li {
    border-bottom: 1px solid #CCC;
  }
  #header.header-floating-icons .header-nav-main nav > ul > li:hover > a {
    background: #FFF;
  }
  #header.header-floating-icons .header-nav-main nav > ul > li:hover > a > span {
    opacity: 1;
    transform: rotateY(0deg);
  }
  #header.header-floating-icons .header-nav-main nav > ul > li > a {
    font-size: 25px;
    padding: 18px;
    perspective: 770px;
    background: #F7F7F7;
    border-radius: 0;
    transform: translateZ(0);
  }
  #header.header-floating-icons .header-nav-main nav > ul > li > a:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 102%;
    z-index: -1;
  }
  #header.header-floating-icons .header-nav-main nav > ul > li > a > span {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 125.5%;
    background: #FFF;
    padding: 0 25px;
    height: 100%;
    line-height: 4.1;
    white-space: nowrap;
    font-size: 14px;
    z-index: -2;
    transition: ease transform 300ms;
    box-shadow: 0px 0px 80px 0px rgba(62, 62, 62, 0.15);
    transform-origin: -19% 0%;
    transform: rotateY(88deg);
  }
  #header.header-floating-icons .header-nav-main nav > ul > li > a > span:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -4px;
    padding: 10px;
    background: #FFF;
    z-index: -1;
    transform: translate(0, -50%) rotate(45deg);
  }
}
@media (min-width: 992px) and (max-width: 1281px) {
  #header.header-floating-icons .header-row {
    left: -19px;
  }
  #header.header-floating-icons .header-nav-main nav > ul > li > a {
    padding: 9.8px;
    perspective: 450px;
  }
  #header.header-floating-icons .header-nav-main nav > ul > li > a > span {
    line-height: 3;
  }
}
@media (max-width: 991px) {
  #header.header-floating-icons {
    position: fixed;
    top: 23px;
    right: 10px;
  }
  #header.header-floating-icons .header-body {
    background: transparent;
    border: none;
    min-height: 0;
  }
  #header.header-floating-icons .header-btn-collapse-nav {
    padding: 15px 20px;
    margin: 0;
  }
  #header.header-floating-icons .header-nav-main {
    position: absolute;
    right: 0px;
    top: 77px;
    max-width: 53px;
    overflow: hidden;
    box-shadow: 0px 0px 15px 0px rgba(62, 62, 62, 0.2);
  }
  #header.header-floating-icons .header-nav-main:before {
    content: none;
  }
  #header.header-floating-icons .header-nav-main nav {
    padding: 0;
    margin: 0 !important;
    max-height: none;
  }
  #header.header-floating-icons .header-nav-main nav > ul {
    display: block;
    padding: 0;
  }
  #header.header-floating-icons .header-nav-main nav > ul > li {
    display: inline-block;
    border: none;
  }
  #header.header-floating-icons .header-nav-main nav > ul > li > a {
    font-size: 1.7em;
    margin: 0;
    padding: 15px;
    background: #F7F7F7;
    border-radius: 0;
    border-bottom: 1px solid #e2e2e2;
  }
  #header.header-floating-icons .header-nav-main nav > ul > li > a > span {
    display: none;
  }
  #header.header-floating-icons .header-nav-main nav > ul > li:last-child > a {
    border-bottom: 0;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main.header-nav-main-light nav > ul > li > a {
    color: #FFF;
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li > a.active {
    color: #CCC;
    background: #FFF;
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li.open > a, #header .header-nav-main.header-nav-main-light nav > ul > li.active > a, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > a {
    background: #FFF;
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li.open > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li.active > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > .dropdown-menu {
    border-top-color: #FFF;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li.open > .dropdown-menu .dropdown-submenu:hover > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li.active > .dropdown-menu .dropdown-submenu:hover > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > .dropdown-menu .dropdown-submenu:hover > .dropdown-menu {
    border-top-color: #FFF;
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li.active > a {
    background: #FFF;
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li.dropdown.open > a:before, #header .header-nav-main.header-nav-main-light nav > ul > li.dropdown:hover > a:before {
    border-bottom-color: #FFF;
  }
  #header .header-nav-main.header-nav-main-light .dropdown-menu > li > a:hover, #header .header-nav-main.header-nav-main-light .dropdown-menu > li > a:focus {
    background: #f5f5f5;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-menu li a, #header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-mega-sub-nav li a {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
  }
  #header .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu li a, #header .header-nav-main-effect-1 nav > ul > li.dropdown:hover .dropdown-mega-sub-nav li a {
    transform: translate3d(0, 0, 0);
  }
  #header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-menu {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
  }
  #header .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 2px, 0);
  }
  #header .header-nav.header-nav-stripe .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu, #header .header-nav.header-nav-links .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu, #header .header-nav.header-nav-line .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu, #header .header-nav.header-nav-dropdowns-dark .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-effect-2 nav > ul > li.dropdown .dropdown-menu {
    transition: opacity 0.2s ease-out;
    opacity: 0;
  }
  #header .header-nav-main-effect-2 nav > ul > li.dropdown:hover > .dropdown-menu {
    opacity: 1;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-effect-3 nav > ul > li.dropdown .dropdown-menu {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, 10px, 0);
  }
  #header .header-nav-main-effect-3 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-effect-4 nav > ul > li.dropdown .dropdown-menu {
    transition: transform 0.2s ease-out;
    transform: translate3d(-10px, 0, 0);
  }
  #header .header-nav-main-effect-4 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-effect-5 nav > ul > li.dropdown .dropdown-menu {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -10px, 0);
  }
  #header .header-nav-main-effect-5 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-effect-6 nav > ul > li.dropdown .dropdown-menu {
    transition: transform 0.2s ease-out;
    transform: translate3d(10px, 0, 0);
  }
  #header .header-nav-main-effect-6 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(-10px, 0, 0);
    opacity: 0;
  }
  #header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  #header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse > .dropdown-menu {
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(10px, 0, 0);
    left: auto;
    right: 100%;
    opacity: 0;
  }
  #header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
/* Header Nav Main Mobile */
@media (max-width: 991px) {
  #header .header-nav-main {
    position: absolute;
    background: transparent;
    width: 100%;
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  #header .header-nav-main:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    background: #fff;
    z-index: -1;
    transform: translateX(-50%);
  }
  #header .header-nav-main nav {
    max-height: 56vh;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 15px;
    transition: ease all 500ms;
  }
  #header .header-nav-main nav.collapsing {
    overflow-y: hidden;
  }
  #header .header-nav-main nav::-webkit-scrollbar {
    width: 5px;
  }
  #header .header-nav-main nav::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0;
    border-radius: 0;
    background: rgba(204, 204, 204, 0.5);
  }
  #header .header-nav-main nav > ul {
    padding-top: 15px;
    padding-bottom: 15px;
    flex-direction: column;
  }
  #header .header-nav-main nav > ul li {
    border-bottom: 1px solid #e8e8e8;
    clear: both;
    display: block;
    float: none;
    margin: 0;
    padding: 5px 0;
    position: relative;
  }
  #header .header-nav-main nav > ul li a {
    font-size: 20px;
    font-style: normal;
    line-height: 30px;
    padding: 7px 8px;
    margin: 1px 0;
    border-radius: 4px;
    text-align: left;
  }
  #header .header-nav-main nav > ul li a .fa-caret-down {
    line-height: 35px;
    min-height: 38px;
    min-width: 30px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 0;
  }
  #header .header-nav-main nav > ul li a.dropdown-toggle {
    position: relative;
  }
  #header .header-nav-main nav > ul li a.dropdown-toggle:after {
    content: none;
  }
  #header .header-nav-main nav > ul li a.dropdown-toggle .fa-chevron-down {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
  }
  #header .header-nav-main nav > ul li a:active {
    background-color: #f8f9fa;
    color: inherit;
  }
  #header .header-nav-main nav > ul li.dropdown .dropdown-menu {
    background: transparent;
    padding: 0;
    margin: 0;
    font-size: 13px;
    box-shadow: none;
    border-radius: 0;
    border: 0;
    clear: both;
    display: none;
    float: none;
    position: static;
  }
  #header .header-nav-main nav > ul li.dropdown .dropdown-menu li.dropdown-submenu > a {
    position: relative;
  }
  #header .header-nav-main nav > ul li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
    content: none;
  }
  #header .header-nav-main nav > ul li.dropdown .dropdown-menu li.dropdown-submenu > a .fa-chevron-down {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
  }
  #header .header-nav-main nav > ul li.dropdown .dropdown-menu li.dropdown-submenu.open > .dropdown-menu {
    display: block;
    margin-left: 20px;
  }
  #header .header-nav-main nav > ul li.dropdown.open > .dropdown-menu {
    display: block;
    margin-left: 20px;
  }
  #header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-title {
    margin-top: 10px;
    display: block;
    text-align: left;
  }
  #header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-nav {
    margin: 0 0 0 20px;
    padding: 0;
    list-style: none;
  }
  #header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-nav > li > a {
    display: block;
    text-decoration: none;
  }
  #header .header-nav-main nav > ul li:last-child {
    border-bottom: 0;
  }
  #header .header-nav-main nav > ul > li > a {
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #0E2D52;
  }
  #header .header-nav-main nav > ul > li > a:active {
    color: #0E2D52;
  }
  #header .header-nav-main nav > ul > li > a.active {
    color: #0E2D52 !important;
    background: #fff;
  }
  #header .header-nav-main nav > ul > li > a.active:focus, #header .header-nav-main nav > ul > li > a.active:hover {
    color: #0E2D52;
    background: #fff;
  }
  #header .header-nav-main nav .not-included {
    margin: 0;
  }
  #header .header-nav-main a > .thumb-info-preview {
    display: none !important;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li a {
    border-radius: 0 !important;
  }
  #header .header-nav-main.header-nav-main-slide .wrapper-items-cloned {
    display: none;
  }
  #header .header-nav-bar .header-container {
    min-height: 0;
    height: auto !important;
  }
  #header .header-nav-bar .header-nav {
    min-height: 60px !important;
    padding: 0;
  }
  #header .header-btn-collapse-nav {
    outline: 0;
    display: block;
    position: relative;
    z-index: 1;
  }
  #header .header-nav.header-nav-stripe {
    min-height: 90px;
  }
  #header .header-nav.header-nav-links, #header .header-nav.header-nav-line {
    min-height: 70px;
  }
  #header .header-nav.header-nav-dropdowns-dark {
    min-height: 70px;
  }
  #header .header-nav.header-nav-sub-title .header-nav-main nav > ul > li > a > span, #header .header-nav.header-nav-sub-title-animated .header-nav-main nav > ul > li > a > span {
    display: block;
    text-transform: none;
    font-weight: normal;
  }
}
/* Header Nav Main Mobile Dark */
@media (max-width: 991px) {
  #header .header-nav-main.header-nav-main-mobile-dark:before {
    background-color: #333;
  }
  #header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a {
    color: #dedede;
  }
  #header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active {
    color: #FFF;
  }
  #header .header-nav-main.header-nav-main-mobile-dark nav > ul > li .dropdown-menu > li a {
    color: #969696;
  }
  #header .header-nav-main.header-nav-main-mobile-dark nav > ul > li .dropdown-menu > li a.active {
    color: #FFF;
  }
  #header .header-nav-main.header-nav-main-mobile-dark nav > ul li {
    border-bottom: 1px solid #3e3e3e;
  }
  #header .header-nav-main.header-nav-main-mobile-dark nav > ul li:last-child {
    border-bottom: 0;
  }
  #header .header-nav-main.header-nav-main-mobile-dark nav > ul li a:hover, #header .header-nav-main.header-nav-main-mobile-dark nav > ul li a:focus, #header .header-nav-main.header-nav-main-mobile-dark nav > ul li a:active {
    background-color: #2b2b2b;
  }
  #header .header-nav-main.header-nav-main-mobile-dark nav > ul li.dropdown-mega .dropdown-mega-sub-title {
    color: #eaeaea;
  }
}
/* Header Nav Main Dark Text */
@media (max-width: 991px) {
  #header .header-nav-main.header-nav-main-dark-text nav > ul > li > a {
    color: #333;
  }
  #header .header-nav-main.header-nav-main-dark-text nav > ul > li > a.active {
    color: #FFF;
  }
}
/* Header Nav Vertical */
@media (max-width: 991px) {
  html.side-header #header.side-header .side-header-scrollable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  html.side-header #header.side-header .side-header-scrollable .scrollable-content {
    right: 0 !important;
    overflow: visible;
  }
  html.side-header #header.side-header .side-header-scrollable .scrollable-pane {
    display: none !important;
  }
}
/* Side Header Hamburguer Sidebar */
@media (max-width: 991px) {
  html.side-header-hamburguer-sidebar #header.side-header .header-row-side-header {
    width: 100%;
  }
  html.side-header-hamburguer-sidebar #header.side-header .side-header-scrollable {
    height: 100%;
  }
  html.side-header-hamburguer-sidebar #header.side-header .side-header-scrollable .scrollable-content {
    right: 0 !important;
    padding-right: 10px;
    padding-left: 10px;
    overflow-y: hidden;
  }
  html.side-header-hamburguer-sidebar #header.side-header .side-header-scrollable .scrollable-pane {
    display: none !important;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav {
    height: 100%;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav .header-nav-main {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav nav {
    display: block !important;
    max-height: 69vh;
    padding: 0 8px 0 0;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links {
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main {
    align-self: stretch;
    min-height: 0;
    margin-top: 0;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a {
    color: #dedede !important;
    border-bottom-color: #3e3e3e;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active {
    color: #FFF !important;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main.header-nav-main-mobile-dark nav > ul > li .dropdown-menu > li a {
    color: #969696;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main.header-nav-main-mobile-dark nav > ul > li .dropdown-menu > li a.active {
    color: #FFF;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main.header-nav-main-mobile-dark nav > ul li {
    border-bottom-color: #3e3e3e;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main.header-nav-main-mobile-dark nav > ul li a:hover, html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main.header-nav-main-mobile-dark nav > ul li a:focus, html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main.header-nav-main-mobile-dark nav > ul li a:active {
    background-color: #2b2b2b;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul > li > a, html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul > li:hover > a {
    position: relative;
    background: transparent !important;
    color: #444 !important;
    padding: 0 0.9rem;
    margin: 1px 0 0;
    min-height: 70px;
    height: 100%;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul > li > a.dropdown-toggle:after {
    content: none;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul > li > a.active {
    color: #CCC;
    background: transparent;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul > li.dropdown.open > .dropdown-menu, html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul > li.dropdown:hover > .dropdown-menu {
    margin-top: 0;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul li {
    border-bottom: 0;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul li a {
    color: #444;
    font-size: 12px;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-side-header nav > ul > li > a {
    padding: 10px 15px !important;
    min-height: 0 !important;
    border-bottom: 1px solid #f7f7f7;
    border-radius: 0;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-side-header nav > ul > li:last-child > a {
    border-bottom: 0;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-side-header nav > ul > li.dropdown {
    position: static;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown > .dropdown-menu li a {
    border: 0;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown:not(.open) .dropdown-menu {
    overflow: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    top: -10000px;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.open > .dropdown-menu,
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.open > .dropdown-menu li.dropdown-submenu.open > .dropdown-menu {
    position: relative;
    box-shadow: none;
    margin: 0;
    padding: 5px 0 5px 15px;
    left: auto;
    right: auto;
    transform: none;
    min-width: auto;
    width: 100%;
    opacity: 1 !important;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-menu {
    min-width: 0;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content {
    padding: 5px 0 5px 15px;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content [class*=col] {
    max-width: 100%;
    display: block;
    padding: 5px 0;
    flex: none;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content .dropdown-mega-sub-title {
    margin-top: 0;
    padding-left: 15px;
    color: #333333;
    font-size: 0.85em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.5px;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content .dropdown-mega-sub-nav {
    padding-left: 10px;
  }
  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links-vertical-expand .thumb-info-preview {
    display: none;
  }
}
/* Responsive */
@media (min-width: 992px) {
  #header .header-body:not(.h-100) {
    height: auto !important;
  }
}
@media (max-width: 991px) {
  #header .header-logo img {
    z-index: 1;
  }
  #header .header-nav {
    display: flex;
    align-items: center;
  }
  #header.header-narrow .header-nav.header-nav-stripe {
    min-height: 70px;
  }
}
/* Mega Menu (Signin/SignUp) */
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu {
  width: 40%;
  right: 15px;
  left: auto;
  border-radius: 6px 0 6px 6px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table {
  width: 100%;
  margin-top: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a {
  background-color: transparent !important;
  color: #333;
  padding: 0;
  margin: 0;
  display: block;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a.remove {
  float: right;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a:hover {
  text-decoration: none;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .fa-shopping-cart {
  margin-right: 5px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .product-thumbnail {
  width: 120px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .product-actions {
  text-align: right;
  width: 80px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .actions-continue {
  margin-top: 10px;
  padding-top: 20px;
  border-top: 1px solid #DDD;
  clear: both;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .actions-continue .btn {
  margin-bottom: 10px;
}

/* Responsive */
@media (max-width: 991px) {
  #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu {
    margin: 15px 0;
    padding: 0 15px;
    width: 100%;
  }
}
@media (min-width: 992px) {
  html.side-header .main .container, html.side-header #footer .container {
    padding: 0 35px;
    width: auto !important;
    max-width: 1210px;
  }
  html.side-header:not(.side-header-above) body > .body {
    margin: 0 0 0 255px;
    width: auto;
    overflow-x: hidden;
    overflow-y: visible;
  }
  html.side-header:not(.side-header-above) body > .body .forcefullwidth_wrapper_tp_banner .rev_slider_wrapper {
    width: 100% !important;
    left: auto !important;
  }
  html.side-header.side-header-above #header.side-header {
    transition: cubic-bezier(0.55, 0, 0.1, 1) transform 300ms;
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  html.side-header.side-header-change-container .main .container, html.side-header.side-header-change-container #footer .container {
    transform: translateX(125px);
  }
}
@media (min-width: 992px) and (max-width: 1440px) {
  html.side-header.side-header-change-container .main .container, html.side-header.side-header-change-container #footer .container {
    max-width: 960px;
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  html.side-header.side-header-change-container .home-concept {
    transform: translateX(125px);
  }
  html.side-header.side-header-change-container .home-concept .container {
    transform: translateX(0);
  }
}
@media (min-width: 992px) and (min-width: 1200px) and (max-width: 1440px) {
  html.side-header.side-header-change-container .home-concept .container > .row {
    margin-left: -6rem;
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  html.side-header.side-header-change-container.side-header-right .main .container, html.side-header.side-header-change-container.side-header-right #footer .container {
    transform: translateX(-125px);
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  html.side-header.side-header-change-container.side-header-right .home-concept {
    transform: translateX(-125px);
  }
}
@media (min-width: 992px) and (min-width: 1200px) and (max-width: 1440px) {
  html.side-header.side-header-change-container.side-header-right .home-concept .container > .row {
    margin-left: -6rem;
  }
}
@media (min-width: 992px) {
  html.side-header #header.side-header {
    position: fixed;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    top: 0;
    left: 0;
    max-width: 255px;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    transition: cubic-bezier(0.55, 0, 0.1, 1) transform 300ms;
  }
  html.side-header #header.side-header:not(.header-transparent) {
    background: #FFF;
  }
  html.side-header #header.side-header.side-header-lg {
    max-width: 355px;
  }
  html.side-header #header.side-header.side-header-hide {
    transform: translateX(100%);
  }
  html.side-header #header.side-header .header-body {
    border-top: 0;
  }
  html.side-header #header.side-header .header-container {
    width: 100%;
  }
  html.side-header #header.side-header .header-row {
    flex-direction: column;
  }
  html.side-header #header.side-header .header-search {
    width: 100%;
    margin: 0.7rem 0;
  }
  html.side-header #header.side-header .header-nav-top .nav > li > a, html.side-header #header.side-header .header-nav-top .nav > li > span {
    display: block;
  }
  html.side-header #header.side-header .header-logo {
    margin: 2rem 0;
  }
  html.side-header #header.side-header .header-nav {
    flex-direction: column;
  }
  html.side-header #header.side-header .header-nav.header-nav-links nav > ul > li > a {
    height: auto;
  }
  html.side-header #header.side-header .header-nav.header-nav-links-vertical-expand .header-nav-main {
    overflow: hidden;
  }
  html.side-header #header.side-header .header-nav-main,
html.side-header #header.side-header .header-nav-main nav,
html.side-header #header.side-header .header-nav-main .nav {
    width: 100%;
  }
  html.side-header #header.side-header .nav {
    flex-direction: column;
    width: 100%;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle:after {
    content: "";
    border-color: transparent transparent transparent #CCC;
    position: absolute;
    right: 15px;
    top: 50%;
    border-top: 0.3em solid;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
    border-right: 0;
    transform: translate3d(0, -50%, 0);
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header #header.side-header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
    top: 3px;
    left: 100%;
    border-top: 0;
    border-left: 5px solid #CCC;
    margin-left: -5px;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li.dropdown li.dropdown-submenu:hover > .dropdown-menu {
    margin-top: -5px;
    border-top: 0;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li.dropdown.open > a, html.side-header #header.side-header .header-nav-main nav > ul > li.dropdown:hover > a {
    padding-bottom: 0.5rem;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li.dropdown.open > a:before, html.side-header #header.side-header .header-nav-main nav > ul > li.dropdown:hover > a:before {
    content: none;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li.dropdown-mega {
    position: relative !important;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li.dropdown-mega .dropdown-menu {
    min-width: 720px;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li {
    margin-top: 1px;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li > a {
    display: flex;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li .dropdown-menu.dropdown-reverse li a {
    padding-right: 8px;
    padding-left: 20px;
  }
  html.side-header #header.side-header .header-nav-main nav > ul > li .dropdown-menu.dropdown-reverse li.dropdown-submenu > a:after {
    border-width: 4px 4px 4px 0;
  }
}
@media (min-width: 992px) {
  html.side-header-right:not(.side-header-above) body > .body {
    margin: 0 255px 0 0;
  }
  html.side-header-right #header.side-header {
    left: auto;
    right: 0;
  }
  html.side-header-right #header.side-header .header-nav:not(.header-nav-links-vertical-slide):not(.header-nav-links-vertical-expand) .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle {
    padding-right: 0px;
    padding-left: 30px;
  }
  html.side-header-right #header.side-header .header-nav:not(.header-nav-links-vertical-slide):not(.header-nav-links-vertical-expand) .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle:after {
    left: 15px;
    right: auto;
    border-right: 0.3em solid transparent;
    border-left: 0;
  }
  html.side-header-right #header.side-header .header-nav:not(.header-nav-links-vertical-slide):not(.header-nav-links-vertical-expand) .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header-right #header.side-header .header-nav:not(.header-nav-links-vertical-slide):not(.header-nav-links-vertical-expand) .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    right: 100%;
    left: auto;
    border-right: 5px solid #CCC;
    border-left: 0;
    margin-left: 0;
    margin-right: -5px;
    border-radius: 4px 0 0 4px;
    opacity: 1;
  }
  html.side-header-right #header.side-header .header-nav:not(.header-nav-links-vertical-slide):not(.header-nav-links-vertical-expand).header-nav-links-vertical-columns .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header-right #header.side-header .header-nav:not(.header-nav-links-vertical-slide):not(.header-nav-links-vertical-expand).header-nav-links-vertical-columns .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
    height: 100vh;
  }
  html.side-header-right #header.side-header.side-header-hide {
    transform: translateX(-100%);
  }
  html.side-header-right.side-header-right-no-reverse #header.side-header .header-nav:not(.header-nav-links-vertical-slide) .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a {
    padding-left: 25px;
  }
  html.side-header-right.side-header-right-no-reverse #header.side-header .header-nav:not(.header-nav-links-vertical-slide) .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
    left: initial;
    right: 20px;
  }
}
@media (min-width: 992px) {
  html.ie.side-header-right #header.side-header .header-nav:not(.header-nav-links-vertical-slide) .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
    left: 10px;
    right: initial;
    width: 0;
  }
}
@media (min-width: 992px) {
  html.side-header-semi-transparent body > .body {
    margin: 0;
  }
  html.side-header-semi-transparent #header.side-header {
    background: rgba(0, 0, 0, 0.3);
  }
  html.side-header-semi-transparent #header.side-header .header-body {
    background: transparent !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  html:not(.side-header-disable-offcanvas).side-header:not(.side-header-above) body > .body {
    margin: 0;
  }
  html:not(.side-header-disable-offcanvas).side-header #header.side-header {
    left: -255px;
    z-index: 20 !important;
  }
  html:not(.side-header-disable-offcanvas).side-header.side-header-right #header.side-header {
    left: auto;
    right: -255px;
  }
}
@media (min-width: 1200px) {
  html.side-header.side-header-hide #header.side-header {
    left: 0;
    transform: translateX(0);
  }
  html.side-header.side-header-right.side-header-hide #header.side-header {
    left: auto;
    right: 0;
    transform: translateX(0);
  }
}
@media (max-width: 991px) {
  html.side-header #header.side-header .header-container {
    height: auto !important;
  }
}
@media (max-height: 768px) {
  html.side-header #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
    top: auto;
  }
}
html.side-header-hamburguer-sidebar .main .container, html.side-header-hamburguer-sidebar #footer .container {
  padding: 0 35px;
  width: auto !important;
  max-width: 1210px;
}
html.side-header-hamburguer-sidebar:not(.side-header-above) body > .body {
  margin: 0 0 0 255px;
  width: auto;
  overflow-x: hidden;
  overflow-y: visible;
}
html.side-header-hamburguer-sidebar:not(.side-header-above) body > .body .forcefullwidth_wrapper_tp_banner .rev_slider_wrapper {
  width: 100% !important;
  left: auto !important;
}
html.side-header-hamburguer-sidebar.side-header-above #header.side-header {
  transition: cubic-bezier(0.55, 0, 0.1, 1) transform 300ms;
}
html.side-header-hamburguer-sidebar #header.side-header {
  background: #FFF;
  position: fixed;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  top: 0;
  left: 0;
  max-width: 255px;
  width: 100%;
  height: 100%;
  transform: translateX(0);
  z-index: 9991;
}
html.side-header-hamburguer-sidebar #header.side-header.side-header-lg {
  max-width: 355px;
}
html.side-header-hamburguer-sidebar #header.side-header.side-header-hide {
  transform: translateX(-100%);
}
html.side-header-hamburguer-sidebar #header.side-header.header-transparent {
  background: transparent;
}
html.side-header-hamburguer-sidebar #header.side-header .side-header-scrollable {
  height: 100%;
}
html.side-header-hamburguer-sidebar #header.side-header .header-body {
  border-top: 0;
  height: 100% !important;
}
html.side-header-hamburguer-sidebar #header.side-header .header-container {
  width: 100%;
  height: 100% !important;
}
html.side-header-hamburguer-sidebar #header.side-header .header-row {
  flex-direction: column;
}
html.side-header-hamburguer-sidebar #header.side-header .header-search {
  width: 100%;
  margin: 0.7rem 0;
}
html.side-header-hamburguer-sidebar #header.side-header .header-nav-top .nav > li > a, html.side-header-hamburguer-sidebar #header.side-header .header-nav-top .nav > li > span {
  display: block;
}
html.side-header-hamburguer-sidebar #header.side-header .header-logo {
  margin: 2rem 0;
}
html.side-header-hamburguer-sidebar #header.side-header .header-nav {
  flex-direction: column;
}
html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul > li > a {
  height: auto;
}
html.side-header-hamburguer-sidebar #header.side-header .header-nav-main,
html.side-header-hamburguer-sidebar #header.side-header .header-nav-main nav,
html.side-header-hamburguer-sidebar #header.side-header .header-nav-main .nav {
  width: 100%;
}
html.side-header-hamburguer-sidebar #header.side-header .nav {
  flex-direction: column;
  width: 100%;
}
html.side-header-hamburguer-sidebar.side-header-hamburguer-sidebar-right #header.side-header {
  left: auto;
  right: 0;
}
html.side-header-hamburguer-sidebar.side-header-hamburguer-sidebar-right #header.side-header.side-header-hide {
  transform: translateX(100%);
}
html.side-header-hamburguer-sidebar.side-header-hamburguer-sidebar-push body > .body {
  margin: 0;
  overflow: visible;
  transition: ease right 300ms;
  right: 0;
}
html.side-header-hamburguer-sidebar.side-header-hamburguer-sidebar-push .body-overlay {
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 10;
  opacity: 0;
}
html.side-header-hamburguer-sidebar.side-header-hamburguer-sidebar-push #header.side-header {
  transform: none;
  transition: ease left 300ms;
  left: -355px;
}
html.side-header-hamburguer-sidebar.side-header-hamburguer-sidebar-right.side-header-hamburguer-sidebar-push body > .body {
  position: relative;
  transition: ease left 300ms;
  left: 0;
  right: auto;
}
html.side-header-hamburguer-sidebar.side-header-hamburguer-sidebar-right.side-header-hamburguer-sidebar-push #header.side-header {
  transition: ease right 300ms, ease transform 300ms;
  right: -355px;
  left: auto;
}

html.side-header-hamburguer-sidebar:not(.side-header-hide).side-header-hamburguer-sidebar-push .hamburguer-btn:not(.hamburguer-btn-side-header-mobile-show), html.side-header-hamburguer-sidebar:not(.side-header-hide).side-header-hamburguer-sidebar-push .sticky-wrapper {
  display: none;
}
html.side-header-hamburguer-sidebar:not(.side-header-hide).side-header-hamburguer-sidebar-push body > .body {
  position: relative;
  right: -355px;
}
html.side-header-hamburguer-sidebar:not(.side-header-hide).side-header-hamburguer-sidebar-push .body-overlay {
  opacity: 1;
  height: 100%;
}
html.side-header-hamburguer-sidebar:not(.side-header-hide).side-header-hamburguer-sidebar-push #header.side-header {
  z-index: 11;
  left: 0;
}
html.side-header-hamburguer-sidebar:not(.side-header-hide).side-header-hamburguer-sidebar-right.side-header-hamburguer-sidebar-push body > .body {
  left: -355px;
  right: auto;
}
html.side-header-hamburguer-sidebar:not(.side-header-hide).side-header-hamburguer-sidebar-right.side-header-hamburguer-sidebar-push #header.side-header {
  right: 0;
  left: auto;
}

html.side-header-overlay-full-screen body > .body {
  margin: 0;
  width: auto;
  overflow-x: hidden;
  overflow-y: visible;
}
html.side-header-overlay-full-screen body > .body .forcefullwidth_wrapper_tp_banner .rev_slider_wrapper {
  width: 100% !important;
  left: auto !important;
}
html.side-header-overlay-full-screen #header {
  display: flex;
  align-items: center;
  background-color: rgba(51, 51, 51, 0.99);
  position: fixed;
  box-shadow: 0 0 18px rgba(68, 68, 68, 0.07);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 3rem;
  opacity: 0;
  visibility: hidden;
  transition: ease opacity 300ms, ease visibility 300ms;
}
html.side-header-overlay-full-screen #header:not(.side-header-hide) {
  opacity: 1;
  visibility: visible;
  transition: ease opacity 300ms, ease visibility 300ms;
}
html.side-header-overlay-full-screen #header .header-container:after {
  content: none;
}
html.side-header-overlay-full-screen #header .header-body {
  background-color: transparent;
  border: 0;
}
html.side-header-overlay-full-screen #header .header-row-side-header {
  flex: 0 0 50%;
  margin: 0 auto;
}
html.side-header-overlay-full-screen #header .header-nav {
  flex-direction: column;
}
html.side-header-overlay-full-screen #header .header-nav.header-nav-links nav > ul > li > a {
  height: auto;
}
html.side-header-overlay-full-screen #header .header-nav-main,
html.side-header-overlay-full-screen #header .header-nav-main nav,
html.side-header-overlay-full-screen #header .header-nav-main .nav {
  width: 100%;
}
html.side-header-overlay-full-screen #header .nav {
  flex-direction: column;
  width: 100%;
}
html.side-header-overlay-full-screen #header .header-nav-main {
  position: relative;
}
html.side-header-overlay-full-screen #header .header-nav-main:before {
  content: none;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li {
  text-align: center;
  position: static;
  margin-top: 1px;
  height: auto;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li > a {
  display: inline-block;
  border: 0;
  font-size: 1.8rem;
  text-transform: none;
  color: #FFF;
  padding: 15px 0 !important;
  font-weight: 600;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li > a:after {
  top: -2px !important;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li > a.open, html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li > a:focus, html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li > a:active, html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li > a.active {
  background: transparent;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li a .fa-chevron-down {
  display: none !important;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-item {
  transition: ease left 300ms;
  position: relative;
  left: 0;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-item:after {
  display: none;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-item:before {
  display: none !important;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-item:hover {
  left: 5px;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
  position: static;
  background: transparent !important;
  box-shadow: none;
  display: none !important;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
  border: 0;
  font-size: 1.1rem;
  color: #FFF;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:after {
  display: none;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-submenu {
  position: static;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-submenu .dropdown-menu a {
  font-size: 0.75rem;
  opacity: 0.7;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu,
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-submenu.open > .dropdown-menu {
  display: block !important;
  opacity: 1;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  margin: 0;
  transform: none;
  position: static;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu li a,
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-submenu.open > .dropdown-menu li a {
  background: transparent;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content {
  padding: 1.6rem 0 0;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content > .row {
  flex-direction: column;
  align-items: center;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content > .row > [class*=col-] {
  max-width: none;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content > .row > [class*=col-] + [class*=col-] {
  margin-top: 25px;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-title {
  color: #FFF;
  font-size: 1.2em;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-nav {
  opacity: 0.7;
}
html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-nav > li > a {
  margin: 0 0 8px;
  padding: 3px 8px 3px 8px;
}
html.side-header-overlay-full-screen.ie #header .header-nav-main nav > ul > li.dropdown.dropdown-mega .dropdown-mega-content > .row > [class*=col-] {
  flex: 0 0 auto;
}

@media (max-width: 991px) {
  html.side-header-overlay-full-screen #header .header-row-side-header {
    flex: 0 0 100%;
  }
  html.side-header-overlay-full-screen #header .side-header-scrollable {
    height: 100% !important;
  }
  html.side-header-overlay-full-screen #header .side-header-scrollable .scrollable-content {
    right: 0 !important;
    padding-right: 10px;
    padding-left: 10px;
    overflow-y: hidden;
  }
  html.side-header-overlay-full-screen #header .side-header-scrollable .scrollable-pane {
    display: none !important;
  }
  html.side-header-overlay-full-screen #header .header-nav {
    height: 100%;
  }
  html.side-header-overlay-full-screen #header .header-nav-main {
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  html.side-header-overlay-full-screen #header .header-nav-main nav {
    max-height: 80vh;
  }
  html.side-header-overlay-full-screen #header .header-nav-main nav > ul > li.dropdown .dropdown-item:hover {
    left: 0;
  }
  html.side-header-overlay-full-screen #header .header-nav-main nav > ul li {
    border-bottom: 0;
  }
  html.side-header-overlay-full-screen #header .header-nav-main nav > ul li a {
    text-align: center;
  }
  html.side-header-overlay-full-screen #header .header-nav-main nav > ul li.dropdown.dropdown-mega .dropdown-mega-content {
    padding: 0;
  }
  html.side-header-overlay-full-screen #header .header-nav-main nav > ul li.dropdown.dropdown-mega .dropdown-mega-sub-title {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 7px;
  }
  html.side-header-overlay-full-screen #header .header-nav-main nav > ul li.dropdown.dropdown-mega .dropdown-mega-sub-nav {
    margin: 0;
  }
}
html.side-header-hamburguer-sidebar-narrow-bar .body {
  margin-left: 90px;
}
html.side-header-hamburguer-sidebar-narrow-bar #header.side-header {
  transform: translateX(90px);
}
html.side-header-hamburguer-sidebar-narrow-bar .slider-container {
  left: -45px !important;
  width: calc(100% + 90px) !important;
}

.side-header-narrow-bar {
  display: flex;
  flex-direction: column;
  background: #FFF;
  width: 90px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 9992;
}
.side-header-narrow-bar:after {
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  background: rgba(0, 0, 0, 0.06);
  position: absolute;
}
.side-header-narrow-bar .side-header-narrow-bar-content .side-header-narrow-bar-content-vertical {
  transform: rotate(-90deg);
  white-space: nowrap;
  text-transform: uppercase;
  margin-left: -18%;
}
@media (max-width: 991px) {
  html.side-header-hamburguer-sidebar-narrow-bar .body {
    padding-left: 90px;
    margin-left: 0;
  }
  html.side-header-hamburguer-sidebar-narrow-bar #header {
    padding-left: 90px;
    left: -90px !important;
  }
}
body[data-plugin-section-scroll] #header .header-body {
  background: transparent;
}

html[dir=rtl] #header .header-search .btn-light {
  right: -35px;
}

html.ie #header .header-column .header-extra-info .feature-box .feature-box-info {
  flex: none;
}

@media (max-width: 991px) {
  html.safari #header .header-body {
    overflow: visible !important;
  }
}
.header-top {
  display: flex;
  align-items: center;
  min-height: 55px;
  background-color: #FCAC2F;
}
.header-top.header-top-light-border-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}
.header-top.header-top-simple-border-bottom {
  border-bottom-width: 1px;
}
.header-top.header-top-default {
  background: #f7f7f7;
}
.header-top.header-top-borders {
  border-bottom-width: 1px;
}
.header-top.header-top-borders .nav-item-borders {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  padding-left: 12px;
  padding-right: 12px;
}
.header-top.header-top-borders .nav-item-borders:first-child {
  padding-left: 0;
}
.header-top.header-top-borders .nav-item-borders:last-child {
  border-right: 0;
}
.header-top.header-top-light-borders {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.header-top.header-top-light-borders.header-top-borders .nav-item-borders {
  border-right-color: rgba(255, 255, 255, 0.2);
}
.header-top.header-top-light-2-borders {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.header-top.header-top-light-2-borders.header-top-borders .nav-item-borders {
  border-right-color: rgba(255, 255, 255, 0.06);
}

.header-nav-top {
  display: flex;
  align-self: stretch;
  align-items: center;
}
.header-nav-top strong {
  font-size: 1.1em;
  color: #0E2D52;
}
.header-nav-top .nav > li > a, .header-nav-top .nav > li > span {
  color: #0E2D52;
  font-size: 1.1em;
  display: inline-block;
}
@media (min-width: 1200px) and (max-width: 1299px) {
  .header-nav-top .nav > li > a, .header-nav-top .nav > li > span {
    font-size: 0.9em;
  }
}
.header-nav-top .nav > li > a.btn, .header-nav-top .nav > li > span.btn {
  color: #FCAC2F;
}
.header-nav-top .nav > li > a [class*=fa-], .header-nav-top .nav > li > a .icons, .header-nav-top .nav > li > span [class*=fa-], .header-nav-top .nav > li > span .icons {
  margin-right: 3px;
  font-size: 0.85em;
  position: relative;
  text-align: center;
}
.header-nav-top .nav > li > a [class*=fa-].fa-fw, .header-nav-top .nav > li > a .icons.fa-fw, .header-nav-top .nav > li > span [class*=fa-].fa-fw, .header-nav-top .nav > li > span .icons.fa-fw {
  width: 2.1em;
}
.header-nav-top .nav > li > a [class*=fa-].fa-border, .header-nav-top .nav > li > a .icons.fa-border, .header-nav-top .nav > li > span [class*=fa-].fa-border, .header-nav-top .nav > li > span .icons.fa-border {
  border-color: #0E2D52;
  border-radius: 3px;
  padding: 4px;
}
.header-nav-top .nav > li > a {
  text-decoration: none;
}
.header-nav-top .nav > li.open > .dropdown-menu-toggle {
  border-radius: 4px 4px 0 0;
}
.header-nav-top .nav > li + .nav-item-left-border {
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
}
.header-nav-top .nav > li + .nav-item-left-border:after {
  background: rgba(0, 0, 0, 0.08);
  content: "";
  width: 1px;
  height: 16px;
  position: absolute;
  display: block;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  left: 0;
}
.header-nav-top .nav > li.nav-item-left-border.nav-item-left-border-remove:after {
  content: none;
}
@media (max-width: 575px) {
  .header-nav-top .nav > li:not(.d-none) {
    margin-left: 0;
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .header-nav-top .nav > li.nav-item-left-border-remove.d-none {
    margin-left: 0;
    padding-left: 0;
  }
}
.header-nav-top .nav > li.nav-item-left-border.nav-item-left-border-show:after {
  content: "";
}
@media (min-width: 576px) {
  .header-nav-top .nav > li.nav-item-left-border.nav-item-left-border-sm-show:after {
    content: "";
  }
}
@media (min-width: 768px) {
  .header-nav-top .nav > li.nav-item-left-border.nav-item-left-border-md-show:after {
    content: "";
  }
}
@media (min-width: 992px) {
  .header-nav-top .nav > li.nav-item-left-border.nav-item-left-border-lg-show:after {
    content: "";
  }
}
@media (min-width: 1200px) {
  .header-nav-top .nav > li.nav-item-left-border.nav-item-left-border-xl-show:after {
    content: "";
  }
}
@media (min-width: 1400px) {
  .header-nav-top .nav > li.nav-item-left-border.nav-item-left-border-xxl-show:after {
    content: "";
  }
}
.header-nav-top .nav > li.nav-item.dropdown.show .nav-link {
  background: transparent;
}
.header-nav-top .nav > li.nav-item:not(.dropdown) > a [class*=fa-], .header-nav-top .nav > li.nav-item:not(.dropdown) > a .icons {
  left: -1px;
  top: -1px;
}
.header-nav-top .nav > li.nav-item-anim-icon > a:hover [class*=fa-], .header-nav-top .nav > li.nav-item-anim-icon > a:hover .icons {
  animation: navItemArrow 600ms ease;
  animation-iteration-count: infinite;
}
.header-nav-top .dropdown .dropdown-item {
  font-size: 0.75em;
}
.header-nav-top .dropdown-menu {
  border: 0;
  margin-left: -2px;
  box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.05);
  background: #0E2D52;
  padding: 3px;
  min-width: auto;
  z-index: 10000;
  margin-right: 0;
  margin-top: 6px;
  transition: opacity 0.2s ease-out;
  border-radius: 4px;
}
.header-nav-top .dropdown-menu:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 5px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #0E2D52;
  transform: translate3d(-7px, -6px, 0);
}
.header-nav-top .dropdown-menu.dropdow-menu-arrow-right:before {
  right: auto;
  left: 20px;
}
.header-nav-top .dropdown-menu a {
  background-color: transparent;
  color: #FCAC2F;
  padding: 5px 12px;
  font-size: 0.9em;
}
.header-nav-top .dropdown-menu a:hover, .header-nav-top .dropdown-menu a:active {
  background-color: transparent;
}
.header-nav-top .flag {
  margin-right: 3px;
  position: relative;
  top: -3px;
  width: 20px;
  height: 20px;
  object-fit: cover;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  #header .header-nav.header-nav-line nav > ul > li > a {
    font-size: 14px;
  }

  #header .header-body .btn-xl {
    padding: 0.9rem 1rem;
    font-size: 1em;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  #header .header-nav.header-nav-line nav > ul > li > a {
    font-size: 14px;
    padding: 0 0.8rem;
  }
}
/* Page Header */
.page-header {
  background-color: #333;
  margin: 0 0 35px 0;
  padding: 30px 0;
  position: relative;
  text-align: left;
}
.page-header .breadcrumb {
  background: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
}
.page-header .breadcrumb > li {
  display: inline-block;
  font-size: 1.1em;
  text-shadow: none;
  font-weight: 500;
}
.page-header .breadcrumb > li + li:before {
  position: relative;
  top: 1px;
  font-size: 1.2em;
  color: #fff;
  opacity: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /*rtl:ignore*/
  content: "\f101";
  /*rtl:raw:
  content: "\F100";
  */
  padding: 0 12px 0 9px;
}
.page-header .breadcrumb > li .fa-home {
  font-size: 1.2em;
}
.page-header .breadcrumb.breadcrumb-block {
  background: #333;
  display: inline-block !important;
  padding: 0.45rem 1.2rem;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}
.page-header .breadcrumb.breadcrumb-light {
  color: #fff;
}
.page-header .breadcrumb.breadcrumb-light a {
  color: #FCAC2F;
}
.page-header .breadcrumb.breadcrumb-light .active {
  color: #FCAC2F;
}
.page-header h1, .page-header .h1 {
  color: #fff;
  display: inline-block;
  font-size: 2.8em;
  line-height: 1;
  margin: 0;
  padding: 0;
  font-weight: 400;
  position: relative;
  top: 1px;
  text-transform: uppercase;
}
.page-header .sub-title {
  display: block;
  font-size: 1.2em;
  font-weight: 300;
  margin: 0;
  opacity: 0.8;
  color: #fff;
}
.page-header .page-header-extra-button {
  position: absolute;
  width: 100%;
  bottom: 50px;
  left: 0;
  right: 0;
  z-index: 5;
}
.page-header.page-header-sm {
  padding: 30px 0;
}
.page-header.page-header-sm h1, .page-header.page-header-sm .h1 {
  font-size: 22px;
}
.page-header.page-header-lg {
  padding: 50px 0;
}
.page-header.page-header-lg h1, .page-header.page-header-lg .h1 {
  font-size: 40px;
}
.page-header.page-header-classic:after {
  content: "";
  width: 100%;
  height: 5px;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
}
.page-header.page-header-classic .page-header-title-border {
  width: 0;
  height: 5px;
  position: absolute;
  bottom: 0;
  background: transparent;
  z-index: 1;
}
.page-header.page-header-modern.page-header-sm h1, .page-header.page-header-modern.page-header-sm .h1 {
  font-size: 22px;
}
.page-header.page-header-modern.page-header-md {
  padding: 50px 0;
}
.page-header.page-header-modern.page-header-lg {
  padding: 65px 0;
}
.page-header.page-header-modern.page-header-lg h1, .page-header.page-header-modern.page-header-lg .h1 {
  font-size: 32px;
}
.page-header.page-header-modern.page-header-background {
  position: relative;
  padding: 130px 0;
  min-height: 320px;
  background-size: cover;
}
.page-header.page-header-modern.page-header-background.page-header-background-pattern {
  background-size: auto;
  background-repeat: repeat;
  background-attachment: fixed;
}
.page-header.page-header-modern.page-header-background.page-header-background-sm {
  padding: 60px 0;
}
.page-header.page-header-modern.page-header-background.page-header-background-md {
  padding: 150px 0;
}
.page-header.page-header-modern.page-header-background.page-header-background-lg {
  padding: 250px 0;
}

/* Position */
.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.p-fixed {
  position: fixed !important;
}

.p-static {
  position: static !important;
}

/* Box Shadow */
.box-shadow-none {
  box-shadow: none !important;
}

/* Inverted */
.inverted {
  color: #FFF;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}

h1 .inverted, .h1 .inverted {
  padding-left: 10px;
  padding-right: 10px;
}

h2 .inverted, .h2 .inverted {
  padding-left: 7px;
  padding-right: 7px;
}

h3 .inverted, .h3 .inverted {
  padding-left: 2px;
  padding-right: 2px;
}

h4 .inverted, .h4 .inverted {
  padding-left: 4px;
  padding-right: 4px;
}

h5 .inverted, .h5 .inverted {
  padding-left: 2px;
  padding-right: 2px;
}

h6 .inverted, .h6 .inverted {
  padding-left: 2px;
  padding-right: 2px;
}

/* Letter Spacing */
.ls-0 {
  letter-spacing: 0 !important;
}

.negative-ls-05 {
  letter-spacing: -0.05em;
}

.negative-ls-1 {
  letter-spacing: -1px;
}

.negative-ls-2 {
  letter-spacing: -2px;
}

.negative-ls-3 {
  letter-spacing: -2.5px;
}

.positive-ls-1 {
  letter-spacing: 1px;
}

.positive-ls-2 {
  letter-spacing: 2px;
}

.positive-ls-3 {
  letter-spacing: 2.5px;
}

/* Cursor */
.cur-pointer {
  cursor: pointer;
}

/* Pointer Events */
.p-events-none {
  pointer-events: none;
}

/* Font Size */
.text-1 {
  font-size: 0.8em !important;
}

.text-2 {
  font-size: 0.9em !important;
}

.text-3 {
  font-size: 1em !important;
}

.text-4 {
  font-size: 1.2em !important;
}

.text-5 {
  font-size: 1.5em !important;
}

.text-6 {
  font-size: 1.8em !important;
}

.text-7 {
  font-size: 2em !important;
}

.text-8 {
  font-size: 2.3em !important;
}

.text-9 {
  font-size: 2.5em !important;
}

.text-10 {
  font-size: 2.75em !important;
}

.text-11 {
  font-size: 3em !important;
}

.text-12 {
  font-size: 3.5em !important;
}

.text-13 {
  font-size: 4em !important;
}

.text-14 {
  font-size: 4.5em !important;
}

.text-15 {
  font-size: 5em !important;
}

@media (min-width: 576px) {
  .text-sm-1 {
    font-size: 0.8em !important;
  }

  .text-sm-2 {
    font-size: 0.9em !important;
  }

  .text-sm-3 {
    font-size: 1em !important;
  }

  .text-sm-4 {
    font-size: 1.2em !important;
  }

  .text-sm-5 {
    font-size: 1.5em !important;
  }

  .text-sm-6 {
    font-size: 1.8em !important;
  }

  .text-sm-7 {
    font-size: 2em !important;
  }

  .text-sm-8 {
    font-size: 2.3em !important;
  }

  .text-sm-9 {
    font-size: 2.5em !important;
  }

  .text-sm-10 {
    font-size: 2.75em !important;
  }

  .text-sm-11 {
    font-size: 3em !important;
  }

  .text-sm-12 {
    font-size: 3.5em !important;
  }

  .text-sm-13 {
    font-size: 4em !important;
  }

  .text-sm-14 {
    font-size: 4.5em !important;
  }

  .text-sm-15 {
    font-size: 5em !important;
  }
}
@media (min-width: 768px) {
  .text-md-1 {
    font-size: 0.8em !important;
  }

  .text-md-2 {
    font-size: 0.9em !important;
  }

  .text-md-3 {
    font-size: 1em !important;
  }

  .text-md-4 {
    font-size: 1.2em !important;
  }

  .text-md-5 {
    font-size: 1.5em !important;
  }

  .text-md-6 {
    font-size: 1.8em !important;
  }

  .text-md-7 {
    font-size: 2em !important;
  }

  .text-md-8 {
    font-size: 2.3em !important;
  }

  .text-md-9 {
    font-size: 2.5em !important;
  }

  .text-md-10 {
    font-size: 2.75em !important;
  }

  .text-md-11 {
    font-size: 3em !important;
  }

  .text-md-12 {
    font-size: 3.5em !important;
  }

  .text-md-13 {
    font-size: 4em !important;
  }

  .text-md-14 {
    font-size: 4.5em !important;
  }

  .text-md-15 {
    font-size: 5em !important;
  }
}
@media (min-width: 992px) {
  .text-lg-1 {
    font-size: 0.8em !important;
  }

  .text-lg-2 {
    font-size: 0.9em !important;
  }

  .text-lg-3 {
    font-size: 1em !important;
  }

  .text-lg-4 {
    font-size: 1.2em !important;
  }

  .text-lg-5 {
    font-size: 1.5em !important;
  }

  .text-lg-6 {
    font-size: 1.8em !important;
  }

  .text-lg-7 {
    font-size: 2em !important;
  }

  .text-lg-8 {
    font-size: 2.3em !important;
  }

  .text-lg-9 {
    font-size: 2.5em !important;
  }

  .text-lg-10 {
    font-size: 2.75em !important;
  }

  .text-lg-11 {
    font-size: 3em !important;
  }

  .text-lg-12 {
    font-size: 3.5em !important;
  }

  .text-lg-13 {
    font-size: 4em !important;
  }

  .text-lg-14 {
    font-size: 4.5em !important;
  }

  .text-lg-15 {
    font-size: 5em !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-1 {
    font-size: 0.8em !important;
  }

  .text-xl-2 {
    font-size: 0.9em !important;
  }

  .text-xl-3 {
    font-size: 1em !important;
  }

  .text-xl-4 {
    font-size: 1.2em !important;
  }

  .text-xl-5 {
    font-size: 1.5em !important;
  }

  .text-xl-6 {
    font-size: 1.8em !important;
  }

  .text-xl-7 {
    font-size: 2em !important;
  }

  .text-xl-8 {
    font-size: 2.3em !important;
  }

  .text-xl-9 {
    font-size: 2.5em !important;
  }

  .text-xl-10 {
    font-size: 2.75em !important;
  }

  .text-xl-11 {
    font-size: 3em !important;
  }

  .text-xl-12 {
    font-size: 3.5em !important;
  }

  .text-xl-13 {
    font-size: 4em !important;
  }

  .text-xl-14 {
    font-size: 4.5em !important;
  }

  .text-xl-15 {
    font-size: 5em !important;
  }
}
@media (min-width: 1400px) {
  .text-xxl-1 {
    font-size: 0.8em !important;
  }

  .text-xxl-2 {
    font-size: 0.9em !important;
  }

  .text-xxl-3 {
    font-size: 1em !important;
  }

  .text-xxl-4 {
    font-size: 1.2em !important;
  }

  .text-xxl-5 {
    font-size: 1.5em !important;
  }

  .text-xxl-6 {
    font-size: 1.8em !important;
  }

  .text-xxl-7 {
    font-size: 2em !important;
  }

  .text-xxl-8 {
    font-size: 2.3em !important;
  }

  .text-xxl-9 {
    font-size: 2.5em !important;
  }

  .text-xxl-10 {
    font-size: 2.75em !important;
  }

  .text-xxl-11 {
    font-size: 3em !important;
  }

  .text-xxl-12 {
    font-size: 3.5em !important;
  }

  .text-xxl-13 {
    font-size: 4em !important;
  }

  .text-xxl-14 {
    font-size: 4.5em !important;
  }

  .text-xxl-15 {
    font-size: 5em !important;
  }
}
.text-1rem {
  font-size: 1rem !important;
}

/* Line Height */
.line-height-initial {
  line-height: initial !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.2 !important;
}

.line-height-3 {
  line-height: 1.3 !important;
}

.line-height-4 {
  line-height: 1.4 !important;
}

.line-height-5 {
  line-height: 1.5 !important;
}

.line-height-6 {
  line-height: 1.6 !important;
}

.line-height-7 {
  line-height: 1.7 !important;
}

.line-height-8 {
  line-height: 1.8 !important;
}

.line-height-9 {
  line-height: 1.9 !important;
}

@media (min-width: 576px) {
  .line-height-sm-initial {
    line-height: initial !important;
  }

  .line-height-sm-1 {
    line-height: 1 !important;
  }

  .line-height-sm-2 {
    line-height: 1.2 !important;
  }

  .line-height-sm-3 {
    line-height: 1.3 !important;
  }

  .line-height-sm-4 {
    line-height: 1.4 !important;
  }

  .line-height-sm-5 {
    line-height: 1.5 !important;
  }

  .line-height-sm-6 {
    line-height: 1.6 !important;
  }

  .line-height-sm-7 {
    line-height: 1.7 !important;
  }

  .line-height-sm-8 {
    line-height: 1.8 !important;
  }

  .line-height-sm-9 {
    line-height: 1.9 !important;
  }
}
@media (min-width: 768px) {
  .line-height-md-initial {
    line-height: initial !important;
  }

  .line-height-md-1 {
    line-height: 1 !important;
  }

  .line-height-md-2 {
    line-height: 1.2 !important;
  }

  .line-height-md-3 {
    line-height: 1.3 !important;
  }

  .line-height-md-4 {
    line-height: 1.4 !important;
  }

  .line-height-md-5 {
    line-height: 1.5 !important;
  }

  .line-height-md-6 {
    line-height: 1.6 !important;
  }

  .line-height-md-7 {
    line-height: 1.7 !important;
  }

  .line-height-md-8 {
    line-height: 1.8 !important;
  }

  .line-height-md-9 {
    line-height: 1.9 !important;
  }
}
@media (min-width: 992px) {
  .line-height-lg-initial {
    line-height: initial !important;
  }

  .line-height-lg-1 {
    line-height: 1 !important;
  }

  .line-height-lg-2 {
    line-height: 1.2 !important;
  }

  .line-height-lg-3 {
    line-height: 1.3 !important;
  }

  .line-height-lg-4 {
    line-height: 1.4 !important;
  }

  .line-height-lg-5 {
    line-height: 1.5 !important;
  }

  .line-height-lg-6 {
    line-height: 1.6 !important;
  }

  .line-height-lg-7 {
    line-height: 1.7 !important;
  }

  .line-height-lg-8 {
    line-height: 1.8 !important;
  }

  .line-height-lg-9 {
    line-height: 1.9 !important;
  }
}
@media (min-width: 1200px) {
  .line-height-xl-initial {
    line-height: initial !important;
  }

  .line-height-xl-1 {
    line-height: 1 !important;
  }

  .line-height-xl-2 {
    line-height: 1.2 !important;
  }

  .line-height-xl-3 {
    line-height: 1.3 !important;
  }

  .line-height-xl-4 {
    line-height: 1.4 !important;
  }

  .line-height-xl-5 {
    line-height: 1.5 !important;
  }

  .line-height-xl-6 {
    line-height: 1.6 !important;
  }

  .line-height-xl-7 {
    line-height: 1.7 !important;
  }

  .line-height-xl-8 {
    line-height: 1.8 !important;
  }

  .line-height-xl-9 {
    line-height: 1.9 !important;
  }
}
@media (min-width: 1400px) {
  .line-height-xxl-initial {
    line-height: initial !important;
  }

  .line-height-xxl-1 {
    line-height: 1 !important;
  }

  .line-height-xxl-2 {
    line-height: 1.2 !important;
  }

  .line-height-xxl-3 {
    line-height: 1.3 !important;
  }

  .line-height-xxl-4 {
    line-height: 1.4 !important;
  }

  .line-height-xxl-5 {
    line-height: 1.5 !important;
  }

  .line-height-xxl-6 {
    line-height: 1.6 !important;
  }

  .line-height-xxl-7 {
    line-height: 1.7 !important;
  }

  .line-height-xxl-8 {
    line-height: 1.8 !important;
  }

  .line-height-xxl-9 {
    line-height: 1.9 !important;
  }
}
/* Opacity */
.opacity-0 {
  opacity: 0.0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}

/* Scale */
.scale-1 {
  transform: scale(1.1) !important;
}

.scale-2 {
  transform: scale(1.2) !important;
}

.scale-3 {
  transform: scale(1.3) !important;
}

.scale-4 {
  transform: scale(1.4) !important;
}

.scale-5 {
  transform: scale(1.5) !important;
}

.scale-6 {
  transform: scale(1.6) !important;
}

/* Top / Bottom / Left / Right */
.top-auto {
  top: auto !important;
}

.bottom-auto {
  top: auto !important;
}

.top-0 {
  top: 0px !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.left-0 {
  left: 0px !important;
}

.right-0 {
  right: 0px !important;
}

.top-1 {
  top: 1px !important;
}

.bottom-1 {
  bottom: 1px !important;
}

.left-1 {
  left: 1px !important;
}

.right-1 {
  right: 1px !important;
}

.top-2 {
  top: 2px !important;
}

.bottom-2 {
  bottom: 2px !important;
}

.left-2 {
  left: 2px !important;
}

.right-2 {
  right: 2px !important;
}

.top-3 {
  top: 3px !important;
}

.bottom-3 {
  bottom: 3px !important;
}

.left-3 {
  left: 3px !important;
}

.right-3 {
  right: 3px !important;
}

.top-4 {
  top: 4px !important;
}

.bottom-4 {
  bottom: 4px !important;
}

.left-4 {
  left: 4px !important;
}

.right-4 {
  right: 4px !important;
}

.top-5 {
  top: 5px !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.left-5 {
  left: 5px !important;
}

.right-5 {
  right: 5px !important;
}

.top-6 {
  top: 6px !important;
}

.bottom-6 {
  bottom: 6px !important;
}

.left-6 {
  left: 6px !important;
}

.right-6 {
  right: 6px !important;
}

.top-7 {
  top: 7px !important;
}

.bottom-7 {
  bottom: 7px !important;
}

.left-7 {
  left: 7px !important;
}

.right-7 {
  right: 7px !important;
}

.top-8 {
  top: 8px !important;
}

.bottom-8 {
  bottom: 8px !important;
}

.left-8 {
  left: 8px !important;
}

.right-8 {
  right: 8px !important;
}

.top-9 {
  top: 9px !important;
}

.bottom-9 {
  bottom: 9px !important;
}

.left-9 {
  left: 9px !important;
}

.right-9 {
  right: 9px !important;
}

.top-10 {
  top: 10px !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.left-10 {
  left: 10px !important;
}

.right-10 {
  right: 10px !important;
}

/* Outline */
.outline-none {
  outline: 0 !important;
}

/* Text Decoration */
.text-decoration-none {
  text-decoration: none !important;
}

/* Text Transform */
.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-transform-none {
  text-transform: none !important;
}

/* States */
.text-muted {
  color: #999 !important;
}

html.dark .text-muted {
  color: #505461 !important;
}

/* Overflow */
.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

/* Z-Index */
.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

@media (max-width: 991px) {
  .z-index-mobile-0 {
    z-index: 0 !important;
  }
}
/* Colors */
.text-dark {
  color: #333 !important;
}

.text-light {
  color: #FFF !important;
}

/* Weights */
.font-weight-thin {
  font-weight: 100 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold, b, strong {
  font-weight: 700 !important;
}

.font-weight-extra-bold, .font-weight-black {
  font-weight: 900 !important;
}

/* Letter Space */
.letter-spacing-minus-1 {
  letter-spacing: -1px;
}

/* Borders */
.no-borders {
  border: none !important;
}

.rounded {
  border-radius: 5px !important;
}

.b-thin {
  border-width: 3px !important;
}

.b-normal {
  border-width: 5px !important;
}

.b-thick {
  border-width: 7px !important;
}

/* General Helpers */
.ws-nowrap {
  white-space: nowrap !important;
}

.ws-normal {
  white-space: normal !important;
}

/* Width */
@media (max-width: 991px) {
  .w-auto-mobile {
    width: auto !important;
  }

  .w-100-mobile {
    width: 100% !important;
  }
}
.col-1-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.col-2-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}

.col-3-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}

.col-4-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  max-width: 80%;
}

@media (min-width: 576px) {
  .col-sm-1-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-sm-2-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-sm-3-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-sm-4-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
}
@media (min-width: 768px) {
  .col-md-1-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-md-2-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-md-3-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-md-4-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
}
@media (min-width: 992px) {
  .col-lg-1-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-lg-2-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-lg-3-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-lg-4-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
}
@media (min-width: 1200px) {
  .col-xl-1-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-xl-2-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-xl-3-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-xl-4-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
}
/* RTL */
/* #Footer */
#footer {
  font-size: 1em;
  padding: 0;
  position: relative;
  clear: both;
  background-color: #fff;
  color: #a9aaab;
  line-height: 21px;
  text-align: left;
}
#footer .footer-ribbon {
  background: #999;
  position: absolute;
  margin: -44px 0 0 0;
  padding: 10px 20px 6px 20px;
}
#footer .footer-ribbon:before {
  border-right: 10px solid #646464;
  border-top: 16px solid transparent;
  content: "";
  display: block;
  height: 0;
  right: 100%;
  position: absolute;
  top: 0;
  width: 7px;
}
#footer .footer-ribbon span {
  color: #333;
  font-size: 1.6em;
  font-family: "Shadows Into Light", cursive;
}
#footer p {
  color: #333;
}
#footer h1, #footer .h1, #footer h2, #footer .h2, #footer h3, #footer .h3, #footer h4, #footer .h4, #footer h5, #footer .h5, #footer h6, #footer .h6 {
  color: #333;
}
#footer a:not(.btn) {
  color: #333;
  transition: all 0.1s ease-in-out;
}
#footer a:not(.btn):hover {
  text-decoration: none;
  color: #000;
}
#footer a:not(.btn):focus, #footer a:not(.btn):active {
  color: #000;
}
#footer a:not(.btn).text-color-light {
  color: #FFF !important;
}
#footer a:not(.btn).text-color-light:hover {
  color: #e6e6e6 !important;
}
#footer a:not(.btn).text-color-light:focus, #footer a:not(.btn).text-color-light:active {
  color: #cccccc !important;
}
#footer a:not(.btn).text-color-default {
  color: #333 !important;
}
#footer a:not(.btn).text-color-default:hover {
  color: #4d4d4d !important;
}
#footer a:not(.btn).text-color-default:focus, #footer a:not(.btn).text-color-default:active {
  color: #1a1a1a !important;
}
#footer a:not(.btn).link-hover-style-1 {
  position: relative;
  left: 0px;
  transition: all 0.1s ease-in-out;
}
#footer a:not(.btn).link-hover-style-1:hover {
  left: 3px;
}
#footer ul.nav-list > li a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  line-height: 20px;
  padding-bottom: 11px;
}
#footer ul.nav-list > li:last-child a {
  border-bottom: 0;
}
#footer ul.nav-list > li a:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
#footer ul.list.icons li {
  margin-bottom: 5px;
}
#footer .footer-nav nav {
  display: flex;
}
#footer .footer-nav nav > ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
#footer .footer-nav nav > ul > li {
  position: relative;
  height: 100%;
  align-self: stretch;
}
#footer .footer-nav nav > ul > li > a {
  position: relative;
  background: transparent;
  padding: 0 0.9rem;
  margin: 0;
  height: 100%;
  display: inline-flex;
  align-items: center;
  white-space: normal;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  color: #FFF;
  text-decoration: none;
}
#footer .footer-nav.footer-nav-links nav > ul > li:first-child > a {
  padding-left: 0;
}
#footer .footer-nav.footer-nav-links nav > ul > li:first-child > a:before {
  left: 0;
}
@media (min-width: 768px) {
  #footer .footer-nav.footer-nav-bottom-line nav > ul > li > a:before {
    content: "";
    position: absolute;
    width: auto;
    height: 3px;
    top: 50%;
    left: 0.85rem;
    right: 0.85rem;
    margin-top: 15px;
    transform: translate3d(0, -50%, 0);
  }
}
@media (max-width: 991px) {
  #footer .footer-nav {
    width: 100%;
  }
  #footer .footer-nav nav {
    text-align: center;
  }
  #footer .footer-nav nav > ul {
    width: 100%;
  }
  #footer .footer-nav nav > ul > li > a {
    text-align: center;
    padding: 5px 12px;
  }
}
@media (max-width: 767px) {
  #footer .footer-nav {
    width: 100%;
  }
  #footer .footer-nav nav {
    text-align: center;
  }
  #footer .footer-nav nav > ul {
    width: 100%;
  }
  #footer .footer-nav nav > ul > li {
    height: auto;
    width: 100%;
  }
  #footer .footer-nav nav > ul > li > a {
    text-align: center;
    padding: 1em 0;
  }
}
#footer form {
  opacity: 0.85;
}
#footer form label.error {
  line-height: 16px;
  margin: 5px 0 -5px;
  display: block;
  clear: both;
}
#footer form .alert {
  padding: 6px;
  text-align: center;
}
#footer .logo img {
  position: relative;
  top: 2px;
}
#footer .twitter [class*=fa-], #footer .twitter .icons {
  clear: both;
  font-size: 1.5em;
  position: relative;
  top: 3px;
  margin-right: -22px;
  left: -30px;
  color: #FFF;
}
#footer .twitter .meta {
  color: #999;
  display: block;
  font-size: 0.9em;
  padding-top: 3px;
  opacity: 0.5;
}
#footer .twitter ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#footer .twitter ul li {
  padding-bottom: 20px;
  padding-left: 30px;
}
#footer .twitter ul li:last-child {
  padding-bottom: 0;
}
#footer .twitter.twitter-dark [class*=fa-], #footer .twitter.twitter-dark .icons {
  color: #333;
}
#footer .twitter.twitter-dark .meta a {
  color: #333;
}
#footer .twitter.twitter-light {
  color: #FFF;
}
#footer .twitter.twitter-light [class*=fa-], #footer .twitter.twitter-light .icons {
  color: #FFF;
}
#footer .twitter.twitter-light .meta a {
  color: #FFF;
  opacity: 0.7;
}
#footer .twitter-account {
  color: #FFF;
  display: block;
  font-size: 0.9em;
  margin: -15px 0 5px;
  opacity: 0.55;
}
#footer .twitter-account:hover {
  opacity: 1;
}
#footer .footer-bg-color-2 {
  background: #2e2e2e;
}
#footer .footer-contact {
  color: #333;
}
#footer .footer-social-icons li a {
  font-size: 1.2em;
  background-color: #333;
  border-radius: 50%;
}
#footer .footer-social-icons li a i {
  color: #fff;
}
#footer .footer-copyright {
  background: #0E2D52;
}
#footer .footer-copyright nav {
  text-transform: none;
}
#footer .footer-copyright nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#footer .footer-copyright nav ul li {
  display: inline-block;
  line-height: 12px;
  margin: 0;
  padding: 0 8px;
}
#footer .footer-copyright nav ul li:first-child {
  border: medium none;
  padding-left: 0;
}
#footer .footer-copyright nav ul li:last-child {
  padding-right: 0;
}
#footer .footer-copyright p {
  font-size: 1.2em;
  color: #fff;
  width: 100%;
  margin: 0;
  padding: 0;
}
#footer .footer-copyright a {
  font-weight: bold;
  text-decoration: underline;
}
#footer .footer-copyright a:hover {
  color: #133e70;
  text-decoration: underline;
}
#footer .footer-copyright.footer-copyright-border-grey {
  border-top: 1px solid #E5E5E5;
}
#footer .footer-copyright.footer-copyright-full-width-border-top {
  position: relative;
  border-top: 0;
}
#footer .footer-copyright.footer-copyright-full-width-border-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  transform: translate3d(-50%, 0, 0);
}
#footer.footer-reveal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -10;
}

.footer-top-border {
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.footer-bottom-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.footer-left-border {
  border-left: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.footer-right-border {
  border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.footer-top-light-border {
  border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
}

.footer-bottom-light-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
}

.footer-left-light-border {
  border-left: 1px solid rgba(255, 255, 255, 0.05) !important;
}

.footer-right-light-border {
  border-right: 1px solid rgba(255, 255, 255, 0.05) !important;
}

.map-above {
  min-height: 630px;
  position: relative;
}
.map-above .map-above-map {
  position: absolute;
  top: 0;
  min-height: 630px;
  height: 100%;
  width: 100%;
}
@media (max-width: 767px) {
  .map-above .map-above-map {
    position: relative;
  }
}
.map-above .map-above-content {
  background: transparent;
}

/*
Name: 			theme-elements.css
Written by: 	Okler Themes - (http://www.okler.net)
Theme Version:	@@version
*/
@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"), url(https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@400;500;600&family=Palanquin:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700) format("woff2");
}
/* Accordion */
.accordion {
  /* Modern */
}
.accordion .card {
  margin-top: 5px;
}
.accordion .card:first-child {
  margin-top: 0;
}
.accordion .card-title {
  font-size: 16px;
}
.accordion .card-header {
  padding: 0;
  border-radius: 0.25rem;
  border-bottom: 0;
  margin: -1px;
}
.accordion .card-header a {
  display: block;
  padding: 20px 0;
}
.accordion .card-header a:hover, .accordion .card-header a:focus {
  text-decoration: none;
}
.accordion .card-header a [class^=icon-] {
  margin-right: 4px;
  position: relative;
  top: 1px;
}
.accordion .card-header a [class*=fa-], .accordion .card-header a .icons {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: -1px;
}
.accordion .card-header a .icons {
  top: 1px;
}
.accordion.accordion-sm .card-header a {
  padding: 5px 15px;
  font-size: 0.9em;
}
.accordion.accordion-sm .card-body {
  font-size: 0.9em;
}
.accordion.accordion-lg .card-header a {
  padding: 15px 15px;
  font-size: 1em;
  font-weight: 600;
}
.accordion.without-bg .card-default {
  background: transparent !important;
}
.accordion.without-bg .card-default > .card-header {
  background: transparent !important;
}
.accordion.without-bg .card-body {
  padding-top: 0;
  padding-left: 0;
}
.accordion.without-borders .card {
  border: 0 !important;
  box-shadow: none;
  border-radius: 0;
}
.accordion.without-borders .card-header a {
  padding-left: 0;
  padding-right: 0;
}
.accordion.without-borders .card-body {
  padding: 0;
}
.accordion.accordion-modern .card:first-of-type, .accordion.accordion-modern .card:not(:first-of-type):not(:last-of-type), .accordion.accordion-modern .card:last-of-type {
  border: 4px solid #F7F7F7;
}
.accordion.accordion-modern .card .card-header {
  background-color: #F7F7F7;
  border-radius: 0;
}
.accordion.accordion-modern-grey-scale-1 .card:first-of-type, .accordion.accordion-modern-grey-scale-1 .card:not(:first-of-type):not(:last-of-type), .accordion.accordion-modern-grey-scale-1 .card:last-of-type {
  border-color: #EDEDED;
}
.accordion.accordion-modern-grey-scale-1 .card .card-header {
  background-color: #EDEDED;
}

/* Labels */
.badge-primary {
  background-color: #0E2D52;
  color: #FFF;
}

.badge-success {
  background-color: #28a745;
  color: #FFF;
}

.badge-warning {
  background-color: #ffc107;
  color: #FFF;
}

.badge-danger {
  background-color: #dc3545;
  color: #FFF;
}

.badge-info {
  background-color: #17a2b8;
  color: #FFF;
}

.badge-dark {
  background-color: #333;
  color: #FFF;
}

.badge {
  border-radius: 0;
}
.badge.badge-primary {
  background-color: #0E2D52;
  color: #fff;
}
.badge.badge-secondary {
  background-color: #FCAC2F;
  color: #fff;
}
.badge.badge-xs {
  padding: 0.3em 0.5em;
}
.badge.badge-sm {
  padding: 0.5em 0.8em;
}
.badge.badge-md {
  padding: 1em 1.6em;
  font-size: 85%;
}
.badge.badge-lg {
  padding: 1.5em 2em;
  font-size: 90%;
}
.badge.badge-xl {
  padding: 2em 2.5em;
  font-size: 100%;
}

/* Buttons */
.btn {
  font-size: 0.8rem;
  padding: 0.533rem 0.933rem;
  cursor: pointer;
}
.btn.btn-xs {
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
}
.btn.btn-sm, .btn-group-sm > .btn {
  font-size: 0.8rem;
  padding: 0.3rem 0.65rem;
}
.btn.btn-lg, .btn-group-lg > .btn {
  font-size: 1.2rem;
  padding: 0.7rem 1rem;
}
.btn.btn-xl {
  font-size: 1.4rem;
  padding: 1.2rem 1.8rem;
}
.btn.btn-px-1 {
  padding-left: 0.35rem;
  padding-right: 0.35rem;
}
.btn.btn-px-2 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.btn.btn-px-3 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.btn.btn-px-4 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.btn.btn-px-5 {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.btn.btn-py-1 {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}
.btn.btn-py-2 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.btn.btn-py-3 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.btn.btn-py-4 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.btn.btn-py-5 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.btn.btn-primary .arrow2:before {
  background-color: #FCAC2F;
}
.btn.btn-primary .arrow2:after {
  border-bottom-color: #FCAC2F;
}
.btn.btn-secondary {
  position: relative;
  color: #0E2D52;
  z-index: 1;
  border: none;
}
.btn.btn-outline-secondary {
  position: relative;
  border-width: 2px;
  z-index: 1;
}
.btn.btn-outline-secondary:before {
  border-radius: 0;
}
.btn.btn-outline-secondary:hover {
  border-color: #0E2D52;
}
.btn.btn-outline-secondary .arrow2:before {
  background-color: #FCAC2F;
}
.btn.btn-outline-secondary .arrow2:after {
  border-bottom-color: #FCAC2F;
}

html body .btn.focus,
html body .btn:focus,
html body .btn.active,
html body .btn:active, html body.body .btn.focus,
html body.body .btn:focus,
html body.body .btn.active,
html body.body .btn:active {
  box-shadow: none !important;
}

/* Dropdown Buttons */
.btn + .dropdown-menu .dropdown-item {
  font-size: 0.8rem;
}

/* Default */
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  box-shadow: none !important;
}
.btn-default:hover, .btn-default:active, .btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

/* Outline */
.btn-outline-primary:hover .arrow2:before {
  background-color: #fff;
}
.btn-outline-primary:hover .arrow2:after {
  border-bottom-color: #fff;
}

.btn:hover.btn-outline-primary, .btn:hover.btn-outline-danger, .btn:hover.btn-outline-warning {
  color: #fff !important;
}

.btn-outline-danger:hover {
  color: #fff !important;
}

.btn-outline {
  border-width: 2px;
  padding: 0.483rem 0.933rem;
}
.btn-outline.btn-xs {
  padding: 0.15rem 0.5rem;
}
.btn-outline.btn-sm, .btn-group-sm > .btn-outline.btn {
  padding: 0.25rem 0.65rem;
}
.btn-outline.btn-lg, .btn-group-lg > .btn-outline.btn {
  padding: 0.45rem 1rem;
}
.btn-outline.btn-xl {
  padding: 1.2rem !important;
}

/* Modern */
.btn-modern {
  text-transform: uppercase;
  font-size: 0.8em;
  padding: 0.8rem 1.5rem;
  font-weight: 600;
}

/* Rounded */
.btn-rounded {
  border-radius: 35px;
}

/* Rounded */
.btn-rounded-icon {
  border-radius: 50px;
  padding: 0;
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.btn-rounded-icon[data-hash] > i {
  pointer-events: none;
}

/* 3d */
.btn-3d {
  border-bottom-width: 3px;
}

/* Arrow */
.btn-with-arrow {
  background-color: transparent;
  font-weight: 600;
}
.btn-with-arrow span {
  background-color: #ccc;
  display: inline-block;
  height: 25px;
  line-height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-left: 10px;
  transition: all 0.1s linear;
}
.btn-with-arrow i {
  color: #FFF;
  font-size: 8px;
  top: -1px;
  position: relative;
}
.btn-with-arrow:hover span {
  transform: translateX(5px);
}
.btn-with-arrow.btn-lg span, .btn-group-lg > .btn-with-arrow.btn span, .btn-with-arrow.btn-xl span {
  line-height: 23px;
}
.btn-with-arrow.btn-xs span {
  line-height: 25px;
}

/* Icons  */
.btn-icon i {
  margin-right: 10px;
}

.btn-icon-right i {
  margin-right: 0;
  margin-left: 10px;
}

/* Dropdown Button */
.btn.dropdown-toggle:after {
  margin-left: 0.155em;
  vertical-align: 0.155em;
}

/* Colors */
.btn-primary {
  background-color: #0E2D52;
  border-color: #0E2D52 #0E2D52 #071526;
  color: #FFF;
}
.btn-primary:hover, .btn-primary.hover {
  background-color: #081b31;
  border-color: #071526 #071526 #0E2D52;
  color: #FFF;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 3px rgba(14, 45, 82, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #0E2D52;
  border-color: #0E2D52 #0E2D52 #071526;
}
.btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  background-color: #081b31;
  background-image: none;
  border-color: #071526 #071526 black;
}

.btn-outline.btn-primary {
  color: #0E2D52;
  background-color: transparent;
  background-image: none;
  border-color: #0E2D52;
}
.btn-outline.btn-primary:hover, .btn-outline.btn-primary.hover {
  color: #FFF;
  background-color: #0E2D52;
  border-color: #0E2D52;
}
.btn-outline.btn-primary:focus, .btn-outline.btn-primary.focus {
  box-shadow: 0 0 0 3px rgba(14, 45, 82, 0.5);
}
.btn-outline.btn-primary.disabled, .btn-outline.btn-primary:disabled {
  color: #0E2D52;
  background-color: transparent;
}
.btn-outline.btn-primary:active, .btn-outline.btn-primary.active, .show > .btn-outline.btn-primary.dropdown-toggle {
  color: #FFF;
  background-color: #0E2D52;
  border-color: #0E2D52;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745 #28a745 #1e7e34;
  color: #FFF;
}
.btn-success:hover, .btn-success.hover {
  background-color: #218838;
  border-color: #1e7e34 #1e7e34 #28a745;
  color: #FFF;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  background-color: #28a745;
  border-color: #28a745 #28a745 #1e7e34;
}
.btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  background-color: #218838;
  background-image: none;
  border-color: #1e7e34 #1e7e34 #145523;
}

.btn-outline.btn-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}
.btn-outline.btn-success:hover, .btn-outline.btn-success.hover {
  color: #FFF;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline.btn-success:focus, .btn-outline.btn-success.focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
}
.btn-outline.btn-success.disabled, .btn-outline.btn-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline.btn-success:active, .btn-outline.btn-success.active, .show > .btn-outline.btn-success.dropdown-toggle {
  color: #FFF;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-warning {
  background-color: #ffc107;
  border-color: #ffc107 #ffc107 #d39e00;
  color: #FFF;
}
.btn-warning:hover, .btn-warning.hover {
  background-color: #e0a800;
  border-color: #d39e00 #d39e00 #ffc107;
  color: #FFF;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  background-color: #ffc107;
  border-color: #ffc107 #ffc107 #d39e00;
}
.btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  background-color: #e0a800;
  background-image: none;
  border-color: #d39e00 #d39e00 #a07800;
}

.btn-outline.btn-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}
.btn-outline.btn-warning:hover, .btn-outline.btn-warning.hover {
  color: #FFF;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline.btn-warning:focus, .btn-outline.btn-warning.focus {
  box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5);
}
.btn-outline.btn-warning.disabled, .btn-outline.btn-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline.btn-warning:active, .btn-outline.btn-warning.active, .show > .btn-outline.btn-warning.dropdown-toggle {
  color: #FFF;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545 #dc3545 #bd2130;
  color: #FFF;
}
.btn-danger:hover, .btn-danger.hover {
  background-color: #c82333;
  border-color: #bd2130 #bd2130 #dc3545;
  color: #FFF;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545 #dc3545 #bd2130;
}
.btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  background-color: #c82333;
  background-image: none;
  border-color: #bd2130 #bd2130 #921925;
}

.btn-outline.btn-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}
.btn-outline.btn-danger:hover, .btn-outline.btn-danger.hover {
  color: #FFF;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline.btn-danger:focus, .btn-outline.btn-danger.focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}
.btn-outline.btn-danger.disabled, .btn-outline.btn-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline.btn-danger:active, .btn-outline.btn-danger.active, .show > .btn-outline.btn-danger.dropdown-toggle {
  color: #FFF;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-info {
  background-color: #17a2b8;
  border-color: #17a2b8 #17a2b8 #117a8b;
  color: #FFF;
}
.btn-info:hover, .btn-info.hover {
  background-color: #138496;
  border-color: #117a8b #117a8b #17a2b8;
  color: #FFF;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8 #17a2b8 #117a8b;
}
.btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  background-color: #138496;
  background-image: none;
  border-color: #117a8b #117a8b #0c525d;
}

.btn-outline.btn-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}
.btn-outline.btn-info:hover, .btn-outline.btn-info.hover {
  color: #FFF;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline.btn-info:focus, .btn-outline.btn-info.focus {
  box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
}
.btn-outline.btn-info.disabled, .btn-outline.btn-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline.btn-info:active, .btn-outline.btn-info.active, .show > .btn-outline.btn-info.dropdown-toggle {
  color: #FFF;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-dark {
  background-color: #333;
  border-color: #333 #333 #1a1a1a;
  color: #FFF;
}
.btn-dark:hover, .btn-dark.hover {
  background-color: #202020;
  border-color: #1a1a1a #1a1a1a #333;
  color: #FFF;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 3px rgba(51, 51, 51, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  background-color: #333;
  border-color: #333 #333 #1a1a1a;
}
.btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  background-color: #202020;
  background-image: none;
  border-color: #1a1a1a #1a1a1a black;
}

.btn-outline.btn-dark {
  color: #333;
  background-color: transparent;
  background-image: none;
  border-color: #333;
}
.btn-outline.btn-dark:hover, .btn-outline.btn-dark.hover {
  color: #FFF;
  background-color: #333;
  border-color: #333;
}
.btn-outline.btn-dark:focus, .btn-outline.btn-dark.focus {
  box-shadow: 0 0 0 3px rgba(51, 51, 51, 0.5);
}
.btn-outline.btn-dark.disabled, .btn-outline.btn-dark:disabled {
  color: #333;
  background-color: transparent;
}
.btn-outline.btn-dark:active, .btn-outline.btn-dark.active, .show > .btn-outline.btn-dark.dropdown-toggle {
  color: #FFF;
  background-color: #333;
  border-color: #333;
}

/* Button Light */
html body .btn-light,
html body .btn-outline.btn-light {
  color: #0E2D52;
  background-color: #FFF;
}
html body .btn-light:hover, html body .btn-light.hover,
html body .btn-outline.btn-light:hover,
html body .btn-outline.btn-light.hover {
  color: #0a213c;
  background-color: #FFF;
}
html body .btn-light:focus, html body .btn-light.focus,
html body .btn-outline.btn-light:focus,
html body .btn-outline.btn-light.focus {
  box-shadow: 0 0 0 3px rgba(191, 191, 191, 0.5);
}
html body .btn-light.disabled, html body .btn-light:disabled,
html body .btn-outline.btn-light.disabled,
html body .btn-outline.btn-light:disabled {
  border-color: #bfbfbf #bfbfbf #bfbfbf;
}
html body .btn-light:active, html body .btn-light.active, .show > html body .btn-light.dropdown-toggle,
html body .btn-outline.btn-light:active,
html body .btn-outline.btn-light.active,
.show > html body .btn-outline.btn-light.dropdown-toggle {
  border-color: #bfbfbf #bfbfbf #bfbfbf;
}
html body .btn-light-2,
html body .btn-outline.btn-light-2 {
  border-color: #FFF;
  color: #FFF;
}
html body .btn-light-2:hover, html body .btn-light-2.hover,
html body .btn-outline.btn-light-2:hover,
html body .btn-outline.btn-light-2.hover {
  background-color: #FFF;
  border-color: #FFF;
  color: #0e0e0e;
}
html body .btn-light-2:focus, html body .btn-light-2.focus,
html body .btn-outline.btn-light-2:focus,
html body .btn-outline.btn-light-2.focus {
  box-shadow: 0 0 0 3px rgba(179, 179, 179, 0.5);
}
html body .btn-light-2.disabled, html body .btn-light-2:disabled,
html body .btn-outline.btn-light-2.disabled,
html body .btn-outline.btn-light-2:disabled {
  border-color: #b3b3b3 #b3b3b3 #b3b3b3;
}
html body .btn-light-2:active, html body .btn-light-2.active, .show > html body .btn-light-2.dropdown-toggle,
html body .btn-outline.btn-light-2:active,
html body .btn-outline.btn-light-2.active,
.show > html body .btn-outline.btn-light-2.dropdown-toggle {
  border-color: #FFF;
}

html[dir=rtl] .input-group .form-control:not(:first-child),
html[dir=rtl] .input-group-addon:not(:first-child),
html[dir=rtl] .input-group-btn:not(:first-child) > .btn,
html[dir=rtl] .input-group-btn:not(:first-child) > .btn-group > .btn,
html[dir=rtl] .input-group-btn:not(:first-child) > .dropdown-toggle,
html[dir=rtl] .input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn,
html[dir=rtl] .input-group-btn:not(:last-child) > .btn:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

/* Hamburguer */
.hamburguer-btn {
  background: transparent;
  outline: 0 !important;
  border: none;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 15px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.hamburguer-btn.hamburguer-btn-side-header {
  position: absolute;
  top: 15px;
  right: 8px;
}
.hamburguer-btn.hamburguer-btn-side-header-overlay {
  right: 30px;
  z-index: 9999;
}
.hamburguer-btn .hamburguer {
  pointer-events: none;
}
.hamburguer-btn .hamburguer span {
  position: absolute;
  top: 54%;
  left: 0;
  width: 100%;
  height: 2px;
  transition: ease width 300ms;
  background: #333;
}
.hamburguer-btn .hamburguer span:nth-child(1) {
  top: 30%;
}
.hamburguer-btn .hamburguer span:nth-child(2) {
  transition-delay: 100ms;
}
.hamburguer-btn .hamburguer span:nth-child(3) {
  top: 78%;
  transition-delay: 200ms;
}
.hamburguer-btn .close {
  opacity: 0;
  pointer-events: none;
  transition: ease all 300ms;
}
.hamburguer-btn .close span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 2px;
  background: #333;
  transition: ease all 300ms;
  transform-origin: 50% 0;
}
.hamburguer-btn .close span:nth-child(1) {
  transform: translateX(-45%) rotate(45deg);
}
.hamburguer-btn .close span:nth-child(2) {
  transform: translateX(-50%) rotate(-45deg);
}
.hamburguer-btn.active .hamburguer {
  opacity: 0;
}
.hamburguer-btn.active .hamburguer span {
  width: 0;
}
.hamburguer-btn.active .close {
  opacity: 1;
  transition: ease all 300ms;
}
.hamburguer-btn.active .close span {
  width: 100%;
  transition: ease all 300ms;
}
.hamburguer-btn.active .close span:nth-child(2) {
  transition-delay: 300ms;
}
.hamburguer-btn.hamburguer-btn-light .hamburguer span,
.hamburguer-btn.hamburguer-btn-light .close span {
  background: #fff;
}

html.overflow-hidden .hamburguer-btn.hamburguer-btn-side-header-overlay {
  right: 13px;
}

/* Hamburguer Sticky Colors */
html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-light .hamburguer span,
html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-light .close span,
.pin-wrapper .sticky-active:not([class*=sticky-wrapper-effect]) .hamburguer-btn.hamburguer-btn-sticky-light .hamburguer span,
.pin-wrapper .sticky-active:not([class*=sticky-wrapper-effect]) .hamburguer-btn.hamburguer-btn-sticky-light .close span,
.pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-light .hamburguer span,
.pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-light .close span {
  background: #FFF;
}
html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-dark .hamburguer span,
html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-dark .close span,
.pin-wrapper .sticky-active:not([class*=sticky-wrapper-effect]) .hamburguer-btn.hamburguer-btn-sticky-dark .hamburguer span,
.pin-wrapper .sticky-active:not([class*=sticky-wrapper-effect]) .hamburguer-btn.hamburguer-btn-sticky-dark .close span,
.pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-dark .hamburguer span,
.pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-dark .close span {
  background: #CCC;
}

/* Badge */
.btn-badge {
  position: relative;
}
.btn-badge .badge {
  top: -0.5rem;
  right: -0.5rem;
  position: absolute;
}

/* Gradient */
.btn-gradient:not(.btn-outline) {
  border: 0;
}
.btn-gradient:not(.btn-outline):hover, .btn-gradient:not(.btn-outline).hover {
  border: 0;
}
.btn-gradient:not(.btn-outline):focus, .btn-gradient:not(.btn-outline).focus {
  border: 0;
}
.btn-gradient:not(.btn-outline).disabled, .btn-gradient:not(.btn-outline):disabled {
  border: 0;
}
.btn-gradient:not(.btn-outline):active, .btn-gradient:not(.btn-outline).active {
  border: 0;
}

.btn-gradient.btn-outline {
  border: 2px solid transparent;
  background: transparent;
  border-image-slice: 1 !important;
}
.btn-gradient.btn-outline:hover, .btn-gradient.btn-outline.hover {
  border: 2px solid transparent;
}
.btn-gradient.btn-outline.disabled, .btn-gradient.btn-outline:disabled {
  border: 2px solid transparent;
}
.btn-gradient.btn-outline:active, .btn-gradient.btn-outline.active {
  border: 2px solid transparent;
}

/* Cards */
.card {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.card-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.card-body {
  padding: 2rem;
}

.card-text-color-hover-light:hover * {
  color: #FFF !important;
}

.card-background-image-hover {
  background-size: cover;
  background-position: center;
}
.card-background-image-hover .card-body {
  background: rgba(255, 255, 255, 0.9);
  margin: 1rem;
  padding: 1rem;
}

/* Flip Card */
.flip-card {
  position: relative;
  border: none;
  perspective: 1000px;
}
.flip-card .flip-front,
.flip-card .flip-back {
  padding: 2rem;
  backface-visibility: hidden;
  background: #FFF;
  box-shadow: 0px 0px 44px -10px rgba(0, 0, 0, 0.15);
  transform-style: preserve-3d;
  transition: ease transform 500ms;
}
.flip-card .flip-front .flip-content,
.flip-card .flip-back .flip-content {
  transform: translate3d(0, 0, 1px);
  backface-visibility: hidden;
}
.flip-card .flip-front.overlay:before,
.flip-card .flip-back.overlay:before {
  transform: translate3d(0, 0, 0px);
  backface-visibility: hidden;
}
.flip-card .flip-front {
  transform: translate3d(0, 0, 1px) rotateY(0deg);
}
.flip-card .flip-back {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, -1px) rotateY(180deg);
}
.flip-card:hover .flip-front {
  transform: translate3d(0, 0, -1px) rotateY(-180deg);
}
.flip-card:hover .flip-back {
  transform: translate3d(0, 0, 1px) rotateY(0deg);
}
.flip-card.flip-card-vertical .flip-front {
  transform: translate3d(0, 0, 1px) rotateX(0deg);
}
.flip-card.flip-card-vertical .flip-back {
  transform: translate3d(0, 0, -1px) rotateX(180deg);
}
.flip-card.flip-card-vertical:hover .flip-front {
  transform: translate3d(0, 0, -1px) rotateX(-180deg);
}
.flip-card.flip-card-vertical:hover .flip-back {
  transform: translate3d(0, 0, 1px) rotateX(0deg);
}
.flip-card.flip-card-3d .flip-front .flip-content, .flip-card.flip-card-3d .flip-back .flip-content {
  perspective: inherit;
  transform: translate3d(0, 0, 60px);
}

/* Text */
.text-color-grey {
  color: #969696 !important;
}

/* Grey */
.bg-color-grey {
  background-color: #F7F7F7 !important;
}

.bg-color-grey-scale-1 {
  background-color: #EDEDED !important;
}

.bg-color-grey-scale-2 {
  background-color: #e8e8e8 !important;
}

.bg-color-grey-scale-3 {
  background-color: #e3e3e3 !important;
}

.bg-color-grey-scale-4 {
  background-color: #dedede !important;
}

.bg-color-grey-scale-5 {
  background-color: #d9d9d9 !important;
}

.bg-color-grey-scale-6 {
  background-color: #d4d4d4 !important;
}

.bg-color-grey-scale-7 {
  background-color: #cecece !important;
}

.bg-color-grey-scale-8 {
  background-color: #c9c9c9 !important;
}

.bg-color-grey-scale-9 {
  background-color: #c4c4c4 !important;
}

.bg-color-grey-scale-10 {
  background-color: #bfbfbf !important;
}

/* Dark */
.bg-color-dark-scale-1 {
  background-color: #333 !important;
}

.bg-color-dark-scale-2 {
  background-color: #2e2e2e !important;
}

.bg-color-dark-scale-3 {
  background-color: #292929 !important;
}

.bg-color-dark-scale-4 {
  background-color: #242424 !important;
}

.bg-color-dark-scale-5 {
  background-color: #1f1f1f !important;
}

/* Borders */
.border-color-light-2 {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.border-color-light-3 {
  border-color: rgba(255, 255, 255, 0.05) !important;
}

/* Dividers */
hr {
  background: #0E2D52;
  border: 0;
  height: 1px;
  margin: 22px 0;
  opacity: 1;
}
hr.short {
  margin: 11px 0;
}
hr.tall {
  margin: 44px 0;
}
hr.taller {
  margin: 66px 0;
}
hr.gratient {
  background: none;
  background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.06), transparent);
}
hr.gradient-to-right {
  background: none;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.06), transparent);
}
hr.gradient-to-left {
  background: none;
  background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.06));
}
hr.solid {
  background: rgba(0, 0, 0, 0.06);
}
hr.light {
  background: rgba(255, 255, 255, 0.15);
}
hr.invisible {
  background: none;
}

.divider {
  border: 0;
  height: 1px;
  margin: 44px auto;
  background: rgba(0, 0, 0, 0.06);
  text-align: center;
  position: relative;
  clear: both;
}
.divider.taller {
  margin: 66px auto;
}
.divider [class*=fa-], .divider .icons {
  text-align: center;
  background: #FFF;
  border-radius: 50px;
  color: #a9a9a9;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  position: absolute;
  text-align: center;
  width: 50px;
  font-size: 20px;
  margin: 0 auto 0 -25px;
  top: -25px;
  left: 50%;
  z-index: 1;
}
.divider.divider-solid {
  background: rgba(0, 0, 0, 0.06);
}
.divider.divider-icon-sm [class*=fa-], .divider.divider-icon-sm .icons {
  width: 30px;
  height: 30px;
  line-height: 30px;
  top: -15px;
  font-size: 15px;
  margin-left: -16px;
}
.divider.divider-icon-lg {
  margin: 86px 0;
}
.divider.divider-icon-lg [class*=fa-], .divider.divider-icon-lg .icons {
  width: 60px;
  height: 60px;
  line-height: 60px;
  top: -30px;
  font-size: 30px;
  margin-left: -30px;
}
.divider.divider-icon-lg.taller {
  margin: 100px auto;
}
.divider.divider-xs {
  width: 35%;
}
.divider.divider-sm {
  width: 67%;
}
.divider.divider-left [class*=fa-], .divider.divider-left .icons {
  left: 0;
  margin-left: 0;
  margin-right: 0;
  right: auto;
}
.divider.divider-right [class*=fa-], .divider.divider-right .icons {
  left: auto;
  margin-left: 0;
  margin-right: 0;
  right: 0;
}
.divider.divider-style-2 [class*=fa-], .divider.divider-style-2 .icons {
  background: #f7f7f7;
  color: #a9a9a9;
}
.divider.divider-style-3 [class*=fa-], .divider.divider-style-3 .icons {
  border: 1px solid #CECECE;
}
.divider.divider-style-4 [class*=fa-], .divider.divider-style-4 .icons {
  border: 1px solid #CECECE;
}
.divider.divider-style-4 [class*=fa-]:after, .divider.divider-style-4 .icons:after {
  border: 3px solid #f7f7f7;
  border-radius: 50%;
  box-sizing: content-box;
  content: "";
  display: block;
  height: 100%;
  left: -4px;
  padding: 1px;
  position: absolute;
  top: -4px;
  width: 100%;
}
.divider.divider-small {
  margin: 5px auto;
  background: transparent;
}
.divider.divider-small hr {
  width: 120px;
  height: 3px;
  background: #FFF;
  margin: 0;
}
.divider.divider-small.divider-small-center {
  text-align: center;
}
.divider.divider-small.divider-small-center hr {
  margin-left: auto;
  margin-right: auto;
}
.divider.divider-small.divider-small-right {
  text-align: right;
}
.divider.divider-small.divider-small-right hr {
  margin-left: auto;
}
.divider.divider-small.divider-light hr {
  background: #DDD;
}
.divider.divider-small.divider-small-sm hr {
  height: 1px;
}
.divider.divider-small.divider-small-lg hr {
  height: 6px;
}
.divider.divider-small.tall {
  margin: 34px 0;
}
.divider.divider-small.taller {
  margin: 46px 0;
}
.divider .divider-small-text {
  background: #FFF;
  color: #999;
  display: inline-block;
  text-align: center;
  font-size: 0.8em;
  height: 30px;
  line-height: 30px;
  padding: 0 30px;
  text-transform: uppercase;
  margin: 0;
  position: relative;
  top: -15px;
}

hr.dashed, .divider.dashed {
  background: none;
  position: relative;
}
hr.dashed:after, .divider.dashed:after {
  border-top: 1px dashed rgba(0, 0, 0, 0.06);
  content: "";
  display: block;
  height: 0;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  width: 100%;
}
hr.pattern, .divider.pattern {
  background: none;
  position: relative;
}
hr.pattern:after, .divider.pattern:after {
  background: transparent url(../img/patterns/worn_dots.png) repeat 0 0;
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  top: 50%;
  margin-top: -7px;
  width: 100%;
}
hr.pattern.pattern-2:after, .divider.pattern.pattern-2:after {
  background-image: url(../img/patterns/az_subtle.png);
}

@keyframes dividerProgress25 {
  from {
    width: 0;
  }
  to {
    width: 25%;
  }
}
.dividerProgress25 {
  animation-name: dividerProgress25;
}

/* Divider Borders */
.divider-top-border {
  border-top: 1px solid #0E2D52;
}

.divider-bottom-border {
  border-bottom: 1px solid #0E2D52;
}

.divider-left-border {
  border-left: 1px solid #0E2D52;
}

.divider-right-border {
  border-right: 1px solid #0E2D52;
}

/* Dropdowns */
.dropdown.dropdown-style-1 .dropdown-toggle {
  display: flex;
  align-items: center;
  padding: 1rem 1.1rem;
  background: #FFF;
  border-radius: 0;
  border-right: 1px solid #ededed;
  min-width: 280px;
  font-weight: bold;
  text-decoration: none;
}
.dropdown.dropdown-style-1 .dropdown-toggle:after {
  content: "\f107";
  position: absolute;
  right: 1.1rem;
  top: 50%;
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  transform: translate3d(0, -50%, 0);
  border: 0;
  margin: 0;
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.dropdown.dropdown-style-1 .dropdown-toggle > i {
  display: none;
}
.dropdown.dropdown-style-1 .dropdown-menu {
  width: 100%;
  border-radius: 0;
  margin-top: -1px;
  border-color: #ededed;
}
.dropdown.dropdown-style-1 .dropdown-menu .dropdown-item {
  font-size: 14px;
  padding-right: 1.1rem;
  padding-left: 1.1rem;
}
.dropdown.dropdown-corner-left-fix .dropdown-menu {
  margin-left: -5px;
}

/* Icon Featured */
.icon-featured {
  display: inline-block;
  margin: 15px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #FFF;
  font-size: 2.3em;
  line-height: 110px;
}
.icon-featured:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  box-sizing: content-box;
}

/* Featured Box */
.featured-box {
  background: #FFF;
  box-sizing: border-box;
  border-bottom: 1px solid #DFDFDF;
  border-left: 1px solid #ECECEC;
  border-radius: 15px;
  border-right: 1px solid #ECECEC;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  min-height: 100px;
  position: relative;
  text-align: center;
  z-index: 1;
}
.featured-box h4, .featured-box .h4 {
  font-size: 1.3em;
  font-weight: 400;
  letter-spacing: -0.7px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.featured-box .box-content {
  border-radius: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-top-width: 4px;
  padding: 1.8rem;
  position: relative;
}
.featured-box .box-content:not(.box-content-border-0) {
  top: -1px;
  border-top-width: 4px;
}
.featured-box .box-content.box-content-border-0 {
  border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
  border-bottom: 0 !important;
}
.featured-box .box-content.box-content-border-bottom {
  top: 1px;
}
.featured-box .box-content-border-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
  border-bottom: 4px solid rgba(0, 0, 0, 0.06);
}

/* Featured Box Left */
.featured-box-text-left {
  text-align: left;
}

/* Featured Boxes - Flat */
.featured-boxes-flat .featured-box {
  box-shadow: none;
  margin: 10px 0;
}
.featured-boxes-flat .featured-box .box-content {
  background: #FFF;
  margin-top: 65px;
}
.featured-boxes-flat .featured-box .icon-featured {
  display: inline-block;
  font-size: 2em;
  height: 90px;
  line-height: 90px;
  padding: 0;
  width: 90px;
  margin: -100px 0 -15px;
  position: relative;
  top: -40px;
}

/* Featured Boxes - Style 2 */
.featured-boxes-style-2 .featured-box {
  background: none;
  border: 0;
  margin: 10px 0;
  box-shadow: none;
}
.featured-boxes-style-2 .featured-box .box-content {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.featured-boxes-style-2 .featured-box .icon-featured {
  display: inline-block;
  font-size: 1.8em;
  height: 75px;
  line-height: 75px;
  padding: 0;
  width: 75px;
  margin-top: 0;
}

/* Featured Boxes - Style 3 */
.featured-boxes-style-3 .featured-box {
  margin: 10px 0;
}
.featured-boxes-style-3 .featured-box .icon-featured {
  display: inline-block;
  font-size: 1.8em;
  height: 75px;
  line-height: 75px;
  padding: 0;
  width: 75px;
  margin-top: -37px;
  top: -37px;
  background: #FFF;
  border: 2px solid rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.06);
}
.featured-boxes-style-3:not(.featured-boxes-flat) .featured-box {
  background: none;
  border: 0;
  box-shadow: none;
}
.featured-boxes-style-3:not(.featured-boxes-flat) .featured-box .box-content {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}

/* Featured Boxes - Style 4 */
.featured-boxes-style-4 .featured-box {
  background: none;
  border: 0;
  margin: 10px 0;
  box-shadow: none;
}
.featured-boxes-style-4 .featured-box .box-content {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.featured-boxes-style-4 .featured-box .icon-featured {
  display: inline-block;
  font-size: 35px;
  height: 45px;
  line-height: 45px;
  padding: 0;
  width: 45px;
  margin-top: 0;
  margin-bottom: 10px;
  background: transparent;
  color: rgba(0, 0, 0, 0.06);
  border-radius: 0;
}

/* Featured Boxes - Style 5, 6 and 7 */
.featured-boxes-style-5 .featured-box, .featured-boxes-style-6 .featured-box, .featured-boxes-style-7 .featured-box {
  background: none;
  border: 0;
  box-shadow: none;
  margin: 10px 0;
}
.featured-boxes-style-5 .featured-box .box-content, .featured-boxes-style-6 .featured-box .box-content, .featured-boxes-style-7 .featured-box .box-content {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.featured-boxes-style-5 .featured-box .box-content h4, .featured-boxes-style-5 .featured-box .box-content .h4, .featured-boxes-style-6 .featured-box .box-content h4, .featured-boxes-style-6 .featured-box .box-content .h4, .featured-boxes-style-7 .featured-box .box-content h4, .featured-boxes-style-7 .featured-box .box-content .h4 {
  color: #474747;
}
.featured-boxes-style-5 .featured-box .icon-featured, .featured-boxes-style-6 .featured-box .icon-featured, .featured-boxes-style-7 .featured-box .icon-featured {
  display: inline-block;
  font-size: 2em;
  height: 75px;
  padding: 0;
  width: 75px;
  margin-top: 0;
  margin-bottom: 10px;
  background: #FFF;
  border: 1px solid #dfdfdf;
  color: #777;
  line-height: 73px;
}

/* Featured Boxes - Style 6 */
.featured-boxes-style-6 .featured-box .icon-featured {
  border: 1px solid #cecece;
  color: #777;
}
.featured-boxes-style-6 .featured-box .icon-featured:after {
  border: 5px solid #f7f7f7;
  border-radius: 50%;
  box-sizing: content-box;
  content: "";
  display: block;
  height: 100%;
  left: -6px;
  padding: 1px;
  position: absolute;
  top: -6px;
  width: 100%;
}

/* Featured Boxes - Style 7 */
.featured-boxes-style-7 .featured-box .icon-featured {
  border: 1px solid #dfdfdf;
  color: #777;
}

/* Featured Boxes - Style 8 */
.featured-boxes-style-8 .featured-box .icon-featured {
  display: inline-block;
  font-size: 2em;
  height: 75px;
  padding: 0;
  width: 75px;
  margin: -15px -15px 0 0;
  background: #FFF;
  line-height: 75px;
  border: 0;
  color: #777;
}
.featured-boxes-style-8 .featured-box .icon-featured:after {
  display: none;
}

/* Featured Boxes Modern */
.featured-boxes-modern-style-1 .featured-box {
  position: relative;
  overflow: hidden;
  border-radius: 0;
  height: auto !important;
  margin: 0 !important;
}
.featured-boxes-modern-style-1 .featured-box .featured-box-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: cubic-bezier(0.39, 0.66, 0.42, 0.99) all 2s;
  transform: scale(1);
}
.featured-boxes-modern-style-1 .featured-box .box-content {
  padding: 4rem;
  border: none;
  z-index: 3;
}
.featured-boxes-modern-style-1 .featured-box .box-content .icon-featured {
  color: #FFF;
  background: transparent;
  width: auto;
  height: auto;
  line-height: 1.4;
  font-size: 2.9rem;
  margin: 0 0 1rem;
}
.featured-boxes-modern-style-1 .featured-box .box-content h2, .featured-boxes-modern-style-1 .featured-box .box-content .h2 {
  color: #FFF;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.3;
  margin-bottom: 0.7rem;
}
.featured-boxes-modern-style-1 .featured-box .box-content p {
  color: #999;
  margin-bottom: 1.6rem;
}
.featured-boxes-modern-style-1 .featured-box:hover .featured-box-background {
  transform: scale(1.1);
}
@media (max-width: 575px) {
  .featured-boxes-modern-style-1 .featured-box .box-content {
    padding: 2rem 1.5rem;
  }
}

.featured-boxes-modern-style-2 .featured-box {
  position: relative;
  background: #6c6ef1;
  background: linear-gradient(135deg, #6c6ef1 0%, #e2498e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#6c6ef1", endColorstr="#e2498e", GradientType=1 );
  border-radius: 0;
  margin: 0 !important;
  overflow: hidden;
}
.featured-boxes-modern-style-2 .featured-box:before {
  content: "";
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  background: #FFF;
}
.featured-boxes-modern-style-2 .featured-box .box-content {
  padding: 4rem;
  border: none;
}
.featured-boxes-modern-style-2 .featured-box .box-content .icon-featured {
  color: #FFF;
  background: transparent;
  width: auto;
  height: auto;
  line-height: 1.4;
  font-size: 2.9rem;
  margin: 0 0 1rem;
}
.featured-boxes-modern-style-2 .featured-box .box-content h2, .featured-boxes-modern-style-2 .featured-box .box-content .h2 {
  color: #333;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.3;
  margin-bottom: 0.7rem;
}
.featured-boxes-modern-style-2 .featured-box .box-content p {
  color: #999;
  margin-bottom: 1.6rem;
}
@media (max-width: 575px) {
  .featured-boxes-modern-style-2 .featured-box .box-content {
    padding: 2rem 1.5rem;
  }
}

.featured-boxes-modern-style-2-hover-only .featured-box:before {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  transition: cubic-bezier(0.39, 0.66, 0.42, 0.99) all 200ms;
}
.featured-boxes-modern-style-2-hover-only:not(:hover) .featured-box {
  background: rgba(0, 0, 0, 0.06) !important;
  box-shadow: none;
  border: 0;
}
.featured-boxes-modern-style-2-hover-only:not(:hover) .featured-box-no-borders {
  background: none !important;
}
.featured-boxes-modern-style-2-hover-only:not(:hover) .featured-box-box-shadow {
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
}
.featured-boxes-modern-style-2-hover-only:hover .featured-box:before {
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
}

/* Featured Boxes */
.featured-boxes .featured-box {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

/* Effects */
.featured-box-effect-1 .icon-featured:after {
  top: -7px;
  left: -7px;
  padding: 7px;
  box-shadow: 0 0 0 3px #FFF;
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(1);
  opacity: 0;
}
.featured-box-effect-1:hover .icon-featured:after {
  transform: scale(0.8);
  opacity: 1;
}

.featured-box-effect-2 .icon-featured:after {
  top: -7px;
  left: -7px;
  padding: 7px;
  box-shadow: 0 0 0 3px #FFF;
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(0.8);
  opacity: 0;
}
.featured-box-effect-2:hover .icon-featured:after {
  transform: scale(0.95);
  opacity: 1;
}

.featured-box-effect-3 .icon-featured:after {
  top: 0;
  left: 0;
  box-shadow: 0 0 0 10px #FFF;
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(0.9);
  opacity: 0;
}
.featured-box-effect-3:hover .icon-featured {
  color: #FFF !important;
}
.featured-box-effect-3:hover .icon-featured:after {
  transform: scale(1);
  opacity: 0.8;
}

.featured-box-effect-4 .icon-featured {
  transition: transform 0.2s, transform 0.2s;
  transform: scale(1);
}
.featured-box-effect-4:hover .icon-featured {
  transform: scale(1.15);
}

.featured-box-effect-5 .icon-featured {
  overflow: hidden;
  transition: all 0.3s;
}
.featured-box-effect-5:hover .icon-featured {
  animation: toRightFromLeft 0.3s forwards;
}

@keyframes toRightFromLeft {
  49% {
    transform: translate(100%);
  }
  50% {
    opacity: 0;
    transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}
.featured-box-effect-6 .icon-featured:after {
  transition: all 0.2s, transform 0.2s;
}
.featured-box-effect-6:hover .box-content .icon-featured:after {
  transform: scale(1.1);
}

.featured-box-effect-7 .icon-featured:after {
  opacity: 0;
  box-shadow: 3px 3px #d5d5d5;
  transform: rotate(-90deg);
  transition: opacity 0.2s, transform 0.2s;
  left: 0;
  top: -1px;
}
.featured-box-effect-7:hover .icon-featured:after {
  opacity: 1;
  transform: rotate(0deg);
}
.featured-box-effect-7 .icon-featured:before {
  transform: scale(0.8);
  opacity: 0.7;
  transition: transform 0.2s, opacity 0.2s;
}
.featured-box-effect-7:hover .icon-featured:before {
  transform: scale(1);
  opacity: 1;
}

/* Feature Box */
.feature-box {
  display: flex;
}
.feature-box .feature-box-icon {
  display: inline-flex;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 100%;
  color: #FFF;
  font-size: 1rem;
  position: relative;
  top: 7px;
  text-align: center;
}
.feature-box .feature-box-info {
  flex: 1 1 100%;
  padding-left: 15px;
}
.feature-box.align-items-center .feature-box-icon {
  top: 0;
}

/* Style 2 */
.feature-box.feature-box-style-2 h4, .feature-box.feature-box-style-2 .h4 {
  color: #333;
}
.feature-box.feature-box-style-2 .feature-box-icon {
  background: transparent;
  top: -3px;
}
.feature-box.feature-box-style-2 .feature-box-icon [class*=fa-], .feature-box.feature-box-style-2 .feature-box-icon .icons {
  font-size: 1.7em;
}
.feature-box.feature-box-style-2.align-items-center .feature-box-icon {
  top: 0;
}

/* Style 3 */
.feature-box.feature-box-style-3 h4, .feature-box.feature-box-style-3 .h4 {
  color: #333;
}
.feature-box.feature-box-style-3 .feature-box-icon {
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: transparent;
  line-height: 32px;
}
.feature-box.feature-box-style-3 .feature-box-icon [class*=fa-], .feature-box.feature-box-style-3 .feature-box-icon .icons {
  color: rgba(0, 0, 0, 0.06);
}

/* Style 4 */
.feature-box.feature-box-style-4 {
  flex-wrap: wrap;
}
.feature-box.feature-box-style-4 h4, .feature-box.feature-box-style-4 .h4 {
  color: #333;
}
.feature-box.feature-box-style-4 .feature-box-icon {
  flex-basis: 100%;
  justify-content: flex-start;
  background: transparent;
  padding: 0 0 1.2rem 0;
}
.feature-box.feature-box-style-4 .feature-box-icon [class*=fa-], .feature-box.feature-box-style-4 .feature-box-icon .icons {
  font-size: 2.35em;
  width: auto;
  height: auto;
}
.feature-box.feature-box-style-4 .feature-box-info {
  clear: both;
  padding-left: 0;
}

/* Style 5 */
.feature-box.feature-box-style-5 h4, .feature-box.feature-box-style-5 .h4 {
  color: #333;
}
.feature-box.feature-box-style-5 .feature-box-icon {
  background: transparent;
  top: 0;
  padding: 1em 1.4em;
  min-width: 5em;
}
.feature-box.feature-box-style-5 .feature-box-icon [class*=fa-], .feature-box.feature-box-style-5 .feature-box-icon .icons {
  font-size: 2.75em;
}
.feature-box.feature-box-style-5.align-items-center .feature-box-icon {
  top: 0;
}

/* Style 6 */
.feature-box.feature-box-style-6 h4, .feature-box.feature-box-style-6 .h4 {
  color: #333;
}
.feature-box.feature-box-style-6 .feature-box-icon {
  border: 1px solid #cecece;
  background: transparent;
  line-height: 32px;
}
.feature-box.feature-box-style-6 .feature-box-icon:after {
  border: 3px solid #f7f7f7;
  border-radius: 50%;
  box-sizing: content-box;
  content: "";
  display: block;
  height: 100%;
  left: -4px;
  padding: 1px;
  position: absolute;
  top: -4px;
  width: 100%;
}
.feature-box.feature-box-style-6 .feature-box-icon [class*=fa-], .feature-box.feature-box-style-6 .feature-box-icon .icons {
  color: #a9a9a9;
}

/* Feature Box Icon Size */
.feature-box .feature-box-icon.feature-box-icon-large {
  width: 3.7em;
  height: 3.7em;
}
.feature-box .feature-box-icon.feature-box-icon-extra-large {
  width: 4.5em;
  height: 4.5em;
}

/* Reverse All Resolutions */
.feature-box.reverse-allres {
  text-align: right;
  flex-direction: row-reverse;
}
.feature-box.reverse-allres .feature-box-info {
  padding-right: 15px;
  padding-left: 0;
}
.feature-box.reverse-allres.feature-box-style-4 .feature-box-icon {
  justify-content: flex-end;
}
.feature-box.reverse-allres.feature-box-style-4 .feature-box-info {
  padding-right: 0;
}

/* Reverse */
@media (min-width: 992px) {
  .feature-box.reverse {
    text-align: right;
    flex-direction: row-reverse;
  }
  .feature-box.reverse .feature-box-info {
    padding-right: 15px;
    padding-left: 0;
  }
  .feature-box.reverse.feature-box-style-4 .feature-box-icon {
    justify-content: flex-end;
  }
  .feature-box.reverse.feature-box-style-4 .feature-box-info {
    padding-right: 0;
  }
}
/* Full Width */
.featured-boxes-full .featured-box-full {
  text-align: center;
  padding: 55px;
}
.featured-boxes-full .featured-box-full [class*=fa-], .featured-boxes-full .featured-box-full .icons, .featured-boxes-full .featured-box-full h1, .featured-boxes-full .featured-box-full .h1, .featured-boxes-full .featured-box-full h2, .featured-boxes-full .featured-box-full .h2, .featured-boxes-full .featured-box-full h3, .featured-boxes-full .featured-box-full .h3, .featured-boxes-full .featured-box-full h4, .featured-boxes-full .featured-box-full .h4, .featured-boxes-full .featured-box-full h5, .featured-boxes-full .featured-box-full .h5, .featured-boxes-full .featured-box-full h6, .featured-boxes-full .featured-box-full .h6, .featured-boxes-full .featured-box-full p, .featured-boxes-full .featured-box-full a {
  padding: 0;
  margin: 0;
}
.featured-boxes-full .featured-box-full:not(.featured-box-full-light) [class*=fa-], .featured-boxes-full .featured-box-full:not(.featured-box-full-light) .icons, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h1, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) .h1, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h2, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) .h2, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h3, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) .h3, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h4, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) .h4, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h5, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) .h5, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h6, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) .h6, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) p, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) a {
  color: #FFF;
}
.featured-boxes-full .featured-box-full .icons, .featured-boxes-full .featured-box-full [class*=fa-] {
  font-size: 3.9em;
  margin-bottom: 15px;
}
.featured-boxes-full .featured-box-full p {
  padding-top: 12px;
  opacity: 0.8;
}
.featured-boxes-full .featured-box-full.featured-box-full-light {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.featured-boxes-full .featured-box-full.featured-box-full-light:not(:last-child) {
  border-right: 0;
}
.featured-boxes-full .featured-box-full.featured-box-full-light:first-child {
  border-left: 0;
}

/*
* IE
*/
html.ie .feature-box .feature-box-icon img {
  min-width: 100%;
}
html.ie .feature-box .feature-box-info {
  flex: 0 1 100%;
}

/* Forms */
form label {
  font-weight: normal;
}

textarea {
  resize: vertical;
}

select {
  border: 1px solid #E5E7E9;
  border-radius: 4px;
  outline: none;
}

.label {
  font-weight: normal;
}

label.required:after {
  content: "*";
  font-size: 0.8em;
  margin: 0.3em;
  position: relative;
  top: -2px;
}

.form-group:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.form-control {
  border-color: rgba(0, 0, 0, 0.09);
}
.form-control:not(.form-control-lg) {
  font-size: 0.75rem;
  line-height: 1.3;
}
.form-control.form-control-lg {
  font-size: 1.2rem;
}
.form-control:not(.form-control-sm):not(.form-control-lg) {
  font-size: 0.85rem;
  line-height: 1.85;
}
.form-control.form-control-focused {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-color: #CCC;
}
.form-control:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-color: #CCC;
}
.form-control.error {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.form-control.error:focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.page-link {
  border-color: rgba(0, 0, 0, 0.06);
}

.input-group .form-control {
  height: auto;
}

.input-group-rounded .form-control {
  border-radius: 2rem 0rem 0rem 2rem;
  border: 0;
  padding-left: 1rem;
}
.input-group-rounded .input-group-append .btn {
  border-color: rgba(0, 0, 0, 0.09);
  border-radius: 0rem 2rem 2rem 0rem;
  font-size: 0.7rem;
  padding: 0.87rem;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  z-index: 3;
}

/* Form - iOS Override */
input[type=text],
input[type=password],
input[type=datetime],
input[type=datetime-local],
input[type=date],
input[type=month],
input[type=time],
input[type=week],
input[type=number],
input[type=email],
input[type=url],
input[type=search],
input[type=tel],
input[type=color],
textarea {
  -webkit-appearance: none;
}

.form-control::-webkit-input-placeholder,
input[type=text]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=datetime]::-webkit-input-placeholder,
input[type=datetime-local]::-webkit-input-placeholder,
input[type=date]::-webkit-input-placeholder,
input[type=month]::-webkit-input-placeholder,
input[type=time]::-webkit-input-placeholder,
input[type=week]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=color]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #bdbdbd;
}
.form-control::-moz-placeholder,
input[type=text]::-moz-placeholder,
input[type=password]::-moz-placeholder,
input[type=datetime]::-moz-placeholder,
input[type=datetime-local]::-moz-placeholder,
input[type=date]::-moz-placeholder,
input[type=month]::-moz-placeholder,
input[type=time]::-moz-placeholder,
input[type=week]::-moz-placeholder,
input[type=number]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=url]::-moz-placeholder,
input[type=search]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=color]::-moz-placeholder,
textarea::-moz-placeholder {
  color: #bdbdbd;
}
.form-control:-ms-input-placeholder,
input[type=text]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=datetime]:-ms-input-placeholder,
input[type=datetime-local]:-ms-input-placeholder,
input[type=date]:-ms-input-placeholder,
input[type=month]:-ms-input-placeholder,
input[type=time]:-ms-input-placeholder,
input[type=week]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=url]:-ms-input-placeholder,
input[type=search]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=color]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #bdbdbd;
}

/* Form - Bootstrap Override */
.btn-lg,
.btn-group-lg > .btn {
  line-height: 1.334;
}

.input-group-btn .btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

select.form-control-sm, select.form-control-lg {
  line-height: 1;
  height: auto !important;
}
select.form-control-lg {
  padding: 1.2rem 0.5rem;
}

.bootstrap-timepicker-widget input {
  border: 0;
}
.bootstrap-timepicker-widget table td input {
  width: 40px;
}

.fileupload .btn {
  line-height: 20px;
  margin-left: -5px;
}

/* Forms Validations */
label.valid {
  display: inline-block;
  text-indent: -9999px;
}
label.error {
  color: #c10000;
  font-size: 0.9em;
  line-height: 18px;
  padding: 5px 0 0;
}
label.error#captcha-error {
  margin-top: 15px;
}

.form-errors-light .form-control.error {
  border-color: #FFF;
}
.form-errors-light label.error {
  color: #FFF;
}

/* Simple Search */
.simple-search .form-control {
  border-radius: 1.3rem 0 0 1.3rem;
  border-color: #EEE;
  border-right: 0;
}
.simple-search .form-control:focus {
  box-shadow: none;
}
.simple-search .input-group-append {
  border-radius: 0 1.3rem 1.3rem 0;
  border: 1px solid #EEE;
  border-left: 0;
}
.simple-search .input-group-append .btn {
  background: transparent !important;
  border: 0;
  box-shadow: none !important;
}

/* Captcha */
.captcha {
  clear: both;
  overflow: hidden;
  background: #F2F2F2;
  text-align: center;
  margin-bottom: 20px;
  max-width: 160px;
  height: 78px;
  position: relative;
}
.captcha input {
  border: 0;
}

.captcha-input {
  position: relative;
  top: 15px;
  font-size: 14px;
}

.captcha-image {
  float: left;
  position: relative;
  top: -5px;
  left: -13px;
}

.captcha-refresh {
  position: absolute;
  z-index: 1;
  bottom: 2px;
  right: 2px;
  border-radius: 3px;
  padding: 2px 5px;
}
.captcha-refresh a, .captcha-refresh a:hover, .captcha-refresh a:focus, .captcha-refresh a:active {
  color: #333;
}

/* Form Styles */
.form-style-2 .form-group {
  margin-bottom: 0.7rem;
}
.form-style-2 .form-control {
  padding: 0.7rem 1rem;
  border: 0;
}
.form-style-2 .form-control::-webkit-input-placeholder {
  color: #202528;
  opacity: 0.5;
}
.form-style-2 .form-control::-moz-placeholder {
  color: #202528;
  opacity: 0.5;
}
.form-style-2 .form-control:-ms-input-placeholder {
  color: #202528;
  opacity: 0.5;
}
.form-style-2 .form-control:-moz-placeholder {
  color: #202528;
  opacity: 0.5;
}
.form-style-2 .form-control.error {
  border-width: 2px;
}

.form-style-3 .form-group {
  margin-bottom: 1.1rem;
}
.form-style-3 .form-control {
  padding: 0.7rem 1rem;
  background: #F4F4F4;
  border: none;
}
.form-style-3 .form-control::-webkit-input-placeholder {
  color: #202528;
  opacity: 0.5;
}
.form-style-3 .form-control::-moz-placeholder {
  color: #202528;
  opacity: 0.5;
}
.form-style-3 .form-control:-ms-input-placeholder {
  color: #202528;
  opacity: 0.5;
}
.form-style-3 .form-control:-moz-placeholder {
  color: #202528;
  opacity: 0.5;
}
.form-style-3 .form-control.error {
  border: 2px solid #e21a1a;
}

.form-style-4 .form-control {
  background: transparent;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom-color: rgba(0, 0, 0, 0.4);
  color: #FFF;
  box-shadow: none !important;
}

/* Headings */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: #333;
  font-weight: 200;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  /*!rtl:raw:
     font-family: 'Cairo', 'Tajawal', 'Merriweather', 'Montserrat', sans-serif;
     */
}

h1, .h1 {
  font-size: 2.6em;
  line-height: 44px;
  margin: 0 0 32px 0;
}

h2, .h2 {
  font-size: 2.2em;
  font-weight: 600;
  line-height: 42px;
  margin: 0 0 32px 0;
}

h3, .h3 {
  font-size: 1.8em;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 33px;
}

h4, .h4 {
  font-size: 1.4em;
  font-weight: 600;
  line-height: 27px;
  margin: 0 0 14px 0;
}

h5, .h5 {
  font-size: 1em;
  font-weight: 600;
  line-height: 18px;
  margin: 0 0 14px 0;
  text-transform: uppercase;
}

h6, .h6 {
  color: #333;
  font-size: 1em;
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 14px 0;
}

h1.big, .big.h1 {
  font-size: 4.2em;
  line-height: 54px;
}
h1.small, .small.h1 {
  font-size: 2.2em;
  line-height: 42px;
  font-weight: 600;
}
h1.short, .short.h1 {
  margin-bottom: 15px;
}

h2.short, .short.h2 {
  margin-bottom: 15px;
}

h3.short, .short.h3,
h4.short,
.short.h4,
h5.short,
.short.h5,
h6.short,
.short.h6 {
  margin-bottom: 9px;
}

h1.shorter, .shorter.h1,
h2.shorter,
.shorter.h2,
h3.shorter,
.shorter.h3,
h4.shorter,
.shorter.h4,
h5.shorter,
.shorter.h5,
h6.shorter,
.shorter.h6 {
  margin-bottom: 0;
}

h1.tall, .tall.h1,
h2.tall,
.tall.h2,
h3.tall,
.tall.h3,
h4.tall,
.tall.h4,
h5.tall,
.tall.h5,
h6.tall,
.tall.h6 {
  margin-bottom: 33px;
}

h1.taller, .taller.h1,
h2.taller,
.taller.h2,
h3.taller,
.taller.h3,
h4.taller,
.taller.h4,
h5.taller,
.taller.h5,
h6.taller,
.taller.h6 {
  margin-bottom: 44px;
}

h1.spaced, .spaced.h1,
h2.spaced,
.spaced.h2,
h3.spaced,
.spaced.h3,
h4.spaced,
.spaced.h4,
h5.spaced,
.spaced.h5,
h6.spaced,
.spaced.h6 {
  margin-top: 22px;
}

h1.more-spaced, .more-spaced.h1,
h2.more-spaced,
.more-spaced.h2,
h3.more-spaced,
.more-spaced.h3,
h4.more-spaced,
.more-spaced.h4,
h5.more-spaced,
.more-spaced.h5,
h6.more-spaced,
.more-spaced.h6 {
  margin-top: 44px;
}

h1.dark, .dark.h1,
h2.dark,
.dark.h2,
h3.dark,
.dark.h3,
h4.dark,
.dark.h4,
h5.dark,
.dark.h5 {
  color: #333;
}

h1.light, .light.h1,
h2.light,
.light.h2,
h3.light,
.light.h3,
h4.light,
.light.h4,
h5.light,
.light.h5,
h6.light,
.light.h6 {
  color: #FFF;
}

h1.text-shadow, .text-shadow.h1,
h2.text-shadow,
.text-shadow.h2,
h3.text-shadow,
.text-shadow.h3,
h4.text-shadow,
.text-shadow.h4,
h5.text-shadow,
.text-shadow.h5,
h6.text-shadow,
.text-shadow.h6 {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

/* Heading */
.heading.heading-border {
  margin-bottom: 25px;
}
.heading.heading-border h1, .heading.heading-border .h1, .heading.heading-border h2, .heading.heading-border .h2, .heading.heading-border h3, .heading.heading-border .h3, .heading.heading-border h4, .heading.heading-border .h4, .heading.heading-border h5, .heading.heading-border .h5, .heading.heading-border h6, .heading.heading-border .h6 {
  margin: 0;
  padding: 0;
}
.heading.heading-bottom-border h1, .heading.heading-bottom-border .h1 {
  border-bottom: 5px solid #eaeaea;
  padding-bottom: 10px;
}
.heading.heading-bottom-border h2, .heading.heading-bottom-border .h2, .heading.heading-bottom-border h3, .heading.heading-bottom-border .h3 {
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 10px;
}
.heading.heading-bottom-border h4, .heading.heading-bottom-border .h4, .heading.heading-bottom-border h5, .heading.heading-bottom-border .h5, .heading.heading-bottom-border h6, .heading.heading-bottom-border .h6 {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 5px;
}
.heading.heading-bottom-double-border h1, .heading.heading-bottom-double-border .h1, .heading.heading-bottom-double-border h2, .heading.heading-bottom-double-border .h2, .heading.heading-bottom-double-border h3, .heading.heading-bottom-double-border .h3 {
  border-bottom: 3px double #eaeaea;
  padding-bottom: 10px;
}
.heading.heading-bottom-double-border h4, .heading.heading-bottom-double-border .h4, .heading.heading-bottom-double-border h5, .heading.heading-bottom-double-border .h5, .heading.heading-bottom-double-border h6, .heading.heading-bottom-double-border .h6 {
  border-bottom: 3px double #eaeaea;
  padding-bottom: 5px;
}
.heading.heading-middle-border {
  position: relative;
}
.heading.heading-middle-border:before {
  border-top: 1px solid #eaeaea;
  content: "";
  height: 0;
  left: auto;
  position: absolute;
  right: 0;
  top: 50%;
  width: 100%;
}
.heading.heading-middle-border h1, .heading.heading-middle-border .h1, .heading.heading-middle-border h2, .heading.heading-middle-border .h2, .heading.heading-middle-border h3, .heading.heading-middle-border .h3, .heading.heading-middle-border h4, .heading.heading-middle-border .h4, .heading.heading-middle-border h5, .heading.heading-middle-border .h5, .heading.heading-middle-border h6, .heading.heading-middle-border .h6 {
  position: relative;
  background: #FFF;
  display: inline-block;
}
.heading.heading-middle-border h1, .heading.heading-middle-border .h1 {
  padding-right: 15px;
}
.heading.heading-middle-border h2, .heading.heading-middle-border .h2, .heading.heading-middle-border h3, .heading.heading-middle-border .h3 {
  padding-right: 10px;
}
.heading.heading-middle-border h4, .heading.heading-middle-border .h4, .heading.heading-middle-border h5, .heading.heading-middle-border .h5, .heading.heading-middle-border h6, .heading.heading-middle-border .h6 {
  padding-right: 5px;
}
.heading.heading-middle-border-reverse {
  text-align: right;
}
.heading.heading-middle-border-reverse h1, .heading.heading-middle-border-reverse .h1, .heading.heading-middle-border-reverse h2, .heading.heading-middle-border-reverse .h2, .heading.heading-middle-border-reverse h3, .heading.heading-middle-border-reverse .h3, .heading.heading-middle-border-reverse h4, .heading.heading-middle-border-reverse .h4, .heading.heading-middle-border-reverse h5, .heading.heading-middle-border-reverse .h5, .heading.heading-middle-border-reverse h6, .heading.heading-middle-border-reverse .h6 {
  padding-right: 0;
}
.heading.heading-middle-border-reverse h1, .heading.heading-middle-border-reverse .h1 {
  padding-left: 15px;
}
.heading.heading-middle-border-reverse h2, .heading.heading-middle-border-reverse .h2, .heading.heading-middle-border-reverse h3, .heading.heading-middle-border-reverse .h3 {
  padding-left: 10px;
}
.heading.heading-middle-border-reverse h4, .heading.heading-middle-border-reverse .h4, .heading.heading-middle-border-reverse h5, .heading.heading-middle-border-reverse .h5, .heading.heading-middle-border-reverse h6, .heading.heading-middle-border-reverse .h6 {
  padding-left: 5px;
}
.heading.heading-middle-border-center {
  text-align: center;
}
.heading.heading-middle-border-center h1, .heading.heading-middle-border-center .h1 {
  padding-left: 15px;
  padding-right: 15px;
}
.heading.heading-middle-border-center h2, .heading.heading-middle-border-center .h2, .heading.heading-middle-border-center h3, .heading.heading-middle-border-center .h3 {
  padding-left: 10px;
  padding-right: 10px;
}
.heading.heading-middle-border-center h4, .heading.heading-middle-border-center .h4, .heading.heading-middle-border-center h5, .heading.heading-middle-border-center .h5, .heading.heading-middle-border-center h6, .heading.heading-middle-border-center .h6 {
  padding-left: 5px;
  padding-right: 5px;
}
.heading.heading-border-xs:before {
  border-top-width: 1px;
}
.heading.heading-border-sm:before {
  border-top-width: 2px;
}
.heading.heading-border-lg:before {
  border-top-width: 3px;
}
.heading.heading-border-xl:before {
  border-top-width: 5px;
}

/* Modal Titles */
.modal-header h3, .modal-header .h3 {
  color: #333;
  text-transform: none;
}

/* Responsive */
@media (max-width: 575px) {
  h2, .h2 {
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  h1.big, .big.h1 {
    font-size: 3.2em;
    line-height: 42px;
  }
}
/* Arrows */
button.mfp-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 0;
  border-radius: 4px;
  background: #333;
  width: 35px;
  height: 100px;
  color: #FFF;
  margin: 0 !important;
  transform: translate3d(0, -50%, 0);
}
button.mfp-arrow:before {
  content: none;
}
button.mfp-arrow:after {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  border: none !important;
}
button.mfp-arrow.mfp-arrow-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
button.mfp-arrow.mfp-arrow-left:after {
  margin-left: 0;
  margin-top: 0;
  position: relative;
  top: auto;
  left: -2px;
  width: auto;
  height: auto;
}
button.mfp-arrow.mfp-arrow-right {
  left: auto;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
button.mfp-arrow.mfp-arrow-right:after {
  content: "\f054";
  margin-left: 0;
  margin-top: 0;
  position: relative;
  top: auto;
  left: auto;
  right: -2px;
  width: auto;
  height: auto;
}

/* Close */
.mfp-close,
.mfp-close-btn-in .mfp-close {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #838383;
}

/* No Margins */
.mfp-no-margins img.mfp-img {
  padding: 0;
}
.mfp-no-margins .mfp-figure:after {
  top: 0;
  bottom: 0;
}
.mfp-no-margins .mfp-container {
  padding: 0;
}

/* Zoom */
.mfp-with-zoom .mfp-container, .mfp-with-zoom.mfp-bg {
  opacity: 0.001;
  transition: all 0.3s ease-out;
}
.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-with-zoom.mfp-removing .mfp-container, .mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Animnate */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  transform: scale(1);
}
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  transform: scale(0.8);
  opacity: 0;
}
.my-mfp-zoom-in.mfp-bg {
  opacity: 0.001;
  transition: opacity 0.3s ease-out;
}
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.2s ease-out;
  transform: translateY(-20px) perspective(600px) rotateX(10deg);
}
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  transform: translateY(0) perspective(600px) rotateX(0);
}
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  transform: translateY(-10px) perspective(600px) rotateX(10deg);
}
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0.01;
  transition: opacity 0.3s ease-out;
}
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Fade */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* Dialog */
.dialog {
  background: white;
  padding: 20px 30px;
  text-align: left;
  margin: 40px auto;
  position: relative;
  max-width: 600px;
}
.dialog.dialog-xs {
  max-width: 200px;
}
.dialog.dialog-sm {
  max-width: 400px;
}
.dialog.dialog-md {
  max-width: 600px;
}
.dialog.dialog-lg {
  max-width: 900px;
}
.dialog.mfp-close-out .mfp-close {
  top: -50px;
  right: -15px;
  color: #FFF;
}

/* White Popup Block */
.white-popup-block {
  background: #FFF;
  padding: 20px 30px;
  text-align: left;
  max-width: 600px;
  margin: 40px auto;
  position: relative;
}
.white-popup-block.white-popup-block-xs {
  max-width: 200px;
}
.white-popup-block.white-popup-block-sm {
  max-width: 400px;
}
.white-popup-block.white-popup-block-md {
  max-width: 600px;
}
.white-popup-block.white-popup-block-lg {
  max-width: 900px;
}

/* Lightbox Opened */
html.lightbox-opened.sticky-header-active #header .header-body {
  padding-right: 17px;
}

/* Modal Opened */
.modal-open {
  padding-right: 0 !important;
}

/* List */
.list li {
  margin-bottom: 13px;
}
.list.pull-left li {
  text-align: left;
}
.list.list-icons {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}
.list.list-icons li {
  position: relative;
  padding-left: 25px;
}
.list.list-icons li > [class*=fa-]:first-child,
.list.list-icons li > .icons:first-child, .list.list-icons li a:first-child > [class*=fa-]:first-child,
.list.list-icons li a:first-child > .icons:first-child {
  position: absolute;
  left: 0;
  top: 5px;
}
.list.list-icons.list-icons-style-2 li {
  padding-top: 5px;
  padding-left: 36px;
}
.list.list-icons.list-icons-style-2 li > [class*=fa-]:first-child,
.list.list-icons.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-style-2 li a:first-child > [class*=fa-]:first-child,
.list.list-icons.list-icons-style-2 li a:first-child > .icons:first-child {
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 4px;
  font-size: 0.8em;
  line-height: 1.3;
}
.list.list-icons.list-icons-style-3 li {
  padding-top: 5px;
  padding-left: 36px;
}
.list.list-icons.list-icons-style-3 li > [class*=fa-]:first-child,
.list.list-icons.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-style-3 li a:first-child > [class*=fa-]:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
  color: #FFF;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 5px;
  font-size: 0.8em;
  line-height: 1.3;
}
.list.list-icons.list-icons-sm li {
  padding-left: 13px;
  margin-bottom: 5px;
}
.list.list-icons.list-icons-sm li > [class*=fa-]:first-child,
.list.list-icons.list-icons-sm li > .icons:first-child, .list.list-icons.list-icons-sm li a:first-child > [class*=fa-]:first-child,
.list.list-icons.list-icons-sm li a:first-child > .icons:first-child {
  font-size: 0.8em;
  top: 7px;
}
.list.list-icons.list-icons-sm.list-icons-style-2 li {
  padding-left: 32px;
}
.list.list-icons.list-icons-sm.list-icons-style-2 li > [class*=fa-]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-sm.list-icons-style-2 li a:first-child > [class*=fa-]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li a:first-child > .icons:first-child {
  width: 20px;
  height: 20px;
  padding: 3px;
}
.list.list-icons.list-icons-sm.list-icons-style-3 li {
  padding-left: 32px;
}
.list.list-icons.list-icons-sm.list-icons-style-3 li > [class*=fa-]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-sm.list-icons-style-3 li a:first-child > [class*=fa-]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li a:first-child > .icons:first-child {
  width: 20px;
  height: 20px;
  padding: 3px;
}
.list.list-icons.list-icons-lg li {
  padding-top: 5px;
  padding-left: 27px;
}
.list.list-icons.list-icons-lg li > [class*=fa-]:first-child,
.list.list-icons.list-icons-lg li > .icons:first-child, .list.list-icons.list-icons-lg li a:first-child > [class*=fa-]:first-child,
.list.list-icons.list-icons-lg li a:first-child > .icons:first-child {
  font-size: 1.3em;
  top: 10px;
}
.list.list-icons.list-icons-lg.list-icons-style-2 li {
  padding-top: 8px;
  padding-left: 42px;
}
.list.list-icons.list-icons-lg.list-icons-style-2 li > [class*=fa-]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-lg.list-icons-style-2 li a:first-child > [class*=fa-]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li a:first-child > .icons:first-child {
  width: 30px;
  height: 30px;
  padding: 4px;
}
.list.list-icons.list-icons-lg.list-icons-style-3 li {
  padding-top: 8px;
  padding-left: 42px;
}
.list.list-icons.list-icons-lg.list-icons-style-3 li > [class*=fa-]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-lg.list-icons-style-3 li a:first-child > [class*=fa-]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li a:first-child > .icons:first-child {
  width: 30px;
  height: 30px;
  padding: 4px;
}
.list.list-icons.list-icons-reverse li {
  text-align: right;
  padding-right: 25px;
  padding-left: 0;
}
.list.list-icons.list-icons-reverse li > [class*=fa-]:first-child,
.list.list-icons.list-icons-reverse li > .icons:first-child, .list.list-icons.list-icons-reverse li a:first-child > [class*=fa-]:first-child,
.list.list-icons.list-icons-reverse li a:first-child > .icons:first-child {
  position: absolute;
  left: auto;
  right: 0;
  top: 5px;
}
.list.list-icons.list-icons-reverse.list-icons-style-2 li {
  padding-right: 36px;
}
.list.list-icons.list-icons-reverse.list-icons-style-3 li {
  padding-right: 36px;
}
.list.list-icons.list-icons-reverse.list-icons-sm li {
  padding-left: 0;
  padding-right: 13px;
}
.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-2 li {
  padding-right: 32px;
}
.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-3 li {
  padding-right: 32px;
}
.list.list-icons.list-icons-reverse.list-icons-lg li {
  padding-left: 0;
  padding-right: 32px;
}
.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-2 li {
  padding-right: 42px;
}
.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-3 li {
  padding-right: 42px;
}
.list.list-borders li {
  border-bottom: 1px solid #eaeaea;
  margin-top: 9px;
  margin-bottom: 0;
  padding-bottom: 9px;
}
.list.list-borders li:last-child {
  border-bottom: 0;
}
.list.list-side-borders {
  border-left: 1px solid #eaeaea;
  padding-left: 10px;
}
.list.list-ordened {
  counter-reset: custom-counter;
  list-style: none;
}
.list.list-ordened li {
  position: relative;
  padding-left: 22px;
}
.list.list-ordened li:before {
  position: absolute;
  top: 0;
  left: 0;
  content: counter(custom-counter);
  counter-increment: custom-counter;
  font-weight: bold;
}
.list.list-ordened.list-ordened-style-2 li, .list.list-ordened.list-ordened-style-3 li {
  padding-left: 32px;
}
.list.list-ordened.list-ordened-style-2 li:before, .list.list-ordened.list-ordened-style-3 li:before {
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  min-width: 26px;
  padding: 0;
  text-align: center;
}
.list.list-ordened.list-ordened-style-3 li:before {
  color: #FFF;
  border: 0;
}
.list.list-unstyled {
  padding-left: 0;
  padding-right: 0;
}

.list-unstyled {
  padding-left: 0;
  padding-right: 0;
}

dl dd {
  margin-bottom: 15px;
}

/* Sitemap */
ul.sitemap > li > a {
  font-weight: bold;
}
ul.sitemap.list li {
  margin: 0 0 3px 0;
}
ul.sitemap.list ul {
  margin-bottom: 5px;
  margin-top: 5px;
}

/* Clearfix */
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

/* Container */
@media (min-width: 1440px) {
  .container-lg {
    max-width: 1440px;
  }

  .container-xl {
    max-width: 1630px;
  }
}
/* Fluid Col */
[class*=fluid-col-] .fluid-col {
  position: absolute;
  left: 15px;
}
[class*=fluid-col-] .fluid-col.fluid-col-left {
  left: auto;
  right: 15px;
}

.fluid-col-6 {
  min-height: 33vw;
}
.fluid-col-6 .fluid-col {
  width: calc(50vw - 15px);
}

.fluid-col-sm-6 .fluid-col, .fluid-col-md-6 .fluid-col, .fluid-col-lg-6 .fluid-col, .fluid-col-xl-6 .fluid-col {
  width: calc(100vw - 30px);
}

@media (min-width: 576px) {
  .fluid-col-sm-6 {
    min-height: 33vw;
  }
  .fluid-col-sm-6 .fluid-col {
    width: calc(55vw - 15px);
  }
}
@media (min-width: 768px) {
  .fluid-col-md-6 {
    min-height: 33vw;
  }
  .fluid-col-md-6 .fluid-col {
    width: calc(50vw - 15px);
  }
}
@media (min-width: 992px) {
  .fluid-col-lg-6 {
    min-height: 33vw;
  }
  .fluid-col-lg-6 .fluid-col {
    width: calc(50vw - 15px);
  }
}
@media (min-width: 1200px) {
  .fluid-col-xl-6 {
    min-height: 33vw;
  }
  .fluid-col-xl-6 .fluid-col {
    width: calc(50vw - 15px);
  }
}
@media (max-width: 1199px) {
  .fluid-col-xl-6 {
    min-height: 64.5vw !important;
  }
}
@media (max-width: 991px) {
  .fluid-col-lg-6 {
    min-height: 64.5vw !important;
  }
}
@media (max-width: 767px) {
  .fluid-col-md-6 {
    min-height: 64.5vw !important;
  }
}
@media (max-width: 575px) {
  .fluid-col-sm-6 {
    min-height: 64.5vw !important;
  }
}
/* Min Height */
.min-height-screen {
  min-height: 100vh;
}

/* Hide Text */
.hide-text {
  display: block;
  text-indent: -9999px;
  width: 0;
  height: 0;
}

/* Box Shadow */
.box-shadow-1:before {
  display: block;
  position: absolute;
  left: 1%;
  top: 1%;
  height: 98%;
  width: 98%;
  opacity: 0.33;
  content: "";
  box-shadow: 0 30px 90px #BBB;
  transition: all 0.2s ease-in-out;
}
.box-shadow-1.box-shadow-1-hover:before {
  opacity: 0;
}
.box-shadow-1.box-shadow-1-hover:hover:before {
  opacity: 0.33;
}

.box-shadow-2 {
  box-shadow: 0 15px 30px -15px rgba(0, 0, 0, 0.45);
}

.box-shadow-3 {
  box-shadow: 0 30px 60px -30px rgba(0, 0, 0, 0.45);
}

.box-shadow-4 {
  box-shadow: 0px 0px 21px -5px rgba(0, 0, 0, 0.2);
}

/* Border Radius */
.border-radius {
  border-radius: 4px !important;
}

/* Sample Item */
.sample-item-container {
  max-width: 1648px;
}

.sample-item-list {
  list-style: none;
  padding: 0;
  margin: 0;
  opacity: 0;
}
.sample-item-list li {
  text-align: center;
}
.sample-item-list.sample-item-list-loaded {
  opacity: 1;
}

.sample-item {
  text-align: center;
  max-width: 500px;
  width: 100%;
  display: inline-block;
  margin-bottom: 35px;
}
.sample-item a {
  text-decoration: none !important;
}
.sample-item .owl-carousel {
  margin-bottom: 0;
  box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 22.8vw;
  max-height: 365px;
  display: block !important;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}
.sample-item .owl-carousel:hover {
  box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.4);
}
.sample-item .owl-carousel .sample-item-image-wrapper {
  box-shadow: none;
}
.sample-item .owl-carousel .owl-item > div {
  height: 365px;
  max-height: 365px;
}
.sample-item .owl-carousel .owl-nav button.owl-prev,
.sample-item .owl-carousel .owl-nav button.owl-next {
  border: 0 none;
  border-radius: 0 4px 4px 0;
  color: #777;
  height: 50px;
  line-height: 38px;
  margin-top: 0;
  transition: all 0.3s ease;
}
.sample-item .owl-carousel .owl-nav button.owl-prev:hover, .sample-item .owl-carousel .owl-nav button.owl-prev:focus,
.sample-item .owl-carousel .owl-nav button.owl-next:hover,
.sample-item .owl-carousel .owl-nav button.owl-next:focus {
  color: #000 !important;
}
.sample-item .owl-carousel .owl-nav button.owl-prev:before,
.sample-item .owl-carousel .owl-nav button.owl-next:before {
  font-size: 11px;
}
.sample-item .owl-carousel .owl-nav button.owl-prev {
  background-color: #E7E7E7;
  transform: translate3d(-30px, 0, 0);
}
.sample-item .owl-carousel .owl-nav button.owl-prev:hover, .sample-item .owl-carousel .owl-nav button.owl-prev:focus {
  background-color: #E7E7E7 !important;
}
.sample-item .owl-carousel .owl-nav button.owl-next {
  background-color: #E7E7E7;
  transform: translate3d(30px, 0, 0);
  border-radius: 4px 0 0 4px;
}
.sample-item .owl-carousel .owl-nav button.owl-next:hover, .sample-item .owl-carousel .owl-nav button.owl-next:focus {
  background-color: #E7E7E7 !important;
}
.sample-item .owl-carousel:hover .owl-nav button.owl-prev {
  transform: translate3d(0, 0, 0);
}
.sample-item .owl-carousel:hover .owl-nav button.owl-next {
  transform: translate3d(0, 0, 0);
}
.sample-item .sample-item-image-wrapper {
  background: #FFF;
  height: 22vw;
  max-height: 365px;
  max-width: 500px;
  width: 100%;
  display: inline-block;
  border: none;
  box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: box-shadow 0.3s ease;
}
.sample-item .sample-item-image-wrapper .fa-spin {
  background: transparent none repeat scroll 0 0;
  color: #ccc;
  font-size: 25px;
  left: 50%;
  margin: -13px;
  position: absolute;
  top: 50%;
  z-index: 1;
}
.sample-item a:hover .sample-item-image-wrapper {
  box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.4);
}
.sample-item .sample-item-image {
  height: 22.8vw;
  max-height: 365px;
  max-width: 500px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: 2;
}
.sample-item .sample-item-description {
  display: block;
  padding: 15px 0;
}
.sample-item h5, .sample-item .h5 {
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.sample-item h5 .sample-item-info, .sample-item .h5 .sample-item-info {
  display: block;
  font-size: 11px;
  opacity: 0.6;
}
.sample-item p {
  padding: 0;
  margin: 0;
  font-size: 12px;
}
.sample-item.sample-item-home .sample-item-image-wrapper {
  height: 13.9vw;
}
.sample-item.sample-item-home .sample-item-image-wrapper,
.sample-item.sample-item-home .sample-item-image {
  max-height: 260px;
}
.sample-item.sample-item-coming-soon .sample-item-image {
  background-color: #FBFBFB;
  background-position: center center;
}
.sample-item.sample-item-coming-soon.sample-item-home .sample-item-image {
  height: 15vw;
}

@media (max-width: 991px) {
  .sample-item .sample-item-image-wrapper, .sample-item .owl-carousel {
    height: 33vw;
  }
  .sample-item .sample-item-image {
    height: 34vw;
  }
  .sample-item.sample-item-home .sample-item-image-wrapper {
    height: 30vw;
  }
  .sample-item.sample-item-home .sample-item-image {
    height: 30vw;
  }
  .sample-item.sample-item-coming-soon.sample-item-home .sample-item-image {
    height: 30vw;
  }
}
@media (max-width: 767px) {
  .sample-item .sample-item-image-wrapper, .sample-item .owl-carousel {
    height: 63.4vw;
  }
  .sample-item .sample-item-image {
    height: 67.5vw;
  }
  .sample-item.sample-item-home .sample-item-image-wrapper {
    height: 63.4vw;
    max-height: 345px;
  }
  .sample-item.sample-item-home .sample-item-image {
    height: 67.5vw;
    max-height: 345px;
  }
  .sample-item.sample-item-coming-soon.sample-item-home .sample-item-image {
    height: 63.4vw;
  }
}
/* Sample Sub Items Lightbox */
.sample-sub-items-lightbox {
  margin: 0 auto;
  max-width: 1650px;
  padding: 10vh 10px 50px;
  width: 100%;
}

/* Magnific Popup Demo Options */
.mfp-demo-options.mfp-bg {
  opacity: 0.98 !important;
  background: #f7f7f7;
}
.mfp-demo-options.mfp-bg.mfp-removing {
  opacity: 0 !important;
}
.mfp-demo-options .mfp-close {
  position: fixed;
  top: 0;
  right: 17px;
  z-index: 3000;
}
.mfp-demo-options.mfp-wrap {
  height: 100vh !important;
}
.mfp-demo-options.mfp-wrap:before {
  background: linear-gradient(to bottom, #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 2000;
  opacity: 1;
  opacity: 0.7;
}
.mfp-demo-options.mfp-wrap:after {
  background: linear-gradient(to top, #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
  content: "";
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 2000;
  opacity: 1;
  opacity: 0.7;
}
.mfp-demo-options.mfp-wrap.mfp-removing:before, .mfp-demo-options.mfp-wrap.mfp-removing:after {
  opacity: 0 !important;
}

/* Pagination */
.pagination {
  position: relative;
  z-index: 1;
}
.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #0E2D52;
  font-size: 1.4em;
  font-weight: 600;
  border: none !important;
  box-shadow: none !important;
  border-radius: 4px;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #777;
  border: 2px solid #777;
  box-shadow: none !important;
}
.pagination .page-link {
  border: 2px solid #777;
  border-radius: 50% !important;
  padding: 0 !important;
  width: 24px;
  height: 24px;
  line-height: 22px;
  text-align: center;
}
.pagination .page-link i {
  font-size: 16px;
}
.pagination .page-item {
  margin: 0 10px;
}
.pagination .page-item.active .page-link {
  z-index: 3;
  color: #0E2D52;
  background-color: #FCAC2F;
  border: 2px solid #FCAC2F;
}
.pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
  color: #fff;
  background-color: #FCAC2F;
  border: 2px solid #FCAC2F;
}

/* Read More */
.read-more,
.learn-more {
  display: inline-block;
  white-space: nowrap;
}

.learn-more-hover-animate-icon > i {
  transition: ease transform 300ms;
}
.learn-more-hover-animate-icon:hover > i {
  transform: translate3d(5px, 0, 0);
}

/* Offset Anchor */
.offset-anchor {
  display: block;
  position: relative;
  visibility: hidden;
  top: -100px;
}

/* Responsive */
@media (max-width: 991px) {
  .offset-anchor {
    top: 0;
  }
}
/* Show Grid */
.show-grid [class*=col] .show-grid-block {
  background-color: #EEE;
  line-height: 40px;
  min-height: 40px;
  text-align: center;
  border: 1px solid #FFF;
  display: block;
}

/* Alerts */
.alert [class*=fa-], .alert .icons {
  margin-right: 8px;
  font-size: 1.1em;
}
.alert ul {
  padding: 0;
  margin: 7px 0 0 40px;
}
.alert ul li {
  font-size: 0.9em;
}
.alert.alert-sm {
  padding: 5px 10px;
  font-size: 0.9em;
}
.alert.alert-lg {
  padding: 20px;
  font-size: 1.2em;
}
.alert.alert-default {
  background-color: #f2f2f2;
  border-color: #eaeaea;
  color: #737373;
}
.alert.alert-default .alert-link {
  color: #4c4c4c;
}
.alert.alert-dark {
  background-color: #4d4d4d;
  border-color: #1a1a1a;
  color: #e6e6e6;
}
.alert.alert-dark .alert-link {
  color: white;
}

/* Embed Responsive */
.embed-responsive.embed-soundcloud {
  padding-bottom: 19.25%;
}
.embed-responsive.embed-responsive-borders {
  border: 1px solid #DDD;
  padding: 4px;
  border-radius: 4px;
}

@media (max-width: 991px) {
  .col-md-3 .sidebar {
    clear: both;
  }
}
/* Half Section */
.col-half-section {
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  max-width: 555px;
  margin-left: 30px;
  margin-right: 0;
}
.col-half-section.col-half-section-right {
  margin-left: 0;
  margin-right: 30px;
}

@media (max-width: 1199px) {
  .col-half-section {
    max-width: 465px;
  }
}
@media (max-width: 991px) {
  .col-half-section {
    max-width: 720px;
    margin: 0 auto !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
@media (max-width: 767px) {
  .col-half-section {
    max-width: 540px;
  }
}
@media (max-width: 575px) {
  .col-half-section {
    max-width: 100%;
  }
}
/* Content Grid */
.content-grid {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.content-grid .content-grid-item {
  position: relative;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-grid .content-grid-item:before, .content-grid .content-grid-item:after {
  content: "";
  position: absolute;
}
.content-grid .content-grid-item:before {
  height: 100%;
  top: 0;
  left: -1px;
  border-left: 1px solid #DADADA;
}
.content-grid .content-grid-item:after {
  width: 100%;
  height: 0;
  top: auto;
  left: 0;
  bottom: -1px;
  border-bottom: 1px solid #DADADA;
}
.content-grid .content-grid-item .img-fluid {
  display: inline-block;
  flex: 0 0 auto;
}
.content-grid.content-grid-dashed .content-grid-item:before {
  border-left: 1px dashed #DADADA;
}
.content-grid.content-grid-dashed .content-grid-item:after {
  border-bottom: 1px dashed #DADADA;
}

/* Alert Admin */
.alert-admin {
  margin: 25px 0;
}
.alert-admin img {
  margin: -50px 0 0;
}
.alert-admin .btn {
  margin: 0 0 -15px;
}
.alert-admin p {
  color: #444;
}
.alert-admin h4, .alert-admin .h4 {
  color: #111;
  font-size: 1.2em;
  font-weight: 600;
  text-transform: uppercase;
}
.alert-admin .warning {
  color: #B20000;
}

/* Not Included */
.not-included {
  color: #b7b7b7;
  display: block;
  font-size: 0.8em;
  font-style: normal;
  margin: -4px 0;
  padding: 0;
}

/* Tip */
.tip {
  display: inline-block;
  padding: 0 5px;
  background: #FFF;
  color: #111;
  text-shadow: none;
  border-radius: 3px;
  margin-left: 8px;
  position: relative;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}
.tip:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
  border-width: 5px;
  margin-top: -5px;
}
.tip.skin {
  color: #FFF;
}

/* Ajax Box */
.ajax-box {
  transition: all 0.2s;
  position: relative;
}
.ajax-box .ajax-box-content {
  transition: all 0.2s;
  opacity: 1;
  overflow: hidden;
  height: auto;
}
.ajax-box .bounce-loader {
  opacity: 0;
}
.ajax-box.ajax-box-init {
  height: 0;
}
.ajax-box.ajax-box-loading {
  height: 300px;
}
.ajax-box.ajax-box-loading .ajax-box-content {
  opacity: 0;
  height: 300px;
}
.ajax-box.ajax-box-loading .bounce-loader {
  opacity: 1;
}

/* Go to Demos */
.go-to-demos {
  transition: padding 0.3s ease;
  background-color: #EEE;
  border-radius: 0 6px 6px 0;
  color: #888 !important;
  display: block;
  font-size: 10px;
  height: 40px;
  left: 0;
  line-height: 40px;
  padding: 0 15px 0 10px;
  position: fixed;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  top: 190px;
  width: auto;
  z-index: 999;
}
.go-to-demos [class*=fa-] {
  transition: left 0.3s ease;
  display: inline-block;
  padding-right: 5px;
  position: relative;
  left: 0;
}
.go-to-demos:hover {
  text-decoration: none;
  padding-left: 25px;
}
.go-to-demos:hover [class*=fa-] {
  left: -7px;
}

@media (max-width: 991px) {
  .go-to-demos {
    display: none;
  }
}
/* Notice Top Bar */
.notice-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #d01a34;
  z-index: -1;
}
.notice-top-bar .notice-button {
  background: rgba(235, 34, 63, 0.7);
  color: #FFF;
  border-radius: 35px;
  transition: ease background 300ms;
  text-decoration: none;
}
.notice-top-bar .notice-button:hover {
  background: #eb223f;
}

/* Hover effetcs */
.hover-effect-1:not(.portfolio-item) {
  box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0);
  transition: ease transform 300ms, ease box-shadow 300ms;
}
.hover-effect-1:not(.portfolio-item):hover {
  box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.4);
  transform: translate3d(0, -15px, 0);
}
.hover-effect-1.portfolio-item {
  transition: ease transform 300ms;
}
.hover-effect-1.portfolio-item .thumb-info {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
  transition: ease box-shadow 300ms;
}
.hover-effect-1.portfolio-item:hover .thumb-info, .hover-effect-1.portfolio-item.show .thumb-info {
  box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.4);
}
.hover-effect-1 a {
  transition: ease color 300ms;
}

.hover-effect-2 {
  opacity: 1;
  transition: ease opacity 300ms;
}
.hover-effect-2:hover {
  opacity: 0.85;
}

.hover-effect-3 {
  opacity: 0.3;
  transition: ease opacity 300ms;
}
.hover-effect-3:hover {
  opacity: 1;
}

/* Border Radius */
.border-radius-0 {
  border-radius: 0 !important;
}

/* Sticky Wrapper */
.sticky-wrapper {
  position: fixed;
  width: 100% !important;
  z-index: 11;
}
.sticky-wrapper .sticky-body {
  display: flex;
  background: #FFF;
  width: 100%;
  z-index: 10;
  transition: ease background 300ms;
}
.sticky-wrapper.sticky-wrapper-transparent .sticky-body {
  background: transparent;
}
.sticky-wrapper.sticky-wrapper-effect-1 {
  position: absolute !important;
}
.sticky-wrapper.sticky-wrapper-effect-1.sticky-effect-active .sticky-body {
  position: fixed;
  background: #FFF;
  animation: headerReveal 0.4s ease-in;
}
.sticky-wrapper.sticky-wrapper-effect-1.sticky-wrapper-effect-1-dark.sticky-effect-active .sticky-body {
  background: #333;
}
.sticky-wrapper.sticky-wrapper-border-bottom.sticky-effect-active .sticky-body {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

/* Highlighted Word */
@keyframes pencilAnimation {
  from {
    width: 0;
  }
  to {
    width: 74px;
  }
}
@keyframes pencilAnimation2 {
  from {
    width: 0;
  }
  to {
    width: 115px;
  }
}
.highlighted-word {
  font-family: "Shadows Into Light", cursive;
  font-weight: 500;
}
.highlighted-word.highlighted-word-animation-1 {
  position: relative;
}
.highlighted-word.highlighted-word-animation-1:after {
  content: "";
  position: absolute;
  right: calc(100% - 74px);
  bottom: -6px;
  width: 0;
  height: 15px;
  animation-name: pencilAnimation;
  animation-duration: 500ms;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  overflow: hidden;
  transform: rotate(180deg);
}
.highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-light:after {
  filter: brightness(0) invert(1);
}
.highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-no-rotate:after {
  transform: rotate(0);
}
.highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-2:after {
  animation-name: pencilAnimation2;
  right: calc(100% - 115px);
  background-repeat: no-repeat;
}
.highlighted-word.highlighted-word-rotate {
  transform: rotate(-15deg);
  transform-origin: 0 0;
}

/* Curved Border */
.curved-border {
  position: relative;
  min-height: 7.2vw;
}
.curved-border:after {
  content: "";
  background-size: 100% 100%;
  background-position: center;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100vw;
  height: 7.2vw;
  z-index: 20;
}
.curved-border.curved-border-top:after {
  top: -2px;
  bottom: auto;
  transform: rotateX(180deg);
}

/* Vertical Divider */
.vertical-divider {
  display: inline;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid transparent;
}

/* Star Rating */
.rating-container .filled-stars {
  text-shadow: none !important;
}

.rating-invisible {
  height: 27px;
  visibility: hidden !important;
}

/* Image Hotspots */
.image-hotspots {
  position: relative;
}

.image-hotspot {
  cursor: pointer;
  position: absolute;
}
.image-hotspot strong {
  color: #FFF;
  z-index: 5;
  font-size: 0.75em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-weight: 600 !important;
}
.image-hotspot .circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 28px;
  height: 28px;
  margin: -0.666em auto auto -0.666em;
  background: #CCC;
  border-radius: 50%;
  opacity: 0.6;
  transform-origin: 50% 50%;
  transition: opacity 0.2s ease-in, transform 0.1s ease-out;
  color: white;
  font-size: 1.5em;
  padding: 0;
  text-align: center;
  line-height: 28px;
  overflow: hidden;
}
.image-hotspot .ring {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 2em;
  margin: -1em auto auto -1em;
  transform-origin: 50% 50%;
  border-radius: 50%;
  border: 1px solid rgba(204, 204, 204, 0.9);
  opacity: 0;
  animation: hotspot-pulsate 3s ease-out infinite;
}

/* Page Transition */
body[data-plugin-page-transition] {
  transition: ease opacity 300ms;
}
body.page-transition-active {
  opacity: 0 !important;
}

/*
Image 360º Viewer
Credits: Codyhouse (https://codyhouse.co/gem/360-degrees-product-viewer)
*/
.cd-product-viewer-wrapper {
  text-align: center;
  padding: 2em 0;
}
.cd-product-viewer-wrapper > div {
  display: inline-block;
}
@media (max-width: 1199px) {
  .cd-product-viewer-wrapper > div {
    width: 100% !important;
  }
}
.cd-product-viewer-wrapper .product-viewer {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .cd-product-viewer-wrapper .product-viewer {
    width: 100% !important;
  }
}
.cd-product-viewer-wrapper img {
  display: block;
  position: relative;
  width: 100%;
  z-index: 1;
}
.cd-product-viewer-wrapper .product-sprite {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 1600%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
  transition: ease opacity 300ms;
}
.cd-product-viewer-wrapper.loaded .product-sprite {
  opacity: 1;
  cursor: ew-resize;
}

.cd-product-viewer-handle {
  position: relative;
  z-index: 2;
  width: 60%;
  max-width: 300px;
  border-radius: 50em;
  margin: 1em auto 3em;
  height: 4px;
}
.cd-product-viewer-handle .fill {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  background: #333;
  transform: scaleX(0);
  transform-origin: left center;
  transition: ease transform 500ms;
}
.no-csstransitions .cd-product-viewer-handle .fill {
  display: none;
}
.loaded .cd-product-viewer-handle .fill {
  opacity: 1;
  background-image: none !important;
  transition: ease transform 300ms, ease opacity 200ms 300ms;
}
.cd-product-viewer-handle .handle {
  position: absolute;
  z-index: 2;
  display: inline-block;
  height: 44px;
  width: 44px;
  left: 0;
  top: -20px;
  background: #333;
  border-radius: 50%;
  border: 2px solid #333;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: transparent;
  transform: translateX(-50%) scale(0);
  transition: ease box-shadow 200ms;
}
.cd-product-viewer-handle .handle .bars {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 20px;
  background: #FFF;
  transform: translate3d(-50%, -50%, 0);
}
.cd-product-viewer-handle .handle .bars:before, .cd-product-viewer-handle .handle .bars:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 4px;
  height: 60%;
  background: #FFF;
  transform: translate3d(0, -50%, 0);
}
.cd-product-viewer-handle .handle .bars:before {
  left: -8px;
}
.cd-product-viewer-handle .handle .bars:after {
  right: -8px;
}
.loaded .cd-product-viewer-handle .handle {
  transform: translateX(-50%) scale(1);
  animation: cd-bounce 300ms 300ms;
  animation-fill-mode: both;
  cursor: ew-resize;
}

@keyframes cd-bounce {
  0% {
    transform: translateX(-50%) scale(0);
  }
  60% {
    transform: translateX(-50%) scale(1.1);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}
/* Recommended Themes */
.recommend-themes {
  background: #000;
  padding: 15px 0;
  position: fixed;
  z-index: 9;
  left: 0;
  width: 100%;
  bottom: 0;
  box-shadow: 0 -3px 1px 0 rgba(0, 0, 0, 0.3);
  transform: translateY(110%);
  transition: transform 0.8s ease;
}
.recommend-themes.active {
  transform: translateY(0);
}
.recommend-themes p {
  padding-right: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #777;
  margin: 0 4px;
}
.recommend-themes .btn {
  background-color: #181818;
  font-size: 12px;
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  border-radius: 40px;
  margin: 0 4px;
}
.recommend-themes .btn:hover {
  background-color: #242424;
}

/* Video Open Icon */
.video-open-icon {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  padding: 0.375rem;
  transition: padding 0.2s;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.07);
  position: relative;
  vertical-align: middle;
  margin-right: 0.5rem;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.04);
}
.video-open-icon:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  background: #fff;
}
.video-open-icon:after {
  content: "";
  border-left: 12px solid #08c;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -5px;
}
.video-open-icon:hover {
  padding: 0.25rem;
}

/* Landing Page Text Reasons */
.text-reasons {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transform: rotate(-10deg);
}
.text-reasons h3, .text-reasons .h3 {
  padding: 0.75rem 3.5rem;
  text-transform: uppercase;
  border-radius: 4px;
  margin-bottom: 0.4rem;
  font-weight: 800;
}
.text-reasons h3:nth-child(2), .text-reasons .h3:nth-child(2) {
  background: rgba(25, 25, 25, 0.15);
  margin-left: -10%;
}
.text-reasons h3:nth-child(3), .text-reasons .h3:nth-child(3) {
  background: rgba(25, 25, 25, 0.57);
  font-size: 1.75rem;
  line-height: 1.5;
  padding-left: 5rem;
  margin-left: 2rem;
}
.text-reasons h3:nth-child(4), .text-reasons .h3:nth-child(4) {
  background: #222529;
  font-size: 2.25rem;
  padding: 0.5rem 1.25rem;
  margin-left: 15%;
}
.text-reasons label {
  font-size: 12.5rem;
  line-height: 0.8;
  font-weight: 800;
  position: absolute;
  left: 0;
  top: 0;
}
.text-reasons .highlighted-word {
  letter-spacing: -0.025em;
}
.text-reasons .highlighted-word:after {
  bottom: -0.33em;
  left: 10%;
  transform: scaleY(-1);
}

/* Strong Shadow */
.strong-shadow {
  position: relative;
  display: inline-block;
}
.strong-shadow:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #494b4e;
  z-index: -1;
}
.strong-shadow.strong-shadow-top-right:before {
  top: -25px;
  left: 25px;
  right: -25px;
  bottom: 25px;
}
.strong-shadow.rounded:before {
  border-radius: 5px;
}

/* SVG Background */
.background-svg-style-1 {
  position: absolute;
  top: 31px;
  width: 100vw;
}

.background-svg-style-1 .dots {
  filter: url(#shadow);
}

.background-svg-style-1 .dots.dots-animation {
  filter: url(#shadow);
}

/* Scroll to Top */
html .scroll-to-top {
  transition: opacity 0.3s;
  background: #FCAC2F;
  border-radius: 4px 4px 0 0;
  bottom: 0;
  color: #0E2D52;
  display: block;
  height: 9px;
  opacity: 0;
  padding: 10px 10px 35px;
  position: fixed;
  right: 10px;
  text-align: center;
  text-decoration: none;
  min-width: 50px;
  z-index: 1040;
  font-size: 0.8em;
}
html .scroll-to-top:hover {
  opacity: 1;
}
html .scroll-to-top.visible {
  opacity: 0.75;
}
html .scroll-to-top span {
  display: inline-block;
  padding: 0 5px;
}
html .scroll-to-top .fa-caret-up {
  font-size: 2em;
}
html.lightbox-opened .scroll-to-top {
  right: 27px;
}
html.ie11 .scroll-to-top {
  right: 25px;
}

/* Responsive */
@media (max-width: 991px) {
  html .scroll-to-top.hidden-mobile {
    display: none !important;
  }
}
/* Section Featured */
section.section {
  margin: 30px 0;
  padding: 50px 0;
}
section.section.section-height-1 {
  padding: 0.7692307692rem 0;
}
section.section.section-height-2 {
  padding: 2.3076923077rem 0;
}
section.section.section-height-3 {
  padding: 4.6153846154rem 0;
}
section.section.section-height-4 {
  padding: 6.9230769231rem 0;
}
section.section.section-height-5 {
  padding: 9.2307692308rem 0;
}
section.section.section-bg-strong-grey {
  background: #EDEDED;
}
section.section.section-center {
  text-align: center;
}
section.section.section-text-light h1, section.section.section-text-light .h1, section.section.section-text-light h2, section.section.section-text-light .h2, section.section.section-text-light h3, section.section.section-text-light .h3, section.section.section-text-light h4, section.section.section-text-light .h4, section.section.section-text-light h5, section.section.section-text-light .h5, section.section.section-text-light h6, section.section.section-text-light .h6 {
  color: #FFF;
}
section.section.section-text-light p {
  color: #e6e6e6;
}
section.section.section-background {
  background-repeat: repeat;
  border: 0;
}
section.section.section-background-change-anim {
  animation-name: colorTransition;
  animation-duration: 25s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
section.section.section-funnel {
  position: relative;
}
section.section.section-funnel .section-funnel-layer-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 6rem 0;
  transform: translate3d(0, -50%, 0);
}
section.section.section-funnel .section-funnel-layer-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 6rem 0;
  transform: translate3d(0, 50%, 0);
}
section.section.section-funnel .section-funnel-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFF;
}
section.section.section-funnel .section-funnel-layer:nth-child(1) {
  transform: skewY(-4.7deg);
}
section.section.section-funnel .section-funnel-layer:nth-child(2) {
  transform: skewY(4.7deg);
}
section.section.section-angled {
  position: relative;
  margin: 0;
  overflow: hidden;
}
section.section.section-angled .section-angled-layer-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3rem 0;
  transform: skewY(-0.7deg) translate3d(0, -50%, 0);
}
section.section.section-angled .section-angled-layer-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 3rem 0;
  transform: skewY(0.7deg) translate3d(0, 50%, 0);
}
section.section.section-angled .section-angled-layer-top + .section-angled-content,
section.section.section-angled .section-angled-layer-top + .section-angled-layer-bottom + .section-angled-content {
  margin-top: 2.5rem;
}
section.section.section-angled .section-angled-layer-bottom + .section-angled-content,
section.section.section-angled .section-angled-layer-bottom + .section-angled-layer-top + .section-angled-content {
  margin-bottom: 2.5rem;
}
section.section.section-angled .section-angled-layer-increase-angle.section-angled-layer-top {
  transform: skewY(-1.7deg) translate3d(0, -50%, 0);
}
section.section.section-angled .section-angled-layer-increase-angle.section-angled-layer-bottom {
  transform: skewY(1.7deg) translate3d(0, 50%, 0);
}
section.section.section-angled .section-angled-layer-increase-angle-2.section-angled-layer-top {
  transform: skewY(-4.7deg) translate3d(0, -50%, 0);
}
section.section.section-angled .section-angled-layer-increase-angle-2.section-angled-layer-bottom {
  transform: skewY(4.7deg) translate3d(0, 50%, 0);
}
section.section.section-angled.section-angled-reverse .section-angled-layer-top {
  transform: skewY(0.7deg) translate3d(0, -50%, 0);
}
section.section.section-angled.section-angled-reverse .section-angled-layer-bottom {
  transform: skewY(-0.7deg) translate3d(0, 50%, 0);
}
section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle.section-angled-layer-top {
  transform: skewY(1.7deg) translate3d(0, -50%, 0);
}
section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle.section-angled-layer-bottom {
  transform: skewY(-1.7deg) translate3d(0, 50%, 0);
}
section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle-2.section-angled-layer-top {
  transform: skewY(4.7deg) translate3d(0, -50%, 0);
}
section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle-2.section-angled-layer-bottom {
  transform: skewY(-4.7deg) translate3d(0, 50%, 0);
}
section.section.section-angled + .section-angled {
  padding-top: 0;
}
section.section.overlay .section-angled-layer-top,
section.section.overlay .section-angled-layer-bottom {
  z-index: 99 !important;
}
section.section.section-overlay {
  position: relative;
}
section.section.section-overlay:before {
  content: "";
  display: block;
  background: rgba(51, 51, 51, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
section.section.section-overlay .section-angled-layer-top,
section.section.section-overlay .section-angled-layer-bottom {
  z-index: 99 !important;
}
section.section.section-overlay-dot:before {
  background: url(../img/overlay-dot.png) repeat scroll 0 0 transparent;
}
section.section.section-overlay-opacity {
  position: relative;
}
section.section.section-overlay-opacity:before {
  content: "";
  display: block;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-1:before {
  background: rgba(51, 51, 51, 0.1);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-2:before {
  background: rgba(51, 51, 51, 0.2);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-3:before {
  background: rgba(51, 51, 51, 0.3);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-4:before {
  background: rgba(51, 51, 51, 0.4);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-5:before {
  background: rgba(51, 51, 51, 0.5);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-6:before {
  background: rgba(51, 51, 51, 0.6);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-7:before {
  background: rgba(51, 51, 51, 0.7);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-8:before {
  background: rgba(51, 51, 51, 0.8);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-9:before {
  background: rgba(51, 51, 51, 0.9);
}
section.section.section-overlay-opacity-light:before {
  background: rgba(255, 255, 255, 0.5);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-1:before {
  background: rgba(255, 255, 255, 0.1);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-2:before {
  background: rgba(255, 255, 255, 0.2);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-3:before {
  background: rgba(255, 255, 255, 0.3);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-4:before {
  background: rgba(255, 255, 255, 0.4);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-5:before {
  background: rgba(255, 255, 255, 0.5);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-6:before {
  background: rgba(255, 255, 255, 0.6);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-7:before {
  background: rgba(255, 255, 255, 0.7);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-8:before {
  background: rgba(255, 255, 255, 0.8);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-9:before {
  background: rgba(255, 255, 255, 0.9);
}
section.section.section-overlay-opacity-gradient:before {
  opacity: 0.85;
}
section.section.section-video {
  background: none !important;
  border: 0;
}
section.section.section-parallax {
  background-color: transparent;
  background-position: 50% 50%;
  background-repeat: repeat;
  background-attachment: fixed;
  border: 0;
}
section.section.section-parallax.parallax-disabled {
  background-attachment: scroll !important;
  background-position: 50% 50% !important;
}
section.section.section-no-border {
  border: 0;
}
section.section.section-with-divider {
  margin: 56px 0 35px;
}
section.section.section-with-divider .divider {
  margin: -56px 0 44px;
}
section.section.section-footer {
  margin-bottom: -50px;
}
section.section.section-with-mockup {
  margin-top: 120px;
}
section.section.section-front {
  position: relative;
  z-index: 1;
}

/* Responsive */
@media (max-width: 991px) {
  .container-fluid > .row > .p-0 section.section, .container-sm > .row > .p-0 section.section, .container-md > .row > .p-0 section.section, .container-lg > .row > .p-0 section.section, .container-xl > .row > .p-0 section.section, .container-xxl > .row > .p-0 section.section {
    margin-bottom: 0;
    margin-top: 0;
  }
  .container-fluid > .row > .p-0:first-child section.section, .container-sm > .row > .p-0:first-child section.section, .container-md > .row > .p-0:first-child section.section, .container-lg > .row > .p-0:first-child section.section, .container-xl > .row > .p-0:first-child section.section, .container-xxl > .row > .p-0:first-child section.section {
    margin-bottom: 0;
    margin-top: 30px;
  }
  .container-fluid > .row > .p-0:last-child section.section, .container-sm > .row > .p-0:last-child section.section, .container-md > .row > .p-0:last-child section.section, .container-lg > .row > .p-0:last-child section.section, .container-xl > .row > .p-0:last-child section.section, .container-xxl > .row > .p-0:last-child section.section {
    margin-bottom: 30px;
    margin-top: 0;
  }
}
/* Side Panel */
html.side-panel .body:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(51, 51, 51, 0.5);
  opacity: 0;
  visibility: hidden;
  z-index: 9998;
  transition: ease opacity 300ms;
}
html.side-panel .side-panel-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 800px;
  height: 100vh;
  background: #FFF;
  padding: 1.5rem;
  box-shadow: 0 0 30px -13px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  transform: translate3d(-100%, 0, 0);
  transition: ease transform 300ms;
  z-index: 9999;
}
html.side-panel .side-panel-wrapper .side-panel-close {
  position: absolute;
  top: 0;
  right: 0;
}
html.side-panel .side-panel-wrapper .post-meta {
  font-style: italic;
  color: #777;
}
html.side-panel .side-panel-wrapper .social-icons {
  display: inline-block;
}
html.side-panel .side-panel-wrapper .social-icons li a {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 0.9em;
}
html.side-panel .side-panel-wrapper .form-control {
  background-color: #F0F0F0;
}
@media (max-width: 1199.98px) {
  html.side-panel .side-panel-wrapper {
    width: 600px;
  }
}
@media (max-width: 767.98px) {
  html.side-panel .side-panel-wrapper {
    width: 500px;
  }
}
@media (max-width: 575.98px) {
  html.side-panel .side-panel-wrapper {
    width: 90%;
  }
}
html.side-panel.side-panel-push .body {
  transform: translate3d(0, 0, 0);
  transition: ease transform 300ms;
}
html.side-panel.side-panel-right .side-panel-wrapper {
  left: auto;
  right: 0;
  transform: translate3d(100%, 0, 0);
}
html.side-panel.side-panel-without-overlay .body:before {
  content: none;
}
html.side-panel.side-panel-open .body:before {
  opacity: 1;
  visibility: visible;
}
html.side-panel.side-panel-open .side-panel-wrapper {
  transform: translate3d(0, 0, 0);
}
html.side-panel.side-panel-open.side-panel-push .body {
  transform: translate3d(300px, 0, 0);
}
html.side-panel.side-panel-open.side-panel-push.side-panel-right .body {
  transform: translate3d(-300px, 0, 0);
}
html.side-panel .hamburguer-btn {
  margin: 5px 10px;
  width: 20px;
  height: 20px;
}

/* Social Icons */
.social-icons {
  margin: 0;
  padding: 0;
  width: auto;
}
.social-icons li {
  display: inline-block;
  margin: 0 5px 0 0;
  padding: 0;
  border-radius: 100%;
  overflow: visible;
}
.social-icons li a {
  transition: all 0.2s ease;
  display: block;
  height: 36px;
  line-height: 36px;
  width: 36px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 0.8rem;
}
.social-icons li:hover a {
  background: #151719;
  color: #FFF;
}
.social-icons li:hover.social-icons-twitter a {
  background: #1aa9e1;
}
.social-icons li:hover.social-icons-facebook a {
  background: #3b5a9a;
}
.social-icons li:hover.social-icons-linkedin a {
  background: #0073b2;
}
.social-icons li:hover.social-icons-rss a {
  background: #ff8201;
}
.social-icons li:hover.social-icons-googleplus a {
  background: #dd4b39;
}
.social-icons li:hover.social-icons-pinterest a {
  background: #cc2127;
}
.social-icons li:hover.social-icons-youtube a {
  background: #c3191e;
}
.social-icons li:hover.social-icons-instagram a {
  background: #bc2a8d;
}
.social-icons li:hover.social-icons-skype a {
  background: #00b0f6;
}
.social-icons li:hover.social-icons-email a {
  background: #dd4b39;
}
.social-icons li:hover.social-icons-vk a {
  background: #6383a8;
}
.social-icons li:hover.social-icons-xing a {
  background: #1a7576;
}
.social-icons li:hover.social-icons-tumblr a {
  background: #304e6c;
}
.social-icons li:hover.social-icons-reddit a {
  background: #ff4107;
}
.social-icons.social-icons-dark li a {
  background: #181818;
}
.social-icons.social-icons-dark li a i {
  color: #FFF;
}
.social-icons.social-icons-dark-2 li a {
  background: #272a2e;
}
.social-icons.social-icons-dark-2 li a i {
  color: #FFF;
}
.social-icons.social-icons-transparent li {
  box-shadow: none;
}
.social-icons.social-icons-transparent li a {
  background: transparent;
}
.social-icons.social-icons-icon-light li a {
  border: 1px solid #0E2D52;
  border-radius: 4px;
}
.social-icons.social-icons-icon-light li a i {
  color: #fff;
}
.social-icons.social-icons-icon-light.social-icons-clean li a i {
  color: #FFF !important;
}
.social-icons.social-icons-big li a {
  height: 48px;
  line-height: 48px;
  width: 48px;
}
.social-icons.social-icons-opacity-light li a {
  background: rgba(255, 255, 255, 0.04);
}

.social-icons:not(.social-icons-clean):not(.social-icons-dark):not(.social-icons-dark-2):not(.social-icons-icon-light) li a {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.social-icons:not(.social-icons-clean):not(.social-icons-dark):not(.social-icons-dark-2):not(.social-icons-icon-light) li a:hover {
  background-color: #333;
  color: #fff !important;
  border-color: #333;
}

.social-icons-dark.social-icons-clean li a,
.social-icons-dark-2.social-icons-clean li a {
  background: transparent;
  color: #FFF !important;
}

/* Tabs */
.tabs {
  border-radius: 4px;
  margin-bottom: 20px;
}

.nav-tabs {
  margin: 0;
  padding: 0;
  border-bottom: 4px solid #eee;
}
.nav-tabs li {
  flex: auto;
}
.nav-tabs li:last-child .nav-link {
  margin-right: 0;
}
.nav-tabs li .nav-link {
  color: #777 !important;
  background-color: #fff;
  border: none !important;
  border-radius: 0;
  font-size: 2.5em;
  transition: all 0.2s;
  padding: 1rem 1.5rem;
  width: 100%;
  margin-right: 5px;
}
.nav-tabs li .nav-link, .nav-tabs li .nav-link:hover {
  background: #fff;
  border-bottom: none;
  border-left: 1px solid #EEE;
  border-right: 1px solid #EEE;
  border-top: 3px solid #EEE;
  color: #CCC;
}
.nav-tabs li .nav-link:hover {
  border-bottom-color: transparent;
  box-shadow: none;
}
.nav-tabs li .nav-link:active, .nav-tabs li .nav-link:focus {
  border-bottom: 0;
}
.nav-tabs li .nav-link.active {
  background: #FFF;
  border-left-color: #EEE;
  border-right-color: #EEE;
  color: #333;
  font-weight: 500;
  text-decoration: underline;
}

.tab-content {
  background-color: #FFF;
  border-top: 0;
  padding: 15px;
}

/* Right Aligned */
.nav-tabs.nav-right {
  text-align: right;
}

/* Bottom Tabs */
.tabs.tabs-bottom {
  margin: 0 0 20px 0;
  padding: 0;
}
.tabs.tabs-bottom .tab-content {
  border-radius: 4px 4px 0 0;
  border-bottom: 0;
  border-top: 1px solid #EEE;
}
.tabs.tabs-bottom .nav-tabs {
  border-bottom: none;
  border-top: 1px solid #EEE;
}
.tabs.tabs-bottom .nav-tabs li {
  margin-bottom: 0;
  margin-top: -1px;
}
.tabs.tabs-bottom .nav-tabs li:last-child .nav-link {
  margin-right: 0;
}
.tabs.tabs-bottom .nav-tabs li .nav-link {
  border-radius: 0 0 5px 5px;
  font-size: 14px;
  margin-right: 1px;
}
.tabs.tabs-bottom .nav-tabs li .nav-link, .tabs.tabs-bottom .nav-tabs li .nav-link:hover {
  border-bottom: 3px solid #EEE;
  border-top: 1px solid #EEE;
}
.tabs.tabs-bottom .nav-tabs li .nav-link:hover {
  border-bottom: 3px solid #CCC;
  border-top: 1px solid #EEE;
}
.tabs.tabs-bottom .nav-tabs li.active .nav-link,
.tabs.tabs-bottom .nav-tabs li.active .nav-link:hover,
.tabs.tabs-bottom .nav-tabs li.active .nav-link:focus {
  border-bottom: 3px solid #CCC;
  border-top-color: transparent !important;
}

/* Vertical */
.tabs-vertical {
  display: table;
  width: 100%;
  padding: 0;
  border-top: 1px solid #EEE;
}
.tabs-vertical .tab-content {
  display: table-cell;
  vertical-align: top;
}
.tabs-vertical .nav-tabs {
  border-bottom: none;
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  vertical-align: top;
  width: 25%;
}
.tabs-vertical .nav-tabs > li {
  display: block;
}
.tabs-vertical .nav-tabs > li .nav-link {
  border-radius: 0;
  display: block;
  padding-top: 10px;
}
.tabs-vertical .nav-tabs > li .nav-link, .tabs-vertical .nav-tabs > li .nav-link:hover, .tabs-vertical .nav-tabs > li .nav-link:focus {
  border-bottom: none;
  border-top: none;
}
.tabs-vertical .nav-tabs > li.active .nav-link,
.tabs-vertical .nav-tabs > li.active .nav-link:hover,
.tabs-vertical .nav-tabs > li.active .nav-link:focus {
  border-top: none;
}

/* Vertical - Left Side */
.tabs-left {
  padding: 0;
}
.tabs-left .tab-content {
  border-radius: 0 5px 5px 5px;
  border-left: none;
}
.tabs-left .nav-tabs > li {
  margin-right: -1px;
}
.tabs-left .nav-tabs > li:first-child .nav-link {
  border-radius: 5px 0 0 0;
}
.tabs-left .nav-tabs > li:last-child .nav-link {
  border-radius: 0 0 0 5px;
  border-bottom: 1px solid #eee;
}
.tabs-left .nav-tabs > li .nav-link {
  border-right: 1px solid #EEE;
  border-left: 3px solid #EEE;
  margin-right: 1px;
  margin-left: -3px;
}
.tabs-left .nav-tabs > li .nav-link:hover {
  border-left-color: #CCC;
}
.tabs-left .nav-tabs > li.active .nav-link,
.tabs-left .nav-tabs > li.active .nav-link:hover,
.tabs-left .nav-tabs > li.active .nav-link:focus {
  border-left: 3px solid #CCC;
  border-right-color: #FFF;
}

/* Vertical - Right Side */
.tabs-right {
  padding: 0;
}
.tabs-right .tab-content {
  border-radius: 5px 0 5px 5px;
  border-right: none;
}
.tabs-right .nav-tabs > li {
  margin-left: -1px;
}
.tabs-right .nav-tabs > li:first-child .nav-link {
  border-radius: 0 5px 0 0;
}
.tabs-right .nav-tabs > li:last-child .nav-link {
  border-radius: 0 0 5px 0;
  border-bottom: 1px solid #eee;
}
.tabs-right .nav-tabs > li .nav-link {
  border-right: 3px solid #EEE;
  border-left: 1px solid #EEE;
  margin-right: 1px;
  margin-left: 1px;
}
.tabs-right .nav-tabs > li .nav-link:hover {
  border-right-color: #CCC;
}
.tabs-right .nav-tabs > li.active .nav-link,
.tabs-right .nav-tabs > li.active .nav-link:hover,
.tabs-right .nav-tabs > li.active .nav-link:focus {
  border-right: 3px solid #CCC;
  border-left: 1px solid #FFF;
}

/* Justified */
.nav-tabs.nav-justified {
  padding: 0;
  margin-bottom: -1px;
}
.nav-tabs.nav-justified li {
  margin-bottom: 0;
}
.nav-tabs.nav-justified li:first-child .nav-link,
.nav-tabs.nav-justified li:first-child .nav-link:hover {
  border-radius: 5px 0 0 0;
}
.nav-tabs.nav-justified li:last-child .nav-link,
.nav-tabs.nav-justified li:last-child .nav-link:hover {
  border-radius: 0 5px 0 0;
}
.nav-tabs.nav-justified li .nav-link {
  border-bottom: 1px solid #DDD;
  border-radius: 0;
  margin-right: 0;
}
.nav-tabs.nav-justified li .nav-link:hover, .nav-tabs.nav-justified li .nav-link:focus {
  border-bottom: 1px solid #DDD;
}
.nav-tabs.nav-justified li.active .nav-link,
.nav-tabs.nav-justified li.active .nav-link:hover,
.nav-tabs.nav-justified li.active .nav-link:focus {
  background: #FFF;
  border-left-color: #EEE;
  border-right-color: #EEE;
}
.nav-tabs.nav-justified li.active .nav-link {
  border-bottom: 1px solid #FFF;
}
.nav-tabs.nav-justified li.active .nav-link, .nav-tabs.nav-justified li.active .nav-link:hover, .nav-tabs.nav-justified li.active .nav-link:focus {
  border-top-width: 3px;
}
.nav-tabs.nav-justified li.active .nav-link:hover {
  border-bottom: 1px solid #FFF;
}

/* Bottom Tabs with Justified Nav */
.tabs.tabs-bottom {
  padding: 0;
}
.tabs.tabs-bottom .nav.nav-tabs.nav-justified {
  border-top: none;
}
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li .nav-link {
  margin-right: 0;
  border-top-color: transparent;
}
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link {
  border-radius: 0 0 0 5px;
}
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link {
  margin-right: 0;
  border-radius: 0 0 5px 0;
}
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active .nav-link,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active .nav-link:hover,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active .nav-link:focus {
  border-top-color: transparent;
}

/* Center */
.tabs-center .nav-tabs {
  margin: 0 auto;
  text-align: center;
}

/* Navigation */
.tabs-navigation {
  border-radius: 5px;
}
.tabs-navigation .nav-tabs > li {
  margin-bottom: 1px;
}
.tabs-navigation .nav-tabs > li .nav-link {
  color: #777;
  padding: 18px;
  line-height: 100%;
  position: relative;
}
.tabs-navigation .nav-tabs > li .nav-link:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  content: "\f054";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.tabs-navigation .nav-tabs > li .nav-link [class*=fa-], .tabs-navigation .nav-tabs > li .nav-link .icons {
  margin-right: 4px;
}
.tabs-navigation .nav-tabs li:first-child .nav-link {
  border-radius: 5px 5px 0 0;
}
.tabs-navigation .nav-tabs li.active .nav-link, .tabs-navigation .nav-tabs li.active .nav-link:hover, .tabs-navigation .nav-tabs li.active .nav-link:focus {
  border-right-color: #eee;
}
.tabs-navigation .nav-tabs li:last-child .nav-link {
  border-radius: 0 0 5px 5px;
}

.tabs-navigation-simple {
  border: 0 !important;
  border-radius: 0 !important;
}
.tabs-navigation-simple .nav-tabs {
  border: 0 !important;
  border-radius: 0 !important;
}
.tabs-navigation-simple .nav-tabs .nav-link, .tabs-navigation-simple .nav-tabs .nav-link:hover {
  background: transparent !important;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
  border-left: 0 !important;
  padding-left: 0 !important;
}
.tabs-navigation-simple .nav-tabs li:last-child .nav-link, .tabs-navigation-simple .nav-tabs li:last-child .nav-link:hover {
  border-bottom: 0 !important;
}

.tab-pane-navigation {
  display: none;
}
.tab-pane-navigation.active {
  display: block;
}

/* Simple */
.tabs.tabs-simple .nav-tabs {
  justify-content: center;
  border: 0;
  margin-bottom: 10px;
}
.tabs.tabs-simple .nav-tabs > li {
  margin-left: 0;
  margin-bottom: 20px;
}
.tabs.tabs-simple .nav-tabs > li .nav-link, .tabs.tabs-simple .nav-tabs > li .nav-link:hover, .tabs.tabs-simple .nav-tabs > li .nav-link:focus {
  padding: 15px 30px;
  background: none;
  border: 0;
  border-bottom: 3px solid #eee;
  border-radius: 0;
  color: #777;
  font-size: 16px;
}
.tabs.tabs-simple .tab-content {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

/* Responsive */
@media (max-width: 554px) {
  .nav-tabs li .nav-link {
    font-size: 1em;
    padding: 0.5rem 1rem;
  }
  .nav-tabs li .btn {
    padding: 0.35rem 0.6rem;
  }

  .tabs .nav.nav-tabs.nav-justified li {
    display: block;
    margin-bottom: -5px;
  }
  .tabs .nav.nav-tabs.nav-justified li .nav-link {
    border-bottom-width: 0 !important;
  }
  .tabs .nav.nav-tabs.nav-justified li:first-child .nav-link, .tabs .nav.nav-tabs.nav-justified li:first-child .nav-link:hover {
    border-radius: 5px 5px 0 0;
  }
  .tabs .nav.nav-tabs.nav-justified li:last-child .nav-link, .tabs .nav.nav-tabs.nav-justified li:last-child .nav-link:hover {
    border-radius: 0;
  }
  .tabs.tabs-bottom .nav.nav-tabs.nav-justified li {
    margin-bottom: 0;
    margin-top: -5px;
  }
  .tabs.tabs-bottom .nav.nav-tabs.nav-justified li .nav-link {
    border-bottom-width: 3px !important;
    border-top-width: 0 !important;
  }
  .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link, .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link:hover {
    border-radius: 0;
  }
  .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link, .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link:hover {
    border-radius: 0 0 5px 5px;
  }
}
.responsive-tabs-container .tab-pane {
  margin-bottom: 15px;
}
.responsive-tabs-container .accordion-link {
  display: none;
  margin-bottom: 10px;
  padding: 10px 15px;
  background-color: #f7f7f7;
  border-radius: 3px;
  border: 1px solid #ebebeb;
}
@media (max-width: 767px) {
  .responsive-tabs-container.accordion-xs .nav-tabs {
    display: none;
  }
  .responsive-tabs-container.accordion-xs .accordion-link {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .responsive-tabs-container.accordion-sm .nav-tabs {
    display: none;
  }
  .responsive-tabs-container.accordion-sm .accordion-link {
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .responsive-tabs-container.accordion-md .nav-tabs {
    display: none;
  }
  .responsive-tabs-container.accordion-md .accordion-link {
    display: block;
  }
}
@media (min-width: 1200px) {
  .responsive-tabs-container.accordion-lg .nav-tabs {
    display: none;
  }
  .responsive-tabs-container.accordion-lg .accordion-link {
    display: block;
  }
}

/* Testimonials */
.testimonial {
  margin-bottom: 20px;
}
.testimonial blockquote {
  background: #CCC;
  border-radius: 5px;
  border: 0;
  color: #666;
  margin: 0;
  padding: 2.5rem 4rem 2.5rem 4.5rem;
  position: relative;
}
.testimonial blockquote:before {
  left: 22px;
  top: 21px;
  color: #FFF;
  content: "“";
  font-size: 85px;
  font-style: normal;
  line-height: 1;
  position: absolute;
}
.testimonial blockquote p {
  color: #FFF;
  font-size: 1.2em;
}
.testimonial .testimonial-arrow-down {
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 8px solid #CCC;
  height: 0;
  margin: 0 0 0 45px;
  width: 0;
}
.testimonial .testimonial-author {
  display: flex;
  margin: 12px 0 0 0;
}
.testimonial .testimonial-author .testimonial-author-thumbnail {
  width: auto;
}
.testimonial .testimonial-author img {
  max-width: 25px;
}
.testimonial .testimonial-author strong {
  color: #0E2D52;
  display: block;
  margin-bottom: 2px;
  font-size: 1rem;
}
.testimonial .testimonial-author span {
  color: #0E2D52;
  display: block;
  font-size: 0.8rem;
  font-weight: 600;
}
.testimonial .testimonial-author p {
  color: #0E2D52;
  text-align: left;
  line-height: 1.2;
  margin-left: 0.6rem;
}

/* Style 2 */
.testimonial.testimonial-style-2 {
  text-align: center;
}
.testimonial.testimonial-style-2 blockquote {
  background: transparent !important;
  border-radius: 4px;
  padding: 22px 20px;
}
.testimonial.testimonial-style-2 blockquote:before, .testimonial.testimonial-style-2 blockquote:after {
  display: none;
}
.testimonial.testimonial-style-2 blockquote p {
  color: #0E2D52;
}
.testimonial.testimonial-style-2 .testimonial-arrow-down {
  display: none;
}
.testimonial.testimonial-style-2 .testimonial-author {
  padding: 0;
  margin: 6px 0 0;
  flex-direction: column;
}
.testimonial.testimonial-style-2 .testimonial-author img {
  margin: 0 auto 15px;
  max-width: 120px;
  max-height: 120px;
}
.testimonial.testimonial-style-2 .testimonial-author p {
  text-align: center;
  padding: 0;
  margin: 0;
}

/* Style 3 */
.testimonial.testimonial-style-3 blockquote {
  background: #f2f2f2 !important;
  border-radius: 4px;
  padding: 12px 30px;
}
.testimonial.testimonial-style-3 blockquote:before {
  top: 5px;
  left: 9px;
  color: #333;
  font-size: 35px;
}
.testimonial.testimonial-style-3 blockquote:after {
  color: #333;
  font-size: 35px;
  bottom: -5px;
}
.testimonial.testimonial-style-3 blockquote p {
  color: #333;
  font-size: 1em;
  font-style: normal;
}
.testimonial.testimonial-style-3 .testimonial-author {
  margin-left: 8px;
  align-items: center;
}
.testimonial.testimonial-style-3 .testimonial-author img {
  max-width: 55px;
}
.testimonial.testimonial-style-3 .testimonial-author p {
  margin-bottom: 0;
}
.testimonial.testimonial-style-3 .testimonial-arrow-down {
  margin-left: 20px;
  border-top: 10px solid #f2f2f2 !important;
}

/* Style 4 */
.testimonial.testimonial-style-4 {
  border-radius: 6px;
  padding: 8px;
  border-top: 1px solid #DFDFDF;
  border-bottom: 1px solid #DFDFDF;
  border-left: 1px solid #ECECEC;
  border-right: 1px solid #ECECEC;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
}
.testimonial.testimonial-style-4 blockquote {
  background: transparent !important;
  border-radius: 4px;
  padding: 12px 30px;
}
.testimonial.testimonial-style-4 blockquote:before {
  top: 5px;
  left: 9px;
  color: #333;
  font-size: 35px;
}
.testimonial.testimonial-style-4 blockquote:after {
  color: #333;
  font-size: 35px;
  bottom: -5px;
}
.testimonial.testimonial-style-4 blockquote p {
  color: #333;
  font-size: 1em;
  font-style: normal;
}
.testimonial.testimonial-style-4 .testimonial-arrow-down {
  display: none;
}
.testimonial.testimonial-style-4 .testimonial-author {
  margin-left: 30px;
  align-items: center;
}
.testimonial.testimonial-style-4 .testimonial-author img {
  max-width: 55px;
}
.testimonial.testimonial-style-4 .testimonial-author p {
  margin-bottom: 0;
}
.testimonial.testimonial-style-4.testimonial-no-borders {
  border: 0;
  box-shadow: none;
}

/* Style 5 */
.testimonial.testimonial-style-5 {
  text-align: center;
}
.testimonial.testimonial-style-5 blockquote {
  background: transparent !important;
  border-radius: 4px;
  padding: 6px 20px;
}
.testimonial.testimonial-style-5 blockquote:before, .testimonial.testimonial-style-5 blockquote:after {
  display: none;
}
.testimonial.testimonial-style-5 blockquote p {
  color: #333;
  font-size: 1.2em;
  font-style: normal;
}
.testimonial.testimonial-style-5 .testimonial-arrow-down {
  display: none;
}
.testimonial.testimonial-style-5 .testimonial-author {
  display: block;
  padding: 6px 0 0;
  margin: 12px 0 0;
  border-top: 1px solid #f2f2f2;
  justify-content: center;
}
.testimonial.testimonial-style-5 .testimonial-author img {
  margin: 0 auto 10px;
  max-width: 55px;
}
.testimonial.testimonial-style-5 .testimonial-author p {
  text-align: center;
  padding: 0;
  margin: 0;
}

/* Style 6 */
.testimonial.testimonial-style-6 {
  text-align: center;
}
.testimonial.testimonial-style-6 blockquote {
  background: transparent !important;
  border-radius: 4px;
  border: 0;
  padding: 0 10%;
}
.testimonial.testimonial-style-6 blockquote:before, .testimonial.testimonial-style-6 blockquote:after {
  display: none;
}
.testimonial.testimonial-style-6 blockquote i.fa-quote-left {
  font-size: 34px;
  position: relative;
  left: -5px;
}
.testimonial.testimonial-style-6 blockquote p {
  color: #333;
  font-size: 24px;
  line-height: 30px;
}
.testimonial.testimonial-style-6 blockquote span {
  font-size: 16px;
  line-height: 20px;
  color: #999;
  position: relative;
  left: -5px;
}
.testimonial.testimonial-style-6 .testimonial-arrow-down {
  display: none;
}
.testimonial.testimonial-style-6 .testimonial-author {
  padding: 0;
  margin: 6px 0 0;
  justify-content: center;
}
.testimonial.testimonial-style-6 .testimonial-author img {
  margin: 0 auto 10px;
}
.testimonial.testimonial-style-6 .testimonial-author p {
  text-align: center;
  padding: 0;
  margin: 0;
}

/* Style 7 */
.testimonial.testimonial-style-7 {
  text-align: center;
  background-color: #CCC;
  padding: 20px;
}
.testimonial.testimonial-style-7 blockquote {
  padding: 30px;
}
.testimonial.testimonial-style-7 blockquote:before {
  color: #FFF;
  display: block !important;
  left: 50%;
  top: -10px;
  font-size: 80px;
  font-style: normal;
  line-height: 1;
  position: absolute;
  transform: translate3d(-50%, 0, 0);
}
.testimonial.testimonial-style-7 blockquote:after {
  display: none;
}
.testimonial.testimonial-style-7 .testimonial-arrow-down {
  display: none;
}
.testimonial.testimonial-style-7 .testimonial-author {
  padding: 0;
  margin: 6px 0 0;
  justify-content: center;
}
.testimonial.testimonial-style-7 .testimonial-author img {
  margin: 0 auto 10px;
}
.testimonial.testimonial-style-7 .testimonial-author p {
  text-align: center;
  padding: 0;
  margin: 0;
}

/* With Quotes */
.testimonial.testimonial-with-quotes {
  /* Remove Left Quote */
  /* Remove Right Quote */
}
.testimonial.testimonial-with-quotes blockquote:before {
  color: #333;
  display: block !important;
  left: 10px;
  top: 0;
  content: "“";
  font-size: 80px;
  font-style: normal;
  line-height: 1;
  position: absolute;
}
.testimonial.testimonial-with-quotes blockquote:after {
  color: #333;
  display: block !important;
  right: 10px;
  font-size: 80px;
  font-style: normal;
  line-height: 1;
  position: absolute;
  bottom: -0.5em;
  content: "”";
}
.testimonial.testimonial-with-quotes blockquote p {
  padding: 0 40px;
}
.testimonial.testimonial-with-quotes.testimonial-remove-left-quote blockquote:before {
  content: none;
}
.testimonial.testimonial-with-quotes.testimonial-remove-right-quote blockquote:after {
  content: none;
}
.testimonial.testimonial-with-quotes.testimonial-quotes-dark blockquote:before, .testimonial.testimonial-with-quotes.testimonial-quotes-dark blockquote:after {
  color: #333 !important;
}

/* Transparent Background */
.testimonial.testimonial-trasnparent-background blockquote {
  background: transparent !important;
}

/* Alternarive Font */
.testimonial.testimonial-alternarive-font blockquote p {
  font-style: italic;
  font-size: 1.2em;
}

/* Light */
.testimonial.testimonial-light blockquote:before, .testimonial.testimonial-light blockquote:after, .testimonial.testimonial-light blockquote p {
  color: #FFF;
}
.testimonial.testimonial-light blockquote.blockquote-default:before, .testimonial.testimonial-light blockquote.blockquote-default:after, .testimonial.testimonial-light blockquote.blockquote-default p {
  color: #777 !important;
}
.testimonial.testimonial-light .testimonial-author span, .testimonial.testimonial-light .testimonial-author strong {
  color: #FFF;
}

.section-text-light .testimonial.testimonial blockquote:before, .section-text-light .testimonial.testimonial blockquote:after, .section-text-light .testimonial.testimonial blockquote p {
  opacity: 0.9;
  color: #FFF;
}
.section-text-light .testimonial.testimonial .testimonial-author span, .section-text-light .testimonial.testimonial .testimonial-author strong {
  opacity: 0.6;
  color: #FFF;
}

/* Thumb Info */
.thumb-info {
  display: block;
  position: relative;
  text-decoration: none;
  max-width: 100%;
  background-color: #FFF;
  border-radius: 4px;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.thumb-info .thumb-info-wrapper {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  border-radius: 4px;
  margin: 0;
  overflow: hidden;
  display: block;
  position: relative;
}
.thumb-info .thumb-info-wrapper.thumb-info-wrapper-demos {
  max-height: 330px;
}
.thumb-info .thumb-info-wrapper:after {
  content: "";
  background: rgba(51, 51, 51, 0.8);
  transition: all 0.3s;
  border-radius: 4px;
  bottom: -4px;
  color: #FFF;
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
  display: block;
  opacity: 0;
  z-index: 1;
}
.thumb-info .thumb-info-wrapper-opacity-0:after {
  opacity: 0.0;
}
.thumb-info .thumb-info-wrapper-opacity-1:after {
  opacity: 0.1;
}
.thumb-info .thumb-info-wrapper-opacity-2:after {
  opacity: 0.2;
}
.thumb-info .thumb-info-wrapper-opacity-3:after {
  opacity: 0.3;
}
.thumb-info .thumb-info-wrapper-opacity-4:after {
  opacity: 0.4;
}
.thumb-info .thumb-info-wrapper-opacity-5:after {
  opacity: 0.5;
}
.thumb-info .thumb-info-wrapper-opacity-6:after {
  opacity: 0.6;
}
.thumb-info .thumb-info-wrapper-opacity-7:after {
  opacity: 0.7;
}
.thumb-info .thumb-info-wrapper-opacity-8:after {
  opacity: 0.8;
}
.thumb-info .thumb-info-wrapper-opacity-9:after {
  opacity: 0.9;
}
.thumb-info .thumb-info-action-icon {
  transition: all 0.3s;
  background: #CCC;
  border-radius: 0 0 0 4px;
  display: inline-block;
  font-size: 25px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  right: -100px;
  text-align: center;
  top: -100px;
  width: 50px;
  z-index: 2;
}
.thumb-info .thumb-info-action-icon i {
  font-size: 14px;
  right: 50%;
  position: absolute;
  top: 50%;
  color: #FFF;
  transform: translate(50%, -50%);
  margin: -2px -1px 0 0px;
}
.thumb-info .thumb-info-inner {
  transition: all 0.3s;
  display: block;
}
.thumb-info .thumb-info-inner em {
  display: block;
  font-size: 0.8em;
  font-style: normal;
  font-weight: normal;
}
.thumb-info .thumb-info-title {
  transition: all 0.3s;
  background: rgba(51, 51, 51, 0.8);
  color: #FFF;
  font-weight: 600;
  left: 0;
  letter-spacing: -0.05em;
  position: absolute;
  z-index: 2;
  max-width: 90%;
  font-size: 17px;
  padding: 13px 21px 2px;
  bottom: 13%;
}
.thumb-info .thumb-info-type {
  background-color: #CCC;
  border-radius: 2px;
  display: inline-block;
  float: left;
  font-size: 0.6em;
  font-weight: 600;
  letter-spacing: 0;
  margin: 8px -2px -15px 0px;
  padding: 2px 12px;
  text-transform: uppercase;
  z-index: 2;
  line-height: 2.3;
}
.thumb-info .owl-carousel {
  z-index: auto;
}
.thumb-info .owl-carousel .owl-dots {
  z-index: 2;
}
.thumb-info img {
  transition: all 0.3s ease;
  border-radius: 3px;
  position: relative;
  width: 100%;
}
.thumb-info:hover .thumb-info-wrapper:after {
  opacity: 1;
}
.thumb-info:hover .thumb-info-action-icon {
  right: 0;
  top: 0;
}
.thumb-info:hover .thumb-info-title {
  background: rgba(51, 51, 51, 0.9);
}
.thumb-info:hover img {
  transform: scale(1.1, 1.1);
}
.thumb-info.thumb-info-no-zoom img {
  transition: none;
}
.thumb-info.thumb-info-no-zoom:hover img {
  transform: scale(1, 1);
}
.thumb-info.thumb-info-lighten .thumb-info-wrapper:after {
  background: rgba(23, 23, 23, 0.25);
}
.thumb-info.thumb-info-hide-wrapper-bg .thumb-info-wrapper:after {
  display: none;
}
.thumb-info.thumb-info-centered-icons .thumb-info-action {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 45px;
  text-align: center;
  opacity: 0;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transform: translate3d(0, -70%, 0);
  z-index: 2;
}
.thumb-info.thumb-info-centered-icons .thumb-info-action a {
  text-decoration: none;
}
.thumb-info.thumb-info-centered-icons .thumb-info-action-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  width: 35px;
  height: 35px;
  font-size: 14px;
  border-radius: 50%;
  margin-right: 5px;
  transition: all 0.1s ease;
  z-index: 2;
}
.thumb-info.thumb-info-centered-icons .thumb-info-action-icon i {
  margin: 0;
}
.thumb-info.thumb-info-centered-icons .thumb-info-action-icon:hover {
  transform: scale(1.15, 1.15);
}
.thumb-info:hover.thumb-info-centered-icons .thumb-info-action {
  opacity: 1;
  transform: translate3d(0, -50%, 0);
}
.thumb-info:hover.thumb-info-centered-icons .thumb-info-action-icon {
  position: relative;
}
.thumb-info.thumb-info-centered-info .thumb-info-title {
  background: transparent;
  left: 5%;
  width: 90%;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transform: translate(0%, -50%);
  top: 50%;
  bottom: auto;
  opacity: 0;
  text-align: center;
  padding: 0;
  position: absolute;
}
.thumb-info.thumb-info-centered-info .thumb-info-type {
  float: none;
}
.thumb-info.thumb-info-centered-info:hover .thumb-info-title {
  opacity: 1;
  transform: translate(0%, -50%);
}
.thumb-info.thumb-info-bottom-info .thumb-info-title {
  background: #FFF;
  left: 0;
  width: 100%;
  max-width: 100%;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
  top: auto;
  bottom: 0;
  opacity: 0;
  padding: 15px;
  text-shadow: none;
  color: #333;
}
.thumb-info.thumb-info-bottom-info .thumb-info-type {
  float: none;
  background: none;
  padding: 0;
  margin: 0;
}
.thumb-info.thumb-info-bottom-info:hover .thumb-info-title {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.thumb-info.thumb-info-bottom-info:hover img {
  transform: scale(1.1);
}
.thumb-info.thumb-info-bottom-info-linear .thumb-info-title {
  background: linear-gradient(to top, rgba(255, 255, 255, 0.8) 0, transparent 100%) repeat scroll 0 0 rgba(255, 255, 255, 0);
}
.thumb-info.thumb-info-bottom-info-dark .thumb-info-title {
  background: #333;
  color: #FFF;
}
.thumb-info.thumb-info-bottom-info-dark-linear .thumb-info-title {
  background: linear-gradient(to top, rgba(51, 51, 51, 0.8) 0, transparent 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
}
.thumb-info.thumb-info-bottom-info-show-more img {
  transform: none !important;
}
.thumb-info.thumb-info-bottom-info-show-more .thumb-info-title {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.thumb-info.thumb-info-bottom-info-show-more .thumb-info-show-more-content {
  transition: ease max-height 800ms;
  overflow: hidden;
  max-height: 0;
  display: block;
}
.thumb-info.thumb-info-bottom-info-show-more:hover .thumb-info-show-more-content {
  max-height: 300px;
}
.thumb-info.thumb-info-push-hor img {
  transform: translatex(-18px);
  width: calc(100% + 19px);
  max-width: none;
}
.thumb-info.thumb-info-push-hor:hover img {
  transform: translatex(0);
}
.thumb-info.thumb-info-block .thumb-info-action-icon {
  top: 15px;
  right: 15px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.thumb-info.thumb-info-block .thumb-info-wrapper:before {
  content: "";
  background: rgba(255, 255, 255, 0.9);
  transition: all 0.3s;
  border-radius: 0;
  bottom: 1rem;
  color: #FFF;
  left: 1rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: block;
  opacity: 0;
  z-index: 2;
}
.thumb-info.thumb-info-block:hover .thumb-info-wrapper:before {
  opacity: 1;
}
.thumb-info.thumb-info-block:hover .thumb-info-action-icon {
  opacity: 1;
}
.thumb-info.thumb-info-block.thumb-info-block-dark .thumb-info-wrapper:before {
  background: rgba(51, 51, 51, 0.9);
}
.thumb-info.thumb-info-hide-info-hover .thumb-info-wrapper:after {
  opacity: 0.65;
}
.thumb-info.thumb-info-hide-info-hover .thumb-info-title {
  opacity: 1;
  top: 50%;
}
.thumb-info.thumb-info-hide-info-hover .thumb-info-type {
  float: none;
}
.thumb-info.thumb-info-hide-info-hover:hover .thumb-info-wrapper:after {
  opacity: 0.1;
}
.thumb-info.thumb-info-hide-info-hover:hover .thumb-info-title {
  opacity: 0;
}
.thumb-info.thumb-info-slide-info-hover .thumb-info-wrapper:after {
  opacity: 0.65;
}
.thumb-info.thumb-info-slide-info-hover .thumb-info-wrapper-no-opacity:after {
  opacity: 1;
}
.thumb-info.thumb-info-slide-info-hover .thumb-info-title {
  opacity: 1;
  top: 0;
  height: 100%;
  top: 0;
  transform: none !important;
}
.thumb-info.thumb-info-slide-info-hover .thumb-info-type {
  float: none;
}
.thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-1,
.thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-2 {
  transition: cubic-bezier(0.55, 0, 0.1, 1) top 500ms;
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translate(0%, -50%);
}
.thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-2 {
  top: 150%;
}
.thumb-info.thumb-info-slide-info-hover:hover .thumb-info-slide-info-hover-1 {
  top: -50%;
}
.thumb-info.thumb-info-slide-info-hover:hover .thumb-info-slide-info-hover-2 {
  top: 50%;
}
.thumb-info.thumb-info-no-borders, .thumb-info.thumb-info-no-borders img {
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
}
.thumb-info.thumb-info-no-borders .thumb-info-wrapper {
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
}
.thumb-info.thumb-info-no-borders .thumb-info-wrapper:after {
  border-radius: 0;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded,
.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded img,
.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded .thumb-info-wrapper,
.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded .thumb-info-wrapper:after {
  border-radius: 4px;
}
.thumb-info.thumb-info-preview .thumb-info-wrapper:after {
  display: none;
}
.thumb-info.thumb-info-preview .thumb-info-image {
  min-height: 232px;
  display: block;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  transition: background-position 0.8s linear 0s;
}
.thumb-info.thumb-info-preview:hover .thumb-info-image {
  transition: background-position 2.5s linear 0s;
  background-position: center bottom;
}
.thumb-info.thumb-info-preview.thumb-info-preview-short:hover .thumb-info-image {
  transition: background-position 1s linear 0s;
}
.thumb-info.thumb-info-preview.thumb-info-preview-long:hover .thumb-info-image {
  transition: background-position 5s linear 0s;
}
.thumb-info.thumb-info-no-overlay .thumb-info-wrapper:after {
  content: none;
}
.thumb-info.thumb-info-no-bg {
  background: transparent;
}

/* Thumb Info Ribbon */
.thumb-info-ribbon {
  background: #999;
  position: absolute;
  margin: -16px 0 0 0;
  padding: 5px 13px 6px;
  right: 15px;
  z-index: 1;
}
.thumb-info-ribbon:before {
  border-right: 10px solid #646464;
  border-top: 16px solid transparent;
  content: "";
  display: block;
  height: 0;
  left: -10px;
  position: absolute;
  top: 0;
  width: 7px;
}
.thumb-info-ribbon span {
  color: #FFF;
  font-size: 1.1em;
  font-family: "Shadows Into Light", cursive;
}

/* Thumb Info - Full Width */
.full-width .thumb-info img {
  border-radius: 0 !important;
}

/* Thumb Info Caption  */
.thumb-info-caption {
  padding: 10px 0;
}
.thumb-info-caption .thumb-info-caption-text, .thumb-info-caption p {
  font-size: 0.9em;
  margin: 0;
  padding: 15px 0;
  display: block;
}

/* Thumb Info Side Image  */
.thumb-info-side-image .thumb-info-side-image-wrapper {
  padding: 0;
  float: left;
  margin-right: 20px;
}

.thumb-info-side-image-right .thumb-info-side-image-wrapper {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

/* Thumb Info Social Icons */
.thumb-info-social-icons {
  margin: 0;
  padding: 0;
  display: block;
}
.thumb-info-social-icons a {
  background: #CCC;
  border-radius: 25px;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
}
.thumb-info-social-icons a:hover {
  text-decoration: none;
}
.thumb-info-social-icons a span {
  display: none;
}
.thumb-info-social-icons a i {
  color: #FFF;
  font-size: 0.9em;
  font-weight: normal;
}

/* Thumb Info Floating Caption */
.thumb-info-floating-caption-title {
  background: rgba(51, 51, 51, 0.8);
  color: #FFF;
  font-weight: 600;
  letter-spacing: -0.05em;
  font-size: 17px;
  padding: 13px 21px;
}
.thumb-info-floating-caption-title .thumb-info-floating-caption-type {
  position: absolute;
  background-color: #CCC;
  border-radius: 2px;
  display: block;
  font-size: 0.6em;
  font-weight: 600;
  letter-spacing: 0;
  margin: 8px -2px -15px 0px;
  padding: 2px 12px;
  text-transform: uppercase;
  line-height: 2.3;
}

/* Responsive */
@media (max-width: 991px) {
  .thumb-info .thumb-info-title {
    font-size: 14px;
  }
  .thumb-info .thumb-info-more {
    font-size: 11px;
  }
}
/* Page 404 */
.http-error {
  padding: 50px 0;
}
.http-error h2, .http-error .h2 {
  font-size: 140px;
  font-weight: 700;
  line-height: 140px;
}
.http-error h4, .http-error .h4 {
  color: #333;
}
.http-error p {
  font-size: 1.4em;
  line-height: 36px;
}

/* Responsive */
@media (max-width: 575px) {
  .http-error {
    padding: 0;
  }
  .http-error h2, .http-error .h2 {
    font-size: 100px;
    letter-spacing: 0;
    line-height: 100px;
  }
}