/* Scroll to Top */
html {
	.scroll-to-top {
		transition: opacity 0.3s;
		background: $secondary;
		border-radius: 4px 4px 0 0;
		bottom: 0;
		color: $primary;
		display: block;
		height: 9px;
		opacity: 0;
		padding: 10px 10px 35px;
		position: fixed;
		#{$right}: 10px;
		text-align: center;
		text-decoration: none;
		min-width: 50px;
		z-index: 1040;
		font-size: 0.8em;
		&:hover {
			opacity: 1;
		}
		&.visible {
			opacity: 0.75;
		}
		span {
			display: inline-block;
			padding: 0 5px;
		}
		.fa-caret-up {
			font-size: 2em;
		}
	}

	&.lightbox-opened {
		.scroll-to-top {
			#{$right}: 27px;
		}
	}

	&.ie11 {
		.scroll-to-top {
			#{$right}: 25px;
		}
	}

}

/* Responsive */
@media (max-width: 991px) {

	html .scroll-to-top.hidden-mobile {
		display: none !important;
	}

}
