/* Page Header */
.page-header {
	background-color: $color-dark;
	margin: 0 0 35px 0;
	padding: 30px 0;
	position: relative;
	text-align: $left;
	.breadcrumb {
		background: none;
		margin: 0;
	    padding: 0;
		position: relative;
		z-index: 1;
		> li {
			display: inline-block;
			font-size: 1.1em;
			text-shadow: none;
			font-weight: 500;
			& + li:before {
				position: relative;
				top: 1px;
				font-size: 1.2em;
				color: #fff;
				opacity: 1;
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				/*rtl:ignore*/
				content: "\F101";
				/*rtl:raw:
				content: "\F100";
				*/
				padding: side-values(0 12px 0 9px);
			}
			.fa-home {
				font-size: 1.2em;
			}
		}

		&.breadcrumb-block {
			background: $color-dark;
			display: inline-block !important;
			padding: .45rem 1.2rem;
			border-radius: 4px;
			box-shadow: 0 3px 10px rgba(0,0,0,.15);
		}

		&.breadcrumb-light {
			color: #fff;
			a {
				color: $secondary;
			}
			.active {
				color: $secondary;
			}
		}
	}

	h1 {
		color: #fff;
		display: inline-block;
		font-size: 2.8em;
		line-height: 1;
		margin: 0;
		padding: 0;
		font-weight: 400;
		position: relative;
		top: 1px;
		text-transform: uppercase;
	}

	.sub-title {
		display: block;
		font-size: 1.2em;
		font-weight: 300;
		margin: 0;
		opacity: 0.8;
		color: #fff;
	}

	// Extra Button
	.page-header-extra-button {
		position: absolute;
		width: 100%;
		bottom: 50px;
		left: 0;
		right: 0;
		z-index: 5;
	}

	// Page Header Small
	&.page-header-sm {
		padding: 30px 0;
		h1 {
			font-size: 22px;
		}
	}

	// Page Header Large
	&.page-header-lg {
		padding: 50px 0;
		h1 {
			font-size: 40px;
		}
	}

	// Classic
	&.page-header-classic {
		&:after {
			content: '';
			width: 100%;
			height: 5px;
			background: rgba(255,255,255,0.8);
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.page-header-title-border {
			width: 0;
			height: 5px;
			position: absolute;
			bottom: 0;
			background: transparent;
			z-index: 1;
		}
	}

	// Modern
	&.page-header-modern {
		&.page-header-sm {
			h1 {
				font-size: 22px;
			}
		}
		&.page-header-md {
			padding: 50px 0;
		}
		&.page-header-lg {
			padding: 65px 0;
			h1 {
				font-size: 32px;
			}
		}

		// background
		&.page-header-background {
			position: relative;
			padding: 130px 0;
			min-height: 320px;
			background-size: cover;
			&.page-header-background-pattern {
				background-size: auto;
				background-repeat: repeat;
				background-attachment: fixed;
			}

 			&.page-header-background-sm {
 				padding: 60px 0;
 			}

 			&.page-header-background-md {
 				padding: 150px 0;
 			}

 			&.page-header-background-lg {
 				padding: 250px 0;
 			}

		}

	}
}
