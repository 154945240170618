/* Buttons */
.btn {
	font-size: 0.8rem;
	padding: 0.533rem 0.933rem;
	cursor: pointer;

	&.btn-xs {
		font-size: 0.7rem;
		padding: 0.2rem 0.5rem;
	}

	&.btn-sm {
		font-size: 0.8rem;
		padding: 0.3rem 0.65rem;
	}

	&.btn-lg {
		font-size: 1.2rem;
		padding: 0.7rem 1rem;
	}

	&.btn-xl {
		font-size: 1.4rem;
		padding: 1.2rem 1.8rem;
	}

	// Paddings
	&.btn-px-1 {
		padding-left: .35rem;
		padding-right: .35rem;
	}

	&.btn-px-2 {
		padding-left: .75rem;
		padding-right: .75rem;
	}

	&.btn-px-3 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	&.btn-px-4 {
		padding-left: 1.75rem;
		padding-right: 1.75rem;
	}

	&.btn-px-5 {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	&.btn-py-1 {
		padding-top: .35rem;
		padding-bottom: .35rem;
	}

	&.btn-py-2 {
		padding-top: .75rem;
		padding-bottom: .75rem;
	}

	&.btn-py-3 {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	&.btn-py-4 {
		padding-top: 1.75rem;
		padding-bottom: 1.75rem;
	}

	&.btn-py-5 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	&.btn-primary {
		.arrow2 {
			&:before {
				background-color: $secondary;
			}
			&:after {
				border-bottom-color: $secondary;
			}
		}
	}
	&.btn-secondary {
		position: relative;
		color: $primary;
		z-index: 1;
		border: none;
	}
	&.btn-outline-secondary {
		position: relative;
		border-width: 2px;
		z-index: 1;
		&:before {
			border-radius: 0;
		}
		&:hover {
			border-color: $primary;
		}
		.arrow2 {
			&:before {
				background-color: $secondary;
			}
			&:after {
				border-bottom-color: $secondary;
			}
		}
	}
}

html {
	body {
		&, &.body {
			.btn.focus,
			.btn:focus,
			.btn.active,
			.btn:active {
				box-shadow: none !important;
			}
		}
	}
}

/* Dropdown Buttons */
.btn + .dropdown-menu {
	.dropdown-item {
		font-size: 0.8rem;
	}
}

/* Default */
.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
	box-shadow: none !important;

	&:hover, &:active, &:focus  {
		color: #333;
		background-color: #e6e6e6;
		border-color: #adadad;
	}
}

/* Outline */
.btn-outline-primary:hover {
	.arrow2 {
		&:before {
			background-color: #fff;
		}
		&:after {
			border-bottom-color: #fff;
		}
	}
}
.btn:hover {
	&.btn-outline-primary,
	&.btn-outline-danger,
	&.btn-outline-warning {
		color: #fff !important;
	}
}
.btn-outline-danger:hover {
	color: #fff !important;
}
.btn-outline {
	border-width: 2px;
	padding: 0.483rem 0.933rem;

	&.btn-xs {
		padding: 0.15rem 0.5rem;
	}

	&.btn-sm {
		padding: 0.25rem 0.65rem;
	}

	&.btn-lg {
		padding: 0.45rem 1rem;
	}

	&.btn-xl {
		padding: 1.2rem !important;
	}
}
/* Modern */
.btn-modern {
    text-transform: uppercase;
	font-size: .8em;
	padding: 0.8rem 1.5rem;
	font-weight: 600;
}

/* Rounded */
.btn-rounded {
	border-radius: 35px;
}

/* Rounded */
.btn-rounded-icon {
	border-radius: 50px;
	padding: 0;
	width: 50px;
	height: 50px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	&[data-hash] {
		> i {
			pointer-events: none;
		}
	}
}

/* 3d */
.btn-3d {
	border-bottom-width: 3px;
}

/* Arrow */
.btn-with-arrow {
	background-color: transparent;
	font-weight: 600;

	span {
		background-color: #ccc;
		display: inline-block;
		height: 25px;
		line-height: 24px;
		width: 24px;
		border-radius: 50%;
		margin-left: 10px;
		transition: all 0.1s linear;

	}

	i {
		color: #FFF;
		font-size: 8px;
		top: -1px;
		position: relative;
	}

	&:hover {
		span {
			transform: translateX(5px);
		}
	}

	&.btn-lg, &.btn-xl {
		span {
			line-height: 23px;
		}
	}

	&.btn-xs {
		span {
			line-height: 25px;
		}
	}
}

/* Icons  */
.btn-icon i {
	margin-#{$right}: 10px;
}

.btn-icon-right i {
	margin-#{$right}: 0;
	margin-#{$left}: 10px;
}

/* Dropdown Button */
.btn {
	&.dropdown-toggle {
		&:after {
			margin-left: .155em;
			vertical-align: .155em;
		}
	}
}

/* Colors */
@each $state in $states {
	$color: nth($state, 2);
	$textColor: nth($state, 3);

	.btn-#{nth($state,1)} {
		background-color: $color;
		border-color: $color $color darken($color, 10%);
		color: $textColor;

		&:hover,
		&.hover {
			background-color: darken($color, 7.5%);
			border-color: darken($color, 10%) darken($color, 10%) $color;
			color: $textColor;
		}

		&:focus,
		&.focus {
			box-shadow: 0 0 0 3px rgba($color, .5);
		}

		&.disabled,
		&:disabled {
			background-color: $color;
			border-color: $color $color darken($color, 10%);
		}

		&:active,
		&.active,
		.show > &.dropdown-toggle {
			background-color: darken($color, 7.5%);
			background-image: none;
			border-color: darken($color, 10%) darken($color, 10%) darken($color, 20%);
		}
	}

	.btn-outline.btn-#{nth($state,1)} {
		color: $color;
		background-color: transparent;
		background-image: none;
		border-color: $color;

		&:hover,
		&.hover {
			color: $textColor;
			background-color: $color;
			border-color: $color;
		}

		&:focus,
		&.focus {
			box-shadow: 0 0 0 3px rgba($color, .5);
		}

		&.disabled,
		&:disabled {
			color: $color;
			background-color: transparent;
		}

		&:active,
		&.active,
		.show > &.dropdown-toggle {
			color: $textColor;
			background-color: $color;
			border-color: $color;
		}
	}
}

/* Button Light */
html body {
	.btn-light,
	.btn-outline.btn-light {
		color: $primary;
		background-color: #FFF;

		&:hover,
		&.hover {
			color: darken($primary, 5%);
			background-color: #FFF;
		}

		&:focus,
		&.focus {
			box-shadow: 0 0 0 3px rgba(darken(#FFF, 25%), .5);
		}

		&.disabled,
		&:disabled {
			border-color: darken(#FFF, 25%) darken(#FFF, 25%) darken(#FFF, 25%);
		}

		&:active,
		&.active,
		.show > &.dropdown-toggle {
			border-color: darken(#FFF, 25%) darken(#FFF, 25%) darken(#FFF, 25%);
		}
	}

	.btn-light-2,
	.btn-outline.btn-light-2 {
		border-color: #FFF;
		color: #FFF;

		&:hover,
		&.hover {
			background-color: #FFF;
			border-color: #FFF;
			color: #0e0e0e;
		}

		&:focus,
		&.focus {
			box-shadow: 0 0 0 3px rgba(darken(#FFF, 30%), .5);
		}

		&.disabled,
		&:disabled {
			border-color: darken(#FFF, 30%) darken(#FFF, 30%) darken(#FFF, 30%);
		}

		&:active,
		&.active,
		.show > &.dropdown-toggle {
			border-color: #FFF;
		}
	}
}

html[dir="rtl"] {
	.input-group .form-control:not(:first-child),
	.input-group-addon:not(:first-child),
	.input-group-btn:not(:first-child)>.btn,
	.input-group-btn:not(:first-child)>.btn-group>.btn,
	.input-group-btn:not(:first-child)>.dropdown-toggle,
	.input-group-btn:not(:last-child)>.btn-group:not(:first-child)>.btn,
	.input-group-btn:not(:last-child)>.btn:not(:first-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
}

/* Hamburguer */
.hamburguer-btn {
	background: transparent;
	outline: 0 !important;
	border: none;
	width: 30px;
	height: 30px;
	padding: 0;
	margin: 15px;
	display: inline-block;
	position: relative;
	cursor: pointer;

	&.hamburguer-btn-side-header {
		position: absolute;
		top: 15px;
		right: 8px;
	}

	&.hamburguer-btn-side-header-overlay {
		right: 30px;
		z-index: 9999;
	}

	.hamburguer {
		pointer-events: none;
		span {
			position: absolute;
			top: 54%;
			left: 0;
			width: 100%;
			height: 2px;
			transition: ease width 300ms;
			//transform: translateY(-50%);
			background: #333;
			&:nth-child(1) {
				top: 30%;
			}
			&:nth-child(2) {
				transition-delay: 100ms;
			}
			&:nth-child(3) {
				top: 78%;
				transition-delay: 200ms;
			}
		}
	}

	.close {
		opacity: 0;
		pointer-events: none;
		transition: ease all 300ms;
		span {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 0;
			height: 2px;
			background: #333;
			transition: ease all 300ms;
			transform-origin: 50% 0;
			&:nth-child(1) {
				transform: translateX(-45%) rotate(45deg);
			}
			&:nth-child(2) {
				transform: translateX(-50%) rotate(-45deg);
			}
		}
	}

	&.active {
		.hamburguer {
			opacity: 0;
			span {
				width: 0;
			}
		}
		.close {
			opacity: 1;
			transition: ease all 300ms;
			span {
				width: 100%;
				transition: ease all 300ms;
				&:nth-child(2) {
					transition-delay: 300ms;
				}
			}
		}
	}

	&.hamburguer-btn-light {
		.hamburguer span,
		.close span {
			background: #fff;
		}
	}
}

html.overflow-hidden {
	.hamburguer-btn {
		&.hamburguer-btn-side-header-overlay {
			right: 13px;
		}
	}
}

/* Hamburguer Sticky Colors */
html.sticky-header-active,
.pin-wrapper .sticky-active:not([class*="sticky-wrapper-effect"]),
.pin-wrapper .sticky-effect-active {
	.hamburguer-btn {
		&.hamburguer-btn-sticky-light {
			.hamburguer span,
			.close span {
				background: #FFF;
			}
		}
		&.hamburguer-btn-sticky-dark {
			.hamburguer span,
			.close span {
				background: #CCC;
			}
		}
	}
}

/* Badge */
.btn-badge {
	position: relative;
	.badge {
		top: -0.5rem;
		right: -0.5rem;
		position: absolute;
	}
}

/* Gradient */
.btn-gradient:not(.btn-outline) {
	border: 0;

	&:hover,
	&.hover {
		border: 0;
	}

	&:focus,
	&.focus {
		border: 0;
	}

	&.disabled,
	&:disabled {
		border: 0;
	}

	&:active,
	&.active {
		border: 0;
	}
}

.btn-gradient.btn-outline {
	border: 2px solid transparent;
	background: transparent;
	border-image-slice: 1 !important;

	&:hover,
	&.hover {
		border: 2px solid transparent;
	}

	&.disabled,
	&:disabled {
		border: 2px solid transparent;
	}

	&:active,
	&.active {
		border: 2px solid transparent;
	}
}
