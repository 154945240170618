/* Social Icons */
.social-icons {
	margin: 0;
	padding: 0;
	width: auto;
	li {
		display: inline-block;
		margin: side-values(0 5px 0 0);
		padding: 0;
		border-radius: 100%;
		overflow: visible;
		a {
			transition: all 0.2s ease;
			display: block;
			height: 36px;
			line-height: 36px;
			width: 36px;
			text-align: center;
			color: #fff;
			text-decoration: none;
			font-size: 0.8rem;
		}
		&:hover {
			a {
				background: #151719;
				color: #FFF;
			}
			&.social-icons-twitter {
				a {
					background: #1aa9e1;
				}
			}
			&.social-icons-facebook {
				a {
					background: #3b5a9a;
				}
			}
			&.social-icons-linkedin {
				a {
					background: #0073b2;
				}
			}
			&.social-icons-rss {
				a {
					background: #ff8201;
				}
			}
			&.social-icons-googleplus {
				a {
					background: #dd4b39;
				}
			}
			&.social-icons-pinterest {
				a {
					background: #cc2127;
				}
			}
			&.social-icons-youtube {
				a {
					background: #c3191e;
				}
			}
			&.social-icons-instagram {
				a {
					background: #bc2a8d;
				}
			}
			&.social-icons-skype {
				a {
					background: #00b0f6;
				}
			}
			&.social-icons-email {
				a {
					background: #dd4b39;
				}
			}
			&.social-icons-vk {
				a {
					background: #6383a8;
				}
			}
			&.social-icons-xing {
				a {
					background: #1a7576;
				}
			}
			&.social-icons-tumblr {
				a {
					background: #304e6c;
				}
			}
			&.social-icons-reddit {
				a {
					background: #ff4107;
				}
			}
		}
	}
	&.social-icons-dark {
		li {
			a {
				background: #181818;
				i {
					color: #FFF;
				}
			}
		}
	}
	&.social-icons-dark-2 {
		li {
			a {
				background: #272a2e;
				i {
					color: #FFF;
				}
			}
		}
	}
	&.social-icons-transparent {
		li {
			box-shadow: none;
			a {
				background: transparent;
			}
		}
	}
	&.social-icons-icon-light {
		li {
			a {
				border: 1px solid $primary;
				border-radius: 4px;
				i {
					color: #fff;
				}
			}
		}
	}
	&.social-icons-icon-light.social-icons-clean {
		li {
			a {
				i {
					color: #FFF !important;
				}
			}
		}
	}
	&.social-icons-big {
		li {
			a {
				height: 48px;
				line-height: 48px;
				width: 48px;
			}
		}
	}
	&.social-icons-opacity-light {
		li {
			a {
				background: rgba(255,255,255,0.04);
			}
		}
	}
}

.social-icons:not(.social-icons-clean):not(.social-icons-dark):not(.social-icons-dark-2):not(.social-icons-icon-light) {
	li {
		a {
			background: transparent;
			border: 1px solid #fff;
			color: #fff;
			&:hover {
				background-color: #333;
				color: #fff !important;
				border-color: #333;
			}
		}
	}
}

.social-icons-dark.social-icons-clean,
.social-icons-dark-2.social-icons-clean {
	li {
		a {
			background: transparent;
			color: #FFF !important;
		}
	}
}
