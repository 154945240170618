/* List */
.list {
	li {
		margin-bottom: 13px;
	}
	&.pull-left li {
		text-align: $left;
	}

	&.list-icons {
		list-style: none;
		padding-#{$left}: 0;
		padding-#{$right}: 0;
		li {
			position: relative;
			padding-#{$left}: 25px;
			&, a:first-child {
				> [class*="fa-"]:first-child,
				> .icons:first-child {
					position: absolute;
					#{$left}: 0;
					top: 5px;
				}
			}
		}

		&.list-icons-style-2 {
			li {
				padding-top: 5px;
				padding-#{$left}: 36px;
				&, a:first-child {
					> [class*="fa-"]:first-child,
					> .icons:first-child {
						border-width: 1px;
						border-style: solid;
						border-radius: 50%;
						width: 25px;
						height: 25px;
						text-align: center;
						padding: 4px;
						font-size: 0.8em;
						line-height: 1.3;
					}
				}
			}
		}

		&.list-icons-style-3 {
			li {
				padding-top: 5px;
				padding-#{$left}: 36px;
				&, a:first-child {
					> [class*="fa-"]:first-child,
					> .icons:first-child {
						color: #FFF;
						border-radius: 50%;
						width: 25px;
						height: 25px;
						text-align: center;
						padding: 5px;
						font-size: 0.8em;
						line-height: 1.3;
					}
				}
			}
		}

		&.list-icons-sm {
			li {
				padding-#{$left}: 13px;
				margin-bottom: 5px;
				&, a:first-child {
					> [class*="fa-"]:first-child,
					> .icons:first-child {
						font-size: 0.8em;
						top: 7px;
					}
				}
			}

			&.list-icons-style-2 {
				li {
					padding-#{$left}: 32px;
					&, a:first-child {
						> [class*="fa-"]:first-child,
						> .icons:first-child {
							width: 20px;
							height: 20px;
							padding: 3px;
						}
					}
				}
			}

			&.list-icons-style-3 {
				li {
					padding-#{$left}: 32px;
					&, a:first-child {
						> [class*="fa-"]:first-child,
						> .icons:first-child {
							width: 20px;
							height: 20px;
							padding: 3px;
						}
					}
				}
			}

		}

		&.list-icons-lg {
			li {
				padding-top: 5px;
				padding-#{$left}: 27px;
				&, a:first-child {
					> [class*="fa-"]:first-child,
					> .icons:first-child {
						font-size: 1.3em;
						top: 10px;
					}
				}
			}

			&.list-icons-style-2 {
				li {
					padding-top: 8px;
					padding-#{$left}: 42px;
					&, a:first-child {
						> [class*="fa-"]:first-child,
						> .icons:first-child {
							width: 30px;
							height: 30px;
							padding: 4px;
						}
					}
				}
			}

			&.list-icons-style-3 {
				li {
					padding-top: 8px;
					padding-#{$left}: 42px;
					&, a:first-child {
						> [class*="fa-"]:first-child,
						> .icons:first-child {
							width: 30px;
							height: 30px;
							padding: 4px;
						}
					}
				}
			}
		}

		&.list-icons-reverse {
			li {
				text-align: $right;
				padding-#{$right}: 25px;
				padding-#{$left}: 0;
				&, a:first-child {
					> [class*="fa-"]:first-child,
					> .icons:first-child {
						position: absolute;
						#{$left}: auto;
						#{$right}: 0;
						top: 5px;
					}
				}
			}

			&.list-icons-style-2 {
				li {
					padding-#{$right}: 36px;
				}
			}

			&.list-icons-style-3 {
				li {
					padding-#{$right}: 36px;
				}
			}

			&.list-icons-sm {
				li {
					padding-#{$left}: 0;
					padding-#{$right}: 13px;
				}
				&.list-icons-style-2 {
					li {
						padding-#{$right}: 32px;
					}
				}

				&.list-icons-style-3 {
					li {
						padding-#{$right}: 32px;
					}
				}
			}

			&.list-icons-lg {
				li {
					padding-#{$left}: 0;
					padding-#{$right}: 32px;
				}
				&.list-icons-style-2 {
					li {
						padding-#{$right}: 42px;
					}
				}

				&.list-icons-style-3 {
					li {
						padding-#{$right}: 42px;
					}
				}
			}

		}

	}

	&.list-borders {
		li {
			border-bottom: 1px solid #eaeaea;
			margin-top: 9px;
			margin-bottom: 0;
			padding-bottom: 9px;
			&:last-child {
				border-bottom: 0;
			}
		}
	}

	&.list-side-borders {
		border-#{$left}: 1px solid #eaeaea;
		padding-#{$left}: 10px;
	}

	&.list-ordened {
		counter-reset: custom-counter;
		list-style: none;
		li {
			position: relative;
			padding-#{$left}: 22px;
			&:before {
				position: absolute;
				top: 0;
				#{$left}: 0;
				content: counter(custom-counter);
				counter-increment: custom-counter;
				font-weight: bold; 
			}
		}

		&.list-ordened-style-2, &.list-ordened-style-3 {
			li {
				padding-#{$left}: 32px;
				&:before {
				    border-radius: 100%;
				    border-style: solid;
				    border-width: 1px;
				    min-width: 26px;
				    padding: 0;
				    text-align: center;
				}
			}
		}

		&.list-ordened-style-3 {
			li {
				&:before {
					color: #FFF;
					border: 0;
				}
			}
		}

	}

	&.list-unstyled {
		padding-left: 0;
		padding-right: 0;
	}
}

.list-unstyled {
	padding-left: 0;
	padding-right: 0;
}

// Description List
dl {
	dd {
		margin-bottom: 15px;
	}
}

@include if-rtl() {
	dd {
		margin-right: 0;
	}	
}

/* Sitemap */
ul {
	&.sitemap {
		> li > a {
			font-weight: bold;
		}
		&.list {
			li {
				margin: 0 0 3px 0;
			}
			ul {
				margin-bottom: 5px;
				margin-top: 5px;
			}
		}
	}
}