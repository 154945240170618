@mixin font-size($size: 14) {
	font-size: ($size / $root-font-size) + rem !important;
}

@mixin line-height($size: 14) {
	line-height: ($size / $root-font-size) + rem !important;
}

@mixin placeholder-color($color) {
	&::-webkit-input-placeholder {
		color: $color;
	}

	&::-moz-placeholder {
		color: $color;
	}

	&:-ms-input-placeholder {
		color: $color;
	}
}