/* Forms */
form label {
	font-weight: normal;
}

textarea {
	resize: vertical;
}

select {
	border: 1px solid #E5E7E9;
	border-radius: 4px;
	outline: none;
}

.label {
	font-weight: normal;
}

label {
	&.required {
		&:after {
			content: "*";
			font-size: 0.8em;
			margin: 0.3em;
			position: relative;
			top: -2px;
		}
	}
}

.form-group:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

// Form Control
.form-control {
	border-color: rgba(0, 0, 0, 0.09);
	&:not(.form-control-lg) {
		font-size: 0.75rem;
		line-height: 1.3;
	}
	&.form-control-lg {
		font-size: 1.2rem;
	}
	&:not(.form-control-sm):not(.form-control-lg) {
		font-size: 0.85rem;
		line-height: 1.85;
	}

	&.form-control-focused {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
		border-color: #CCC;
	}

	&:focus {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
		border-color: #CCC;
	}

	&.error {
		border-color: #a94442;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		&:focus {
			border-color: #843534;
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
		}
	}
}

.page-link {
	border-color: rgba(0, 0, 0, 0.06);
}

// Input Group
.input-group {
	.form-control {
		height: auto;
	}
}

// Input Groups Rounded
.input-group-rounded {
	.form-control {
		border-radius: if-ltr(2rem 0rem 0rem 2rem, 0rem 2rem 2rem 0rem !important);
		border: 0;
		padding-#{$left}: 1rem;
	}
	.input-group-append {
		.btn {
			border-color: rgba(0,0,0,0.09);
			border-radius: if-ltr(0rem 2rem 2rem 0rem, 2rem 0rem 0rem 2rem);
			font-size: 0.7rem;
			padding: 0.87rem;
			border-top: 0;
			border-bottom: 0;
			border-right: 0;
			z-index: 3;
		}
	}
}

// IOS OVERRIDE
// -----------------------------------------------------------------------------
/* Form - iOS Override */
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
	-webkit-appearance: none;
}

// PLACEHOLDERS
// -----------------------------------------------------------------------------
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
	@include placeholder-color(#bdbdbd);
}

/* Form - Bootstrap Override */
.btn-lg,
.btn-group-lg > .btn {
	line-height: 1.334;
}

.input-group-btn {

	.btn {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

}

select {
	&.form-control-sm,
	&.form-control-lg {
		line-height: 1;
		height: auto !important;
	}
	&.form-control-lg {
		padding: 1.2rem 0.5rem;
	}
}

.bootstrap-timepicker-widget {
	input {
		border: 0;
	}
	table {
		td {
			input {
				width: 40px;
			}
		}
	}
}

.fileupload .btn {
    line-height: 20px;
    margin-left: -5px;
}

/* Forms Validations */
label {
	&.valid {
		display: inline-block;
		text-indent: -9999px;
	}

	&.error {
		color: #c10000;
		font-size: 0.9em;
		line-height: 18px;
		padding: 5px 0 0;
		&#captcha-error {
			margin-top: 15px;
		}
	}
}

.form-errors-light {
	.form-control {
		&.error {
			border-color: #FFF;
		}
	}
	label {
		&.error {
			color: #FFF;
		}
	}
}

/* Simple Search */
.simple-search {
	.form-control {
		border-radius: if-ltr(1.3rem 0 0 1.3rem, 0 1.3rem 1.3rem 0 !important);
		border-color: #EEE;
		border-#{$right}: 0;

		&:focus {
			box-shadow: none;
		}
	}
	.input-group-append {
		border-radius: if-ltr(0 1.3rem 1.3rem 0, 1.3rem 0 0 1.3rem);
		border: 1px solid #EEE;
		border-#{$left}: 0;
		.btn {
			background: transparent !important;
			border: 0;
			box-shadow: none !important;
		}
	}
}

/* Captcha */
.captcha {
	clear: both;
	overflow: hidden;
	background: #F2F2F2;
	text-align: center;
	margin-bottom: 20px;
	max-width: 160px;
	height: 78px;
	position: relative;
	input {
		border: 0;
	}
}

.captcha-input {
	position: relative;
	top: 15px;
	font-size: 14px;
}

.captcha-image {
	float: $left;
	position: relative;
	top: -5px;
	#{$left}: -13px;
}

.captcha-refresh {
	position: absolute;
	z-index: 1;
	bottom: 2px;
	right: 2px;
	border-radius: 3px;
	padding: 2px 5px;
	a, a:hover, a:focus, a:active {
		color: #333;
	}
}

/* Form Styles */
.form-style-2 {
	.form-group {
		margin-bottom: 0.7rem;
	}
	.form-control {
	    padding: 0.7rem 1rem;
	    border: 0;
	    &::-webkit-input-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&::-moz-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&:-ms-input-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&:-moz-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}

		&.error {
			border-width: 2px;
		}
	}
}

.form-style-3 {
	.form-group {
		margin-bottom: 1.1rem;
	}
	.form-control {
	    padding: 0.7rem 1rem;
	    background: #F4F4F4;
	    border: none;
		&::-webkit-input-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&::-moz-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&:-ms-input-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&:-moz-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}

		&.error {
		    border: 2px solid #e21a1a;
		}
	}
}

.form-style-4 {
	.form-control {
		background: transparent;
	    border-top: 0;
	    border-right: 0;
	    border-left: 0;
	    border-bottom-color: rgba(0,0,0,0.4);
	    color: #FFF;
	    box-shadow: none !important;
	}
}
