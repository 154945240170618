html {
	direction: $dir;
	overflow-x: hidden;
	box-shadow: none !important;
	-webkit-font-smoothing: antialiased;
}

body {
	background-color: $light-bg;
	color: $primary;
	font-family: $font-primary;
	font-size: $body-font-size + 0px;
	line-height: $body-line-height + 0px;
	margin: 0;
	@include if-rtl() {
		text-align: right;
	}

	a {
		outline: none !important;
	}

}

.body {
	background-color: $light-bg;
}

html.safari:not(.no-safari-overflow-x-fix) .body {
	overflow-x: hidden;
}

li {
	line-height: 24px;
}

a {
	color: $primary;
}
/* Responsive */
@media (max-width: 575px) {

	body {
		font-size: 13px;
	}

}
